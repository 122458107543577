import { Component, OnInit, OnDestroy } from '@angular/core';
import { Option } from '../types/option';
import { Subscription } from 'rxjs';
import { OptionService } from '../services/option.service';
import { Router } from '@angular/router';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ClrIconModule } from '@clr/angular';
import { TypePipe } from '../tools/pipes/type.pipe';
import { SortManager } from 'app/tools/sort-manager';
import { ToolsService } from 'app/services/tools/tools.service';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css'],
  standalone: true,
  imports: [NgFor, ClrIconModule, NgIf, TypePipe, NgClass]
})
export class OptionsComponent implements OnInit, OnDestroy {

  optionsList: Option[] = [];
  optionsListSubscription: Subscription = new Subscription();
  sortManager!: SortManager;
  selectedOption: Option = new Option();

  constructor(private optionService: OptionService, private router: Router, private toolsService: ToolsService) {
    // ajoute le tri
    this.sortManager = new SortManager('versionFrontend', 'install', this.toolsService);
  }

  ngOnInit(): void {
    this.optionsListSubscription = this.optionService.optionsListSubject.subscribe({
      next: (modulesList: Option[]) => {
        this.optionsList = modulesList;     // create link to the modules list from service first THEN fill it service side
        this.optionsList.sort((a, b) => {
          if (b.nom.includes('_') && !a.nom.includes('_')) { return -1; }
          if (a.nom.includes('_') && !b.nom.includes('_')) { return -1; }
          return 0;
        });
      }
    });
    this.optionService.getAll(0, true);
  }

  editOption(option: Option): void {
    this.router.navigate(['/options/' + option.idOption]);
  }

  addOption(): void {
    this.router.navigate(['/optionAdd']);
  }

  ngOnDestroy(): void {
    if (this.optionsListSubscription) {
      this.optionsListSubscription.unsubscribe();
    }
  }

  /**
   * Trie le tableau : même que pour la page install
   * @param trier la colonne à trier
   */
  sort(nameCol: string, initialSort = false): void {
    this.sortManager.sort(nameCol, this.optionsList, (a: Option, b: Option) => {
      const getValue = (option: Option, column: string) => {
        let value = option[column as keyof Option];
        if (value === undefined) {

          value = option.description[column as keyof Option["description"]].toString();
        }
        return value;
      };

      return this.sortManager.compare(getValue(a, nameCol), getValue(b, nameCol));
    }, initialSort);
  }

  /**
     * Selectionne une option
     * @param option l'option sélectionnée
     */
  select(option: Option): void {
    this.selectedOption = option;
  }


}
