import { OptionInstance } from './option_instance';

export class Instance {
    idInstance: number = 0;
    idClient: number = 0;
    nom: string = '';
    referenceInstallation: string = '';
    estActif: boolean = true;
    messageSiInactif: string = '';
    remarque: string = '';
    avecMaintenance: boolean = true;
    dateFinMaintenance: string = '';
    instanceAutomatique: boolean = false;
    idDerniereAnnonce: number = 0;
    avecIncoherencesAnnoncesModules: boolean = false;
    synchronisationLicencesEstActive: boolean = false;
    synchronisationOptionsEstActive: boolean = false;
    urlTimesheet: string = "";
    
    options: OptionInstance[] = [];

    static createNew(ref: string, idClient: number): Instance {
        const i = new Instance();
        i.idClient = idClient;
        i.referenceInstallation = ref;
        return i;
    }
}

export interface InstanceExport {
    numéroClient: number;
    nomClient: string;
    référenceInstallation: string;
    maintenance: boolean;
    actif: boolean;
    remarque: string;
}
