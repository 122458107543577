import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'app/services/client.service';
import { MessageService } from 'app/services/tools/message.service';
import { ClientBase } from 'app/types/client';
import { InfoContact, InfoSimpleAPI, InfoSimple, InfosClient, InputType } from 'app/types/clientInfo';
import { Hebergement } from 'app/types/hebergement';
import { combineLatest } from 'rxjs';
import { NgIf, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClrInputModule, ClrCommonFormsModule, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrTabsModule, ClrConditionalModule, ClrAccordionModule, ClrTextareaModule, ClrIconModule, ClrCheckboxModule, ClrDropdownModule } from '@clr/angular';
import { HebergementsTabComponent } from '../internals/hebergements-tab/hebergements-tab.component';
import { HistoriqueComponent } from '../historique/historique.component';

export enum DashboardTab { Client, Instance }
export enum DashboardPanel { ClientContact, ClientInfos, ClientHebergement, None }

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, ClrInputModule, ClrCommonFormsModule, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrTabsModule, ClrConditionalModule, ClrAccordionModule, ClrTextareaModule, NgFor, ClrIconModule, HebergementsTabComponent, NgSwitch, NgSwitchCase, ClrCheckboxModule, ClrDropdownModule, HistoriqueComponent]
})
export class DashboardComponent implements OnInit {

  client!: ClientBase;

  currentPanel: DashboardPanel = DashboardPanel.ClientHebergement;
  currentTab: DashboardTab = DashboardTab.Client;
  Panel = DashboardPanel;
  Tab = DashboardTab;
  InputType = InputType;

  infoContactEnEdition: InfoContact | null = null;
  infoSimpleEnEdition: InfoSimple | null = null;

  infosSimplesDispo!: InfoSimple[];
  clientInfos!: InfosClient;
  clientHebergement: Hebergement = Hebergement.createEmpty();

  boolModifie: boolean = false;
  idInstance!: number;

  constructor(private clientService: ClientService, private activatedRoute: ActivatedRoute, private router: Router,
    private messageService: MessageService) { }

  ngOnInit(): void {
    // récupération des infos de l'URL
    const selectedTab = this.getIndex('tab');
    const selectedPanel = this.getIndex('panel');
    if (selectedTab >= 0) { this.currentTab = selectedTab; } else { this.currentTab = DashboardTab.Instance; }
    if (selectedPanel >= 0) { this.currentPanel = selectedPanel; } else { this.currentPanel = DashboardPanel.None; }

    switch(selectedPanel){
      case 1 :
        this.switchTab(this.Tab.Client);
        break;
      default :
        break;

    }

    const idClient = Number(this.activatedRoute.snapshot.paramMap.get('idClient'));
    this.idInstance = Number(this.activatedRoute.snapshot.paramMap.get('idInstance'));
    this.loadData(idClient);
  }

  /* =========================== gestion du client ========================================== */
  /* on attend d'avoir chargé le client et les champs disponibles pour les infos avant de charger les infos client */
  loadData(idClient: number): void {
    if (idClient <= 0) {
      this.client = { idClient: 0, code: '', nom: '', remarque: '' };
      return; // nouveau client, on cherche rien de plus
    }
    const clientObs = this.clientService.getClientById(idClient);
    const availableInfosObs = this.clientService.availableInfoTypesSubject;

    // on attend les infos clients + la liste des champs disponibles pour savoir comment remplir les champs avec quoi 
    // combineLatest et pas forkJoin à cause du Sujet https://stackoverflow.com/a/40166502
    combineLatest([clientObs, availableInfosObs])
      .subscribe({
        next: (results => {
          this.client = results[0];
          this.infosSimplesDispo = results[1]
          this.loadClientInfo(results[0].informations)
        }),
        error: (error: Error) => {
          this.client = { idClient: -1, code: '?', nom: '?', remarque: '?' };
          console.error(error);
          this.messageService.displayError("Impossible de récupérer les instances du client");
        }
      })
  }

  updateClient(): void {
    if (this.client.code !== '' && this.client.nom !== '') {
      this.clientService.postClient(this.client).subscribe({
        next: (newId: number) => {
          if (newId > 0) {
            if (!(newId == this.client.idClient)) {
              window.open(`/dashboard/${newId}/${this.currentTab}/${this.currentPanel}`, "_self");
            }
          } else {
            this.messageService.displayError(`Echec de la ${this.client.idClient > 0 ? 'modification' : 'création'} du client`);
          }
        },
        error: ((e: Error) => {
          console.error(e);
          this.messageService.displayError(`Echec de la ${this.client.idClient > 0 ? 'modification' : 'création'} du client`);
        })
      })
    }
  }

  private loadClientInfo(info: InfosClient): void {
    this.clientInfos = new InfosClient();
    // on récupère les infos si on en a
    if (info != null && info.tabInfoSimple) {
      // les champs simples
      info.tabInfoSimple.forEach((info: InfoSimpleAPI) => {
        const infoType = this.infosSimplesDispo.find(i => i.id === info.id);
        if (infoType)
          this.clientInfos.tabInfoSimple.push(InfoSimple.fromInfoAPI(info, infoType.nom, infoType.type, infoType.noLignes))
      });
    } else {
      this.clientInfos.tabInfoSimple = [];
    }
    // les champs contact
    this.clientInfos.tabInfoContact = (info != null && info.tabInfoContact) ? info.tabInfoContact : [];
  }

  /* =========================== tools ========================================================= */
  private getIndex(name: string): number {
    const param = Number(this.activatedRoute.snapshot.paramMap.get(name));
    return param ? param : -1;
  }

  switchTab(tab: DashboardTab): void {
    this.currentTab = tab;
  }

  backToClients(): void {
    this.router.navigate(['/clients'])
  }

  /* =========================== gestion des infos contact ====================================== */
  // on ouvre un formulaire vide
  startEditionContact(): void {
    this.infoContactEnEdition = InfoContact.createNew();
  }

  // on envoie le contact décrit dans le formulaire
  saveContact(): void {
    if (this.infoContactEnEdition !== null) {
      const contact = this.infoContactEnEdition;
      this.clientService.saveContactInfo(this.infoContactEnEdition, this.client.code).subscribe({
        next: (ok: boolean) => {
          if (ok) {
            const index = this.clientInfos.tabInfoContact.findIndex(x => x.id === contact.id);
            if (index >= 0) {
              this.clientInfos.tabInfoContact[index] = contact;
            } else {
              contact.id = this.clientInfos.tabInfoContact.length;
              this.clientInfos.tabInfoContact.push(contact);
            }
            this.infoContactEnEdition = null;
          }
        }
      });
    }
  }

  // on supprime un des contacts depuis le tableau
  deleteContact(index: number): void {
    if (index >= 0 && index < this.clientInfos.tabInfoContact.length) {
      this.clientService.deleteContactInfo(this.clientInfos.tabInfoContact[index].id, this.client.idClient).subscribe({
        next: (ok: boolean) => {
          if (ok) {
            this.clientInfos.tabInfoContact = this.clientInfos.tabInfoContact.filter(i => i.id !== this.clientInfos.tabInfoContact[index].id);
          }
        }
      });
    }
  }

  // on a choisit un contact qu'on veut éditer via le formulaire
  editContact(index: number): void {
    if (index >= 0 && index < this.clientInfos.tabInfoContact.length) {
      this.infoContactEnEdition = this.clientInfos.tabInfoContact[index];
    }
  }

  // on ferme le formulaire en annulant la modif / création
  cancelContact(): void {
    this.infoContactEnEdition = null;
  }


  startEditionInfo(type: InfoSimple): void {
    this.infoSimpleEnEdition = InfoSimple.createFromType(type);
  }

  // on envoie le contact décrit dans le formulaire
  saveInfo(): void {
    if (this.infoSimpleEnEdition !== null) {
      const info = this.infoSimpleEnEdition;
      // info.idItem = InfoSimple.getNewId();
      this.clientService.saveInfoSimple(info, this.client.code).subscribe({
        next: (ok: boolean) => {
          if (ok) {
            const index = this.clientInfos.tabInfoSimple.findIndex(x => x.idItem === info.idItem);
            if (index >= 0) {
              this.clientInfos.tabInfoSimple[index] = info;
            } else {
              info.idItem = this.clientInfos.tabInfoSimple.length;
              this.clientInfos.tabInfoSimple.push(info);
            }
            this.infoSimpleEnEdition = null;
          }
        }
      });
    }
  }

  // on supprime un des contacts depuis le tableau
  deleteInfo(index: number): void {
    if (index >= 0 && index < this.clientInfos.tabInfoSimple.length) {
      this.clientService.deleteInfoSimple(this.clientInfos.tabInfoSimple[index].idItem, this.client.idClient).subscribe({
        next: (ok: boolean) => {
          if (ok) {
            this.clientInfos.tabInfoSimple = this.clientInfos.tabInfoSimple.filter(i => i.idItem !== this.clientInfos.tabInfoSimple[index].idItem);
          }
        }
      });
    }
  }

  // on a choisit un contact qu'on veut éditer via le formulaire
  editInfo(index: number): void {
    if (index >= 0 && index < this.clientInfos.tabInfoSimple.length) {
      this.infoSimpleEnEdition = this.clientInfos.tabInfoSimple[index];
    }
  }

  // on ferme le formulaire en annulant la modif / création
  cancelInfo(): void {
    this.infoSimpleEnEdition = null;
  }
}
