import { Component, Input, OnInit } from '@angular/core';
import { Hebergement, HebergementUser } from 'app/types/hebergement';
import { HebergementService } from 'app/services/hebergement.service';
import { DateService } from 'app/services/tools/date.service';
import { NgIf, NgFor } from '@angular/common';
import { ClrInputModule, ClrCommonFormsModule, ClrCheckboxModule, ClrTextareaModule, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrDatepickerModule, ClrIconModule, ClrDatagridModule, ClrConditionalModule, ClrModalModule } from '@clr/angular';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-hebergements-tab',
    templateUrl: './hebergements-tab.component.html',
    styleUrls: ['./hebergements-tab.component.css'],
    standalone: true,
    imports: [NgIf, ClrInputModule, ClrCommonFormsModule, FormsModule, ClrCheckboxModule, ClrTextareaModule, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrDatepickerModule, ClrIconModule, ClrDatagridModule, NgFor, ClrConditionalModule, ClrModalModule]
})

export class HebergementsTabComponent implements OnInit {
  @Input() idClient!: number;

  // Variables
  hebergement: Hebergement | null = null;
  newUtilisateur: HebergementUser = HebergementUser.createEmpty();
  boolForm = false;
  boolSave = false;
  boolErrorHebergement = false;
  modalConfirmationSupression = false;
  creatingHebergement = false;
  hasEdited = false;

  constructor(private hebergementService: HebergementService, private dateService: DateService) { }

  ngOnInit(): void {
    if (this.idClient > 0) { this.loadData(); }
  }

  /**
   * Charge les données de l'hébergement
   */
  private loadData(): void {
    this.hebergementService.getHebergement(this.idClient).subscribe({
      next: (hebergement: Hebergement) => {
        if (hebergement) {
          this.hebergement = hebergement;
          if (hebergement) {
            this.boolForm = this.hebergement.utilisateurs.length <= 0;
            this.hebergement.dateDebut = this.dateService.formatForDateInput(this.hebergement.dateDebut);
            this.hebergement.utilisateurs.map((u: HebergementUser) => {
              u.dateActivation = this.dateService.formatForDateInput(u.dateActivation);
              u.dateDesactivation = this.dateService.formatForDateInput(u.dateDesactivation);
              return u;
            })
          }
        }
      }, error: (error) => console.error(error)
    });
  }

  /**
   * Sauvegarde l'hébergement
   */
  saveHebergement(): void {
    if (this.hebergement === null) { return; }
    this.hebergement.idClient = this.idClient;
    this.hebergementService.postHebergement(this.hebergement).subscribe({
      next: (res: boolean) => {
        if (res) {
          this.boolSave = true;
          setTimeout(() => { this.boolSave = false }, 5000);
          this.hasEdited = false;
          this.loadData();
          return;
        }
        else { console.error("Erreur lors de la sauvegarde de l'hébergement"); }
      }
    })
    this.creatingHebergement = false;
  }

  addHebergement(): void {
    this.hebergement = Hebergement.createEmpty();
    this.creatingHebergement = true;
  }

  cancelHebergement(): void {
    this.hebergement = null;
  }

  addOrModifyHebergementUser(): void {
    let modification = false;

    if (this.hebergement === null) { return; }

    // Si utilisateur déjà existant, on le modifie
    this.hebergement.utilisateurs.map((u: HebergementUser) => {
      if (u.idHebergementUser === this.newUtilisateur.idHebergementUser) {
        modification = true;
      }
    });
    this.newUtilisateur.idHebergement = this.hebergement.idHebergement
    this.hebergementService.postHebergementUser(this.newUtilisateur).subscribe({
      next: (res: boolean) => {
        this.newUtilisateur = HebergementUser.createEmpty();
        this.boolForm = false;
        this.hebergementService.getHebergement(this.idClient, true).subscribe({
          next: (hebergement: Hebergement) => {
            if (hebergement) {
              this.hebergement = hebergement;
              if (hebergement) {
                this.boolForm = this.hebergement.utilisateurs.length <= 0;
                this.hebergement.dateDebut = this.dateService.formatForDateInput(this.hebergement.dateDebut);
                this.hebergement.utilisateurs.map((u: HebergementUser) => {
                  u.dateActivation = this.dateService.formatForDateInput(u.dateActivation);
                  u.dateDesactivation = this.dateService.formatForDateInput(u.dateDesactivation);
                  return u;
                })
              }
            }
          }, error: (error) => console.error(error)
        });
      }
    });
  }

  /**
   * Modifie un utilisateur
   * @param utilisateur l'utilisateur à modifier
   */
  modifyUtilisateur(utilisateur: HebergementUser): void {
    this.newUtilisateur = utilisateur;
    this.boolForm = true;
  }

  /**
   * Supprime un utilisateur
   * @param utilisateur l'utilisateur à supprimer
   */
  deleteUtilisateur(utilisateur: HebergementUser): void {
    if (this.hebergement === null) { return; }
    this.hebergementService.deleteHebergementUser(utilisateur.idHebergementUser).subscribe({
      next: (res: boolean) => {
        if (res) {
          if (this.hebergement === null) { return; }
          this.hebergement.utilisateurs = this.hebergement.utilisateurs.filter(user => user !== utilisateur);
          if (this.hebergement.utilisateurs.length <= 0) {
            this.boolForm = true;
          }
          this.loadData();
        }
        else { console.error("Erreur lors de la suppression de l'utilisateur"); }
      }
    });
  }

  /**
   * Annule la saisie
   */
  annulerSaisie(): void {
    this.boolForm = false;
    this.newUtilisateur = HebergementUser.createEmpty();
  }

  supprimerHebergement(): void {
    this.hasEdited = false;
    this.boolSave = false;
    this.hebergementService.deleteHebergement(this.hebergement?.idHebergement || 0).subscribe(
      {
        next: (res: boolean) => {
          if (res) {
            this.hebergement = null;
          }
        }
      });
    this.creatingHebergement = false;
  }

  annulerCreation(): void {
    this.hasEdited = false;
    this.boolSave = false;
    if (this.hebergement) {
      // Si l'hébergement a des utilisateurs, on demande confirmation
      if (this.hebergement.utilisateurs.length > 0) {
        this.modalConfirmationSupression = true;
      }
      else {
        this.hebergement = null;
        this.creatingHebergement = false;
      }
    }
  }

}
