import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })

export class DateService {
    private readonly diviseur = "/"
    /**
     * Fonction pour formater la date avec un (keypress)
     * @param date date: string
     * @returns date + le diviseur
     */
    formatDate(date: string): string {
        if (date.length === 2) {
            date = date + this.diviseur;
            return date
        }
        else if (date.length === 5) {
            date = date + this.diviseur
            return date
        }
        return date
    }

    formatForDateInput(dateStr: string): string {
        const date = new Date(dateStr);
        if (!isNaN(date.getTime())) {
            return date.getFullYear() < 2000 ? '' : date.toLocaleDateString('en-GB'); // dd/MM/yyyy
        } else {
            return '';
        }
    }

    dateForAPI(dateStr: string): Date {
        return new Date(this.formatForAPI(dateStr));
    }

    private formatForAPI(date: string): string {
        if (date === '' || date === undefined){
          return '1000-01-01';
        }
        return date.substr(6, 4) + '-' + date.substr(3, 2) + '-' + date.substr(0, 2);
      }

    /**
     * Fonction pour savoir si la date est valide
     * @param date date à confirmer
     * @returns boolean
     */
    isValidDate(date: string): boolean {
        const regex: RegExp = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/gm
        if (regex.test(date)) {
            return true
        }
        else {
            return false
        }
    }
}
