<div class="content-container">
    <nav class="sidenav">
        <section class="sidenav-content">
            <section class="nav-group">
                <div id="divbuttonsnav">
                    <button (click)="onSubmit()" style="width: fit-content;" type="submit"
                        class="btn btn-success btn-inline" id="btnSubmit">Valider</button>
                    <button (click)="backToList()" class="btn btn-inline">Retour</button>
                </div>
            </section>
        </section>
    </nav>

    <form clrForm #f="ngForm" *ngIf="client && instance">
        <clr-tabs>
            <!--GENERAL TAB-->
            <clr-tab>
                <button clrTabLink class="btn-tab">General</button>
                <ng-template [clrIfActive]="currentTab == Tab.General" (click)="changeTab(Tab.General)">
                    <clr-tab-content id="tab">

                        <div id="client-info">
                            Client: {{client.nom}} - {{client.code}}
                        </div>

                        <clr-input-container>
                            <label for="instanceAuto">Instance automatique</label>
                            <input clrInput type="checkbox" id="instanceAuto" [(ngModel)]="instance.instanceAutomatique"
                                name="instanceAuto" clrCheckbox />
                        </clr-input-container>

                        <clr-input-container>
                            <label for="codeClient">Référence </label>
                            <input style="width:35%" min="0" clrInput type="number"
                                id="codeClient" [(ngModel)]="instance.referenceInstallation" name="codeClient" [readOnly]="instanceCode >= 0" />
                        </clr-input-container>

                        <clr-input-container>
                            <label for="nomInstance">Nom de l'instance</label>
                            <input style="width:35%" clrInput type="text" id="nomInstance"
                                [(ngModel)]="instance.nom" name="nomInstance" />
                        </clr-input-container>

                        <clr-input-container>
                            <label for="avecMaintenance">Avec maintenance</label>
                            <input clrInput type="checkbox" id="avecMaintenance" [(ngModel)]="instance.avecMaintenance"
                                name="avecMaintenance" clrCheckbox />
                        </clr-input-container>

                        <clr-date-container>
                            <label for="dateFinMaintenance">Fin de la maintenance</label>
                            <input type="date" clrDate id="dateFinMaintenance" [(ngModel)]="instance.dateFinMaintenance"
                                name="dateFinMaintenance" />
                        </clr-date-container>

                        <clr-textarea-container>
                            <label>Remarque interne</label>
                            <textarea clrTextarea name="remarque" id="remarque" [(ngModel)]="instance.remarque"></textarea>
                        </clr-textarea-container>

                        <clr-input-container>
                            <label for="estActif">Actif</label>
                            <input clrInput type="checkbox" id="estActif" (click)="enableEdit()" name="estActif"
                                [(ngModel)]="instance.estActif" clrCheckbox />
                        </clr-input-container>

                        <clr-input-container>
                            <label for="SyncLicences">Synchronisation des Licences</label>
                            <input clrInput type="checkbox" id="SyncLicences" name="SyncLicences"
                                [(ngModel)]="instance.synchronisationLicencesEstActive " clrCheckbox />
                        </clr-input-container>

                        <clr-input-container>
                            <label for="urlTimesheet">URL Timesheet</label>
                            <input style="width:35%" clrInput type="text" id="urlTimesheet"
                                [(ngModel)]="instance.urlTimesheet" name="urlTimesheet" />
                        </clr-input-container>

                        <div *ngIf=" !instance.estActif" class="alert alert-warning" role="alert">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                                    </div>
                                    <span class="alert-text">Désactiver cette instance rendra l'accès à
                                        ses différents modules impossibles pour l'utlisateur qui recevra le
                                        message suivant :
                                    </span>
                                </div>
                            </div>
                        </div>

                        <clr-textarea-container *ngIf="!instance.estActif">
                            <label>Message pour le client</label>
                            <textarea clrTextarea name="messageSiInactif" contenteditable="true" id="messageSiInactif"
                                [(ngModel)]="instance.messageSiInactif">
                            </textarea>
                        </clr-textarea-container>
                    </clr-tab-content>
                </ng-template>
            </clr-tab>

            <!--OPTIONS TAB-->
            <clr-tab>
                <button clrTabLink class="btn-tab">Options</button>
                <ng-template [clrIfActive]="currentTab == Tab.Options" (click)="changeTab(Tab.Options)">
                    <clr-tab-content>
                        <app-options-tab id="intern" *ngIf="requestCompleted" (notify)="getOptionsInstance($event)"
                            [mode]="2" [optionsInstanceList]="instance.options">
                        </app-options-tab>
                    </clr-tab-content>
                </ng-template>
            </clr-tab>
        </clr-tabs>
    </form>
</div>
