import { Option } from '../types/option';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from './tools/message.service';

@Injectable()
export class OptionService {

    constructor(private httpClient: HttpClient, private messageService: MessageService) {}

    private optionPath = 'options';
    private optionsList: Option[] = [];
    optionsListSubject = new Subject<Option[]>();

    private option!: Option | null;
    optionSubject = new Subject<Option | null>();

    private emitOptionsListSubject(): void {
        this.optionsListSubject.next(this.optionsList.slice());
    }

    private emitOptionSubject(): void {
        this.optionSubject.next(this.option);
    }

    getAll(mode: number = 0, reloadAnyway: boolean = false): void {
        if (this.optionsList.length > 0 && !reloadAnyway) {
            this.emitOptionsListSubject();
        }
        else
        {
            const path = `${this.optionPath}/mode/${mode}`;
            this.httpClient.get<Option[]>(path).subscribe({
                next: (response) => {
                    this.optionsList = response;
                    this.emitOptionsListSubject();
                },
                error: (error: Error) => {
                    console.error(error);
                    this.messageService.displayError('Impossible de récupérer la liste des options');
                    this.optionsList = [];
                    this.emitOptionsListSubject();
                }
            });
        }
    }

    getOptionById(optionId: number): void {
        const path = `${this.optionPath}/id/${optionId}`;
        this.httpClient.get<Option>(path).subscribe({
            next: (response) => {
                this.option = response;
                this.emitOptionSubject();
            },
            error: (error: Error) => {
                console.error(error);
                this.messageService.displayError('Echec lors de la récupération de l\'option');
                this.option = null;
                this.emitOptionSubject();
            }
        });
    }

    postOption(newOption: Option): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<boolean>(this.optionPath, JSON.stringify(newOption), {headers});
    }
}
