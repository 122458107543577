<header class="header-6" *ngIf="isInsideApp()">
    <div class="menu-small-size">
        <cds-icon  class="element" shape="bars"  (click)="sideNavOpened = true"></cds-icon>
    </div>
    <div class="branding">
        <a routerLink="/clients" class="nav-link">
            <cds-icon shape="users"></cds-icon>
            <span class="title" id="test">Gestion licences</span>
        </a>
    </div>
    <div class="header-nav" [clr-nav-level]="1">
        <a id="instances" routerLinkActive="active" routerLink="/clients" class="nav-link nav-text">Clients</a>
        <a id="installations" routerLinkActive="active" routerLink="/installations" class="nav-link nav-text">
            Installations
        </a>
        <a id="modules" routerLinkActive="active" routerLink="/modules" class="nav-link nav-text">Modules</a>
        <a id="options" routerLinkActive="active" routerLink="/options" class="nav-link nav-text">Options</a>
    </div>
    <div class="header-actions">
        <img *ngIf="this.decoNoel" src="/assets/noel.png" id="noel">
        <img *ngIf="userPicture != ''" src="{{userPicture}}" class="avatar">
        <a class="nav-link">
            <span class="title">{{userName}}</span>
        </a>
        <a id="logOut" (click)="logOut()" class="btn btn-link nav-text">
            Log Out
        </a>
    </div>
    <clr-side-panel [(clrSidePanelOpen)]="sideNavOpened" [clrSidePanelStaticBackdrop]="false" [clrSidePanelSize]="size">
        <div class="side-panel-title">
            <h5>
                <cds-icon shape="users" class="logo-users"></cds-icon>
                <span>Gestion licences</span>
            </h5>
        </div>
        <div class="side-panel-body burger-menu">
            <a id="instances" routerLinkActive="active" routerLink="/clients" class="btn nav-link nav-text"
            (click)="sideNavOpened = false">Clients</a>
            <a id="installations" routerLinkActive="active" routerLink="/installations" class="btn nav-link nav-text"
            (click)="sideNavOpened = false">Installations</a>
            <a id="modules" routerLinkActive="active" routerLink="/modules" class="btn nav-link nav-text"
            (click)="sideNavOpened = false">Modules</a>
            <a id="options" routerLinkActive="active" routerLink="/options" class="btn nav-link nav-text"
            (click)="sideNavOpened = false">Options</a>

        </div>
        
      </clr-side-panel>
</header>
