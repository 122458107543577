<div class="content-container">
    <div class="content-area">
        <div class="title-area">
            <h4>Liste des installations</h4>
        </div>
        <div class="table-container">
            <table class="table table-compact table-noborder">
                <thead>
                    <tr>
                        <th style="min-width:30px">Alertes
                        </th>
                        <th style="min-width:160px">MCR-suite
                            <cds-icon (click)="sort('mcrsuite')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('mcrsuite'), 'not-sorted': !(sortManager.sameCol('mcrsuite'))}">
                            </cds-icon>
                        </th>
                        <th style="min-width:220px">Site
                            <cds-icon (click)="sort('url')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('url'), 'not-sorted': !(sortManager.sameCol('url'))}">
                            </cds-icon>
                        </th>
                        <th  style="min-width:170px">Version du front-end
                            <cds-icon (click)="sort('versionFrontend')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('versionFrontend'), 'not-sorted': !(sortManager.sameCol('versionFrontend'))}">
                            </cds-icon>
                        </th>
                        <th style="min-width:290px">Api
                            <cds-icon (click)="sort('urlAPI')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('urlAPI'), 'not-sorted': !(sortManager.sameCol('urlAPI'))}">
                            </cds-icon>
                        </th>
                        <th>Version Api
                            <cds-icon (click)="sort('versionAPI')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('versionAPI'), 'not-sorted': !(sortManager.sameCol('versionAPI'))}">
                            </cds-icon>
                        </th>
                        <th style="min-width:140px">Version Windev
                            <cds-icon (click)="sort('versionWebdev')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('versionWebdev'), 'not-sorted': !(sortManager.sameCol('versionWebdev'))}">
                            </cds-icon>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let site of dataSource">
                        <tr (click)="select(site)" class="trbody"
                            [ngClass]="selectedSite.url == site.url ? 'active-line' : 'inactive-line'">
                            <td>{{site.alertes}}</td>
                            <td>{{site.mcrsuite}}</td>
                            <td><a (click)="redirectToSite(site.url)">{{site.url}}</a></td>
                            <td>{{site.infos.versionFrontend}}</td>
                            <td>{{site.infos.urlAPI}}</td>
                            <td>{{site.infos.versionAPI}}</td>
                            <td>{{site.infos.versionWebdev}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
