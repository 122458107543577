import { Component, OnInit, OnDestroy } from '@angular/core';
import { Option, emptyOption } from '../types/option';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { OptionService } from '../services/option.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'app/services/tools/message.service';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClrCommonFormsModule, ClrInputModule, ClrTextareaModule, ClrIconModule, ClrAlertModule } from '@clr/angular';

@Component({
    selector: 'app-single-option',
    templateUrl: './single-option.component.html',
    styleUrls: ['./single-option.component.css'],
    standalone: true,
    imports: [RouterLink, NgIf, FormsModule, ClrCommonFormsModule, ClrInputModule, ClrTextareaModule, ClrIconModule, ClrAlertModule]
})
export class SingleOptionComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private optionService: OptionService,
    private messageService: MessageService) { }

  option!: Option | null;
  optionSubscription: Subscription = new Subscription();
  invalidForm: boolean = false;
  issuePosting: boolean = false;
  requestCompleted: boolean = false;

  ngOnInit(): void {
    this.option = emptyOption();
    if (Number(this.activatedRoute.snapshot.paramMap.get('id'))) {
      this.getOption(Number(this.activatedRoute.snapshot.paramMap.get('id')));
    } else {
      this.requestCompleted = true;
    }
  }

  getOption(idOption: number): void {
    this.optionSubscription = this.optionService.optionSubject.subscribe({
      next: (option: Option | null) => {
        this.option = option;
        this.requestCompleted = true;
      },
      error:() => this.requestCompleted = true
    });
    this.optionService.getOptionById(idOption);
  }

  onSubmit(): void {
    if (this.option === null) { return; }
    this.option.type = this.option.type === undefined ? 1 : Number(this.option.type);
    this.option.mode = this.option.mode === undefined ? 1 : Number(this.option.mode);

    if (!this.option.nom) {
      this.invalidForm = true;
    } else {
      this.optionService.postOption(this.option)
      .subscribe({
        next: ((res: boolean) => {
          if(res) {
            this.router.navigate(['/options'])
          } else {
            this.messageService.displayError(`Echec de la ${this.option && this.option.idOption > 0 ? 'modification' : 'création'} de l'option`);
          }
        }),
        error: ((error: Error) => {
          console.error(error);
          this.messageService.displayError(`Echec de la ${this.option && this.option.idOption > 0 ? 'modification' : 'création'} de l'option`);
        })
      });
    }
  }

  update(type: string, event: Event): void {
    if(event === null || this.option === null) { return; }
    switch (type ) {
      case 'type':
        this.option.type = Number((event.target as HTMLInputElement).value);
        break;
      case 'mode':
        this.option.mode = Number((event.target as HTMLInputElement).value);
        break;
    }
  }

  ngOnDestroy(): void {
    if (this.optionSubscription) {
      this.optionSubscription.unsubscribe();
    }
  }
}
