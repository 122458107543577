import { Injectable } from '@angular/core';
import { Annonce } from '../types/annonce';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, map } from 'rxjs';
import { AnnonceExport, UserAnnonce } from 'app/annonces/annonce-types';
import { ExcelExportService } from '../services/tools/excel_export.service'
import { MessageService } from './tools/message.service';
import { DateService } from './tools/date.service';

class LastRequest {
    idInstance: number = 0
    data: LastRequestData[] = []
    constructor(idInstance: number, data: LastRequestData[]) {
        this.idInstance = idInstance;
        this.data = data;
    }
}

class LastRequestData {
    start: number = 0
    annonces: Annonce[] = []
    constructor(start: number, annonces: Annonce[]) {
        this.start = start;
        this.annonces = annonces;
    }
}

@Injectable()

export class AnnonceService {

    private annoncesPath: string = 'annonce';
    readonly MAX_ANNONCE_RESULT:number = 10;
    
    private annoncesList: Annonce[] = [];
    private lastRequest: LastRequest| null = null;
    annoncesListSubject: Subject<Annonce[]> = new Subject<Annonce[]>();

    constructor(private httpClient: HttpClient, private excelExportService: ExcelExportService, private messageService: MessageService,
        private dateService: DateService) {}

    private emitAnnonceList(): void { this.annoncesListSubject.next(this.annoncesList); }

    getAllAnnonces(idInstance: number, start: number): void {
        // si on a memorisé un résultat et que c'est la même plage(même date de début) on la rend direct
        if(this.lastRequest !== null && this.lastRequest.idInstance === idInstance) {
            const index = this.lastRequest.data.findIndex(x => x.start === start);
            if(index >= 0) {
                this.annoncesList = this.lastRequest.data[index].annonces;
                this.emitAnnonceList();
                return;
            }
        }

        const path = `${this.annoncesPath}/${idInstance}/${start}/${this.MAX_ANNONCE_RESULT}`;
        this.httpClient.get<Annonce[]>(path).subscribe({
            next: (response: Annonce[]) => {
                this.annoncesList = response;
                if(this.lastRequest === null) {
                    // on mémorise la dernière requête (première à mémoriser)
                    this.lastRequest = new LastRequest(idInstance, [new LastRequestData(start, this.annoncesList)]);
                } else {
                    // on mémorise la dernière requête (on l'anoute dans le tas)
                    this.lastRequest.data.push(new LastRequestData(start, this.annoncesList));
                    if(this.lastRequest.data.length > 30) {
                        // si on a plus de 30 éléments on vire les éléments du début pour pas en avoir trop
                        this.lastRequest.data = this.lastRequest.data.slice(1);
                    }
                }
                this.emitAnnonceList();
            }, error: (error: Error) => {
                console.error(error);
                this.messageService.displayError("Echec de la récupération des annonces pour l'export");
                this.annoncesList = [];
                this.emitAnnonceList();
            }
        });
    }

    getAllAnnoncesByStartDate(idInstance: number, startDate: string, totalCount: number): Observable<{annonces: Annonce[], index: number}> {
        const formattedDate = this.dateService.dateForAPI(startDate).toLocaleDateString('fr-CA'); // yyyy-MM-dd pour mettre dans l'URL
        const path  = `${this.annoncesPath}/${idInstance}/${formattedDate}/${this.MAX_ANNONCE_RESULT}/${totalCount}`;
        return this.httpClient.get<{annonces: Annonce[], index: number}>(path).pipe(
            map((response:{annonces: Annonce[], index: number}) => {
                this.annoncesList = response.annonces;
                this.emitAnnonceList();
                return response;
            }) // on laisse passer car le composant a besoin de quelques infos
        );
    }

     getAllUsers(idInstance: number): Observable<UserAnnonce[]> {
        const path = `${this.annoncesPath}/users/${idInstance}`;
        return this.httpClient.get<UserAnnonce[]>(path);
    }

    countAnnonces(idInstance: number): Observable<number> {
        const path = `${this.annoncesPath}/count/${idInstance}`;
        return this.httpClient.get<number>(path);
    }

    exportExcel(idInstance: number, nomInstance: string): void {
        this.excelExportService.setExcelDataLoadingStatus(true);
        const path = `${this.annoncesPath}/${idInstance}/-1/-1`;
        this.httpClient.get<Annonce[]>(path).subscribe({
            next: (response: Annonce[]) => {
                const data:AnnonceExport[] = []
                for (const annonce of response) {
                    data.push(<AnnonceExport>{
                        dateHeure: annonce.dateHeure, 
                        utilisateur: `${annonce.prenom} ${annonce.nom} (${annonce.login})`, 
                        machine: annonce.nomMachine, 
                        version_mcr_suite: annonce.version,  
                        machine_os: annonce.versionOS
                    });
                }
                this.excelExportService.createFile(`Annonces - ${nomInstance}`,[{sheetName: 'Liste', data, 
                columns: [{wch: 25}, {wch: 50}, {wch: 20}, {wch: 20}, {wch: 25}]}]);
            }, 
            error: (error) => {
                console.error(error);
                this.messageService.displayError("Echec de l'export excel");
                this.excelExportService.setExcelDataLoadingStatus(false);
            }
        });
    }
}
