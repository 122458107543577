import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as XLSX from 'xlsx';

export interface ExcelSheet<T> {
    sheetName: string, 
    data:T[],
    columns: {wch: number}[]
}

@Injectable()
export class ExcelExportService {

    excelLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private emitExcelStatus(value: boolean): void { this.excelLoading.next(value); }

    setExcelDataLoadingStatus(value: boolean): void {
        this.emitExcelStatus(value);
    }

    createFile<T>(fileName: string, sheets: ExcelSheet<T>[]): void {
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        for(const sheet of sheets) {
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheet.data);
            worksheet['!cols'] = sheet.columns;
            XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);
        }
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
        this.emitExcelStatus(false);
    }

    setupColumns(colsNumber: number):{wch: number}[] {
        const cols = [];
        for (let i = 0; i < colsNumber; ++i) {
            cols.push({wch:15})
        }
        return cols;
    }
}
