import { Subject } from 'rxjs';

export enum ERRORS {
    ERROR_API = 0,
}

export interface Message {
    content: string;
    isError: boolean;
}

export class MessageService {

    private message: Message | null = null;

    messageSubject = new Subject<Message | null>();

    private readonly TIMEOUT: number = 3000;

    emitMessage(): void {
        this.messageSubject.next(this.message);
    }

    displayError(content: string): void {
        this.message = {content: content, isError: true};
        this.emitMessage();
        // on vire l'erreur après 3 sec.
        setTimeout(() =>this.removeMessage(), this.TIMEOUT)
    }

    displaySuccess(content: string): void {
        this.message = {content: content, isError: false};
        this.emitMessage();
        // on vire l'erreur après 3 sec.
        setTimeout(() => this.removeMessage(), this.TIMEOUT)
    }

    log(message: string): void {
        console.log(message);
    }

    logError(message: string): void {
        console.error(message);
    }

    removeMessage(): void {
        if (this.message !== null) {
            this.message = null;
            this.emitMessage();
        }
    }
}
