import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoutingConfigService } from '../../services/routingConfigService';
import { ToolsService } from '../../services/tools/tools.service';
import { AuthService } from 'app/services/auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(private routingConfigService: RoutingConfigService,private toolsService: ToolsService, private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // on se place au début de l'envoi de la requête 
        // => on voit passer la requête et on la modifie pour y ajouter les headers
        const urlPrefix: string = this.routingConfigService.getUrl();
        let duplicate: HttpRequest<any>;

        // si c'est cet appel précis on ajoute pas le token (cet appel sert à le créer justement)
        if(req.url.includes('google/token')) {
            duplicate = req.clone({ params: req.params, url: `${urlPrefix}/${req.url}` });
        // sinon pour chaque appel on file le token avec en headers
        } else {
            const accessToken: string = this.toolsService.localGetByKey('access_token');
            const tokenId = this.toolsService.localGetByKey('tokenId');
            const userId = this.authService.idUser.toString();// j'envoie le user id pour que s'il est vide je le récupère depuis le backend
            duplicate = req.clone(
                { headers: req.headers.set('access_token', accessToken).set('tokenId', tokenId).set('user_id', userId),
                params: req.params, url: `${urlPrefix}/${req.url}` }
            );
        }
        return next.handle(duplicate);
    }
}
