import { Component, OnInit } from '@angular/core';
import { MessageService, Message } from '../../services/tools/message.service';
import { Subscription } from 'rxjs';
import { NgIf } from '@angular/common';
import { ClrAlertModule } from '@clr/angular';

@Component({
    selector: 'app-message-banner',
    templateUrl: './message-banner.component.html',
    styleUrls: ['./message-banner.component.css'],
    standalone: true,
    imports: [NgIf, ClrAlertModule]
})
export class MessageBannerComponent implements OnInit {

  messageSub: Subscription = new Subscription();
  message: Message | null = null;

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    this.subscribe();
  }

  subscribe(): void {
    this.messageSub = this.messageService.messageSubject.subscribe({
      next:(message: Message | null) => this.message = message 
    });
    this.messageService.emitMessage();
  }
}
