<div *ngIf="requestCompleted" id="container" class="content clr-col-8 clr-col-md-8" class="container">
    <div id="container" class="content clr-col-6 clr-col-md-8" class="fit">
        <table class="element table table-noborder">
            <thead>
                <tr>
                    <th>Nom option</th>
                    <th>Valeur</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let instanceOption of optionsInstanceList">
                    <td title="nomOption">
                        <clr-input-container>
                            <select id="optionSelect" clrInput id="optionSelect" [(ngModel)]="instanceOption.idOption">
                                <option *ngFor="let option of optionsList" [value]="option.idOption">
                                    {{option.description |typePipe: 'textarea'}}
                                </option>
                            </select>
                            <clr-control-helper></clr-control-helper>
                        </clr-input-container>
                    </td>

                    <td title="valeur" *ngIf="getType(instanceOption.idOption) == 1">
                        <clr-input-container>
                            <select class="toPutUp" clrInput id="5" [(ngModel)]="instanceOption.valeur"
                                (change)="emitOptions(false)">
                                <option value="1">Oui</option>
                                <option value="0">Non</option>
                            </select>
                        </clr-input-container>
                    </td>

                    <td title="valeur" *ngIf="getType(instanceOption.idOption) == 2">
                        <input type="number" class="toPutUp" [(ngModel)]="instanceOption.valeur" clrInput />
                    </td>

                    <td title="valeur" *ngIf="getType(instanceOption.idOption) == 3">
                        <input class="toPutUp" [(ngModel)]="instanceOption.valeur" clrInput />
                    </td>

                    <td title="valeur" *ngIf="getType(instanceOption.idOption) == 4">
                        <input type="datetime" id="3" [(ngModel)]="instanceOption.valeur" clrInput />
                    </td>

                    <td>
                        <button (click)="removeRow(instanceOption.idOption)" type="button"
                            class="btnToPutUp btn btn-sm btn-icon btn-danger-outline">
                            <cds-icon shape="window-close"></cds-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <button (click)="addRow()" type="button" class="btn btn-sm btn-icon btn-success">
            <cds-icon shape="plus"></cds-icon>
        </button>
        <br />
    </div>
</div>
