import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FullHistorique } from '../types/full_historique';
import { HistoriqueService } from '../services/historique.service';
import { DateService } from 'app/services/tools/date.service';
import { DashboardPanel, DashboardTab } from 'app/dashboard/dashboard.component';
import { AuthService } from 'app/services/auth.service';
import { MessageService } from 'app/services/tools/message.service';
import { NgIf, NgFor } from '@angular/common';
import { ClrIconModule, ClrAlertModule, ClrCommonFormsModule, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrDatepickerModule, ClrInputModule, ClrTextareaModule, ClrCheckboxModule } from '@clr/angular';
import { FormsModule } from '@angular/forms';
import { OptionsTabComponent } from '../internals/options-tab/options-tab.component';

enum Tab {
  Licences,
  Options,
}

@Component({
    selector: 'app-single-historique',
    templateUrl: './single-historique.component.html',
    styleUrls: ['./single-historique.component.css'],
    standalone: true,
    imports: [NgIf, ClrIconModule, ClrAlertModule, FormsModule, ClrCommonFormsModule, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrDatepickerModule, ClrInputModule, ClrTextareaModule, NgFor, ClrCheckboxModule, OptionsTabComponent]
})
export class SingleHistoriqueComponent implements OnInit, OnDestroy {

  @Input() title: string = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private historiqueService: HistoriqueService,
    private dateService: DateService, private authService: AuthService, private messageService: MessageService) { }

  idInstance: number = -1;
  refInstall: string = '';
  idHistorique: number = -1;
  idClient: number = -1;

  fullHistorique: FullHistorique = new FullHistorique();
  fullHistoriqueSubscription: Subscription = new Subscription();

  requestCompleted: boolean = false;
  invalidForm = false;
  errorContent: string = '';
  currentTab = Tab.Licences;
  Tab = Tab

  ngOnInit(): void {
    this.requestCompleted = false;
    this.idInstance = Number(this.activatedRoute.snapshot.paramMap.get('idInstance'));
    this.refInstall = this.activatedRoute.snapshot.paramMap.get('refInstall') ?? '';
    this.idHistorique = Number(this.activatedRoute.snapshot.paramMap.get('idHistorique'));
    this.idClient = Number(this.activatedRoute.snapshot.paramMap.get('idClient'));
    this.getDetailsTable();
  }

  getDetailsTable(): void {
    this.fullHistoriqueSubscription = this.historiqueService.fullHistoriqueSubject.subscribe({
      next: (fullHistorique: FullHistorique) => {
        this.fullHistorique = fullHistorique;
        if (this.fullHistorique.historique.type === 0) { this.fullHistorique.historique.type = 1; } // display type Achat by default
        this.fullHistorique.historique.dateValidite = this.dateService.formatForDateInput(this.fullHistorique.historique.dateValidite);
        this.fullHistorique.historique.dateFacture = this.dateService.formatForDateInput(this.fullHistorique.historique.dateFacture);
        this.fullHistorique.historique.dateHeureSaisie = this.dateService.formatForDateInput(this.fullHistorique.historique.dateHeureSaisie);
        this.fullHistorique.historique.idInstance = this.idInstance;
        this.requestCompleted = true;
      },
      error: () => {
        this.fullHistorique = new FullHistorique();
        this.requestCompleted = true;
      }
    });
    this.historiqueService.getFullHistorique(this.idInstance, this.idHistorique);
  }

  getChildInfo(foundOptions: any[]): void {
    // fetch options from the option tab component !! all values are string by default its normal
    this.fullHistorique.options = foundOptions;
    this.onSubmit();
  }

  onSubmit(): void {
    this.invalidForm = false;

    const licences = this.fullHistorique.details.filter(detail => detail.nombre !== 0 && detail.nombre !== null);
    const options = this.fullHistorique.options;
    const licenceHistorique = this.fullHistorique.historique;
    if (licences.length <= 0 && options.length <= 0) {
      this.invalidForm = true;
      this.errorContent = 'Veuillez entrer au moins une indication de licence ou d\'option';
    }
    if (!this.invalidForm) {
      for (const option of options) {
        if (typeof option.valeur === 'number') { option.valeur = String(option.valeur); }
        option.idOption = Number(option.idOption);
      }
      licenceHistorique.idUser = this.authService.idUser;
      this.historiqueService.postHistorique(licenceHistorique, licences, options).subscribe({
        next: ((res: boolean) => {
          if (res) {
            this.redirectBack()
          } else {
            this.messageService.displayError(`Echec de la ${licenceHistorique.idLicenceHistorique > 0 ? 'modification' : 'création'} de l'historique`);
          }
        }),
        error: ((error) => {
          console.error(error);
          this.messageService.displayError(`Echec de la ${licenceHistorique.idLicenceHistorique > 0 ? 'modification' : 'création'} de l'historique`);
        })
      });
    }
  }

  updateType(event: Event): void {
    if (event === null) { return; }
    this.fullHistorique.historique.type = Number((event.target as HTMLInputElement).value);
  }

  redirectBack(): void {
    this.router.navigate([`/dashboard/${this.idClient}/${DashboardTab.Instance}/${DashboardPanel.None}/${this.refInstall}`]);
  }

  switchTab(tab: Tab): void {
    this.currentTab = tab;
  }

  ngOnDestroy(): void {
    if (this.fullHistoriqueSubscription) {
      this.fullHistoriqueSubscription.unsubscribe();
    }
  }
}