import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-four-oh-four',
    templateUrl: './four-oh-four.component.html',
    styleUrls: ['./four-oh-four.component.css'],
    standalone: true
})
export class FourOhFourComponent {

  constructor(private location: Location) { }

  back(): void {
    this.location.back();
  }
}
