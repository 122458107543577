<div class="content-container">
    <div class="content-area">
        <div class="title-area">
            <h4>Liste des modules</h4>
            <button (click)="addModule()" class="btn btn-success-outline">Ajouter</button>
        </div>
        <div class="main">
            <div class="left-side {{ currentModule != null ? 'main-table-div-selected' : 'main-table-div-not-selected'}}">
                <div class="table-content">
                    <div class="table-container">
                        <button id="mobileButton" (click)="addModule()" class="btn btn-success-outline">Ajouter</button>
                        <table class="table table-compact">
                            <thead>
                                <th class="hidden">IDModule</th>
                                <th style="min-width:200px">Module</th>
                            </thead>
                            <tbody>
                                <ng-container  *ngFor="let module of modulesList; let moduleIndex = index">
                                    <tr class="module-tab-line {{currentModule == module ? 'active-line' : 'inactive-line'}}" (click)="clickOnInstance($event, moduleIndex)">
                                        <td id="col_id">{{module.idModule}}</td>
                                        <td>
                                            <button class="btn btn-link">{{module.nom}} ({{module.code}})</button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <h6 class="no-data" *ngIf="modulesList.length == 0">Aucune donnée disponible</h6>
                    </div>
                </div>
            </div>
            <!-- right side : détails du module affichés à droite-->
            <div class="right-side" *ngIf="currentModule != null && inModificationModule != null">
                <div class="right-side-title">
                    <h5  ng-model="firstname"> {{currentModule.nom}} ({{currentModule.code}})</h5>
                    <div class="right-side-title-icons">
                        <cds-icon class="alert-icon hand" shape="window-close" (click)="closeClient()"></cds-icon>
                    </div>
                </div>
                <div class="module-infos scrollable"> <!-- merge avec single-module -->
                    <form clrForm (ngSubmit)="onSubmit()" #f="ngForm">
                        <clr-input-container id="champNom">
                            <label for="nomModule">Nom</label>
                            <!-- <input clrInput type="text" id="nomModule" name="nomModule" [(ngModel)]="currentModule.nom" ngModel /> -->
                            <!-- <input clrInput type="text" id="nomModule" name="nomModule" value="{{currentModule.nom}}" required> -->
                            <input clrInput type="text" id="nomModule" name="nomModule"  [(ngModel)]="inModificationModule.nom"/>
                        </clr-input-container>
        
                        <clr-input-container id="champCode">
                            <label for="codeModule">Code</label>
                            <!-- <input clrInput type="text" id="codeModule" name="codeModule" [(ngModel)]="currentModule.code" ngModel
                                required /> -->
                            <!-- <input clrInput type="text" id="codeModule" name="codeModule" value="{{currentModule.code}}" required> -->
                            <input clrInput type="text" id="codeModule" name="codeModule" [(ngModel)]="inModificationModule.code" ngModel required />
                        </clr-input-container>
        
                        <clr-input-container id="champVerifierLicences">
                            <label for="verifierLicence">Verifier Licences</label>
                            <!-- <input clrInput type="checkbox" id="verifierLicence" name="verifierLicence"
                                [(ngModel)]="currentModule.verifierLicence" clrCheckbox /> -->
                                <!-- <input clrInput type="checkbox" id="verifierLicence" name="verifierLicence"  clrCheckbox *ngIf="!currentModule.verifierLicence">
                                <input clrInput type="checkbox" id="verifierLicence" name="verifierLicence"  clrCheckbox checked *ngIf="currentModule.verifierLicence"> -->
                                <input clrInput type="checkbox" id="verifierLicence" name="verifierLicence" [(ngModel)]="inModificationModule.verifierLicence" clrCheckbox />
                        </clr-input-container>
                        <br>
                        <div class="validation">
                            <button (click)="undo()" class="btn btn-success-inline"> Annuler </button>
                            <button type="submit" class="btn btn-success-inline"> Valider </button>
                        </div>
                    </form>
                    <div *ngIf="invalidForm" class="alert alert-warning" role="alert">
                        <div class="alert-items">
                            <div class="alert-item static">
                                <div class="alert-icon-wrapper">
                                    <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                                </div>
                                <span class="alert-text">Veuillez entrer au moins un code de module</span>
                            </div>
                        </div>
                    </div>
                        
                    <!-- <div *ngIf="requestCompleted" class="content-area">
                    </div>
                    <div *ngIf="!requestCompleted" id="loading" class="loading" style="visibility:inherit">
                        Loading&#8230;
                    </div> -->
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>
