import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Module } from '../types/module';
import { ModuleService } from '../services/module.service';
import { ClrAlertModule, ClrCheckboxModule, ClrCommonFormsModule, ClrIconModule, ClrInputModule } from '@clr/angular';
import { NgFor, NgIf } from '@angular/common';
import { MessageService } from 'app/services/tools/message.service';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-modules',
    templateUrl: './modules.component.html',
    styleUrls: ['./modules.component.css'],
    standalone: true,
    imports: [ClrIconModule, NgFor, NgIf, FormsModule, ClrCommonFormsModule, ClrInputModule, ClrCheckboxModule, ClrIconModule, ClrAlertModule]
})
export class ModulesComponent implements OnInit, OnDestroy {

  modulesList: Module[] = [];
  instanceSubscription: Subscription = new Subscription();
  currentModule: Module | null = null;
  
  // form
  inModificationModule = new Module();
  moduleSubscription: Subscription = new Subscription();
  invalidForm: boolean      = false;
  requestCompleted: boolean = false;
  hello : string = "start";
  hello2!: Module;

  constructor(private moduleService: ModuleService, private router: Router, private messageService: MessageService) { }

  ngOnInit(): void {
    this.instanceSubscription = this.moduleService.modulesListSubject.subscribe({
      next:(modulesList: Module[]) => {
        this.modulesList = modulesList;
      }
    });
    this.moduleService.getAllModules(true);
  }

  addModule(): void {
    this.router.navigate(['/moduleAdd']);
  }

  clickOnInstance(event: MouseEvent, moduleListIndex: number): void {
    this.currentModule = this.modulesList[moduleListIndex];
    this.inModificationModule = Module.copy(this.modulesList[moduleListIndex]);
  }
  closeClient(): void {
    this.currentModule = null;
    //this.currentClientInfo.clear();
  }
  undo(): void {
    this.closeClient();
  }

  onSubmit(): void {
    if(this.currentModule != null){
      this.currentModule.nom = this.inModificationModule.nom;
      this.currentModule.code = this.inModificationModule.code;
      this.currentModule.verifierLicence = this.inModificationModule.verifierLicence;
    }

    if(this.inModificationModule === null) { return; }
    if (!this.inModificationModule.code) {
      this.invalidForm = true;                   // display warning message
    } else {
      this.inModificationModule.nom = this.inModificationModule.nom ? this.inModificationModule.nom : this.inModificationModule.code;
      
      
      
      this.moduleService.postModule(this.inModificationModule).subscribe({
        next: ((res: boolean) => {
          if(res) {
            this.closeClient();
          }else{
            this.messageService.displayError(`Echec de la ${this.inModificationModule && this.inModificationModule.idModule > 0 ? 'modification' : 'création'} du module`);
          }
        }),
        error: ((error: Error) => {
          console.error(error);
          this.messageService.displayError(`Echec de la ${this.inModificationModule && this.inModificationModule.idModule > 0 ? 'modification' : 'création'} du module`);
        })
      });
    }
  }


  ngOnDestroy(): void {
    this.instanceSubscription.unsubscribe();
  }
}