<div class="content-container">
    <div class="content-area">
        <div class="title-area">
            <h4>Liste des clients</h4>
            <button (click)="addClient()" class="btn btn-success-outline">Ajouter</button>
        </div>
        <div class="main">
            <!-- left side -->
            <div class="{{currentClient == null ? 'client-data-none-selected' : 'client-data-one-selected'}}">
                <div class="list-control">
                    <div class="search-control">
                        <div class="search">
                            <input class="search-input" type="text" (input)="searchClient()" [(ngModel)]="searchText"
                                id="search" clrInput placeholder="Rechercher" />
                            <cds-icon class="element" (click)="resetSearch()" shape="times"></cds-icon>
                        </div>
                        <div class="buttons">
                            <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-bottom-left"
                                (click)="reloadList()">
                                <cds-icon class="alert-icon hand" shape="history"></cds-icon>
                                <span class="tooltip-content">Recharger la liste</span>
                            </a>
                        </div>
                    </div>
                    <clr-accordion id="filtre">
                        <clr-accordion-panel [(clrAccordionPanelOpen)]="isOpen">
                            <clr-accordion-title>Filtres ({{clientList.length}} résultats)</clr-accordion-title>
                            <clr-accordion-content class="div-with-filter" *clrIfExpanded>
                                <div class="filter-panel">
                                    <div class="{{ currentClient == null ? 'filter-selection-currentClientInfo-closed' : 'filter-selection-currentClientInfo-opened'}}">
                                        <ng-container class="clr-col-3 filters" *ngFor="let filter of filters">
                                            <!-- <clr-radio-container clrInline>
                                                <label [ngStyle]="{color: getColor(filter.mode)}">{{filter.name | typePipe:
                                                    'title'}}</label>
                                                <clr-radio-wrapper>
                                                    <input (click)="updateFilters(filter.number, $event)"
                                                        [checked]="filter.mode == 2" type="radio" clrRadio [name]="filter.name"
                                                        value="2" />
                                                    <label>Oui</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                    <input (click)="updateFilters(filter.number, $event)"
                                                        [checked]="filter.mode == 1" type="radio" clrRadio [name]="filter.name"
                                                        value="1" />
                                                    <label>Non</label>
                                                </clr-radio-wrapper>
                                                <clr-radio-wrapper>
                                                    <input (click)="updateFilters(filter.number, $event)"
                                                        [checked]="filter.mode == 0" type="radio" clrRadio [name]="filter.name"
                                                        value="0" />
                                                    <label>Les 2</label>
                                                </clr-radio-wrapper>
                                            </clr-radio-container> -->
        
                                            <div class="block-filter">
                                                <div class="filter-title">
                                                    <label [ngStyle]="{color: getColor(filter.mode)}">{{filter.name | typePipe: 'title'}}</label>
                                                </div>
                                                <div class="compact-button">
                                                    <button class="radio-compact b1 {{filter.mode == 2 ? 'selected' : 'not-selected' }}" (click)="selectThisFilter(filter, 2)" type="radio" value="2" clrRadio [name]="filter.name">
                                                        <cds-icon shape="check"></cds-icon>
                                                    </button>
                                                    <button class="radio-compact b2 {{filter.mode == 1 ? 'selected' : 'not-selected' }}" (click)="selectThisFilter(filter, 1)" type="radio" value="1" clrRadio [name]="filter.name">
                                                        <cds-icon shape="times"></cds-icon>
                                                    </button>
                                                    <button class="radio-compact b3 {{filter.mode == 0 ? 'selected' : 'not-selected' }}" (click)="selectThisFilter(filter, 0)" type="radio" value="0" clrRadio [name]="filter.name">
                                                        <cds-icon shape="hashtag"></cds-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    
                                </div>
                            </clr-accordion-content>
                        </clr-accordion-panel>
                    </clr-accordion>
                    <div class="table-container {{ currentClient != null ? 
                        isOpen ? 'main-table-currentClientInfo-with-filterInfo-with' : 'main-table-currentClientInfo-with-filterInfo-without' : 
                        isOpen ? 'main-table-currentClientInfo-without-filterInfo-with' : 'main-table-currentClientInfo-without-filterInfo-without' }}">
                        <table id="mainTable" class="table table-compact table-noborder">
                            <thead class="header-client-table">
                                <th width="5%">Numéro
                                    <cds-icon class="hand sorted" (click)="sort('idClient')" shape="arrow"
                                        [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('idClient'), 'not-sorted': !(sortManager.sameCol('idClient'))}"></cds-icon>
                                </th>
                                <th style="min-width:120px">Nom client
                                    <cds-icon class="hand sorted" (click)="sort('nom')" shape="arrow"
                                        [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('nom'), 'not-sorted': !(sortManager.sameCol('nom'))}"></cds-icon>
                                </th>
                                <th width="3%"></th>
                                <th width="3%"></th>
                                <th width="10%" style="min-width:120px">Synchro licences
                                    <cds-icon class="hand sorted" (click)="sort('synchronisationLicencesEstActive')"
                                        shape="arrow"
                                        [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('synchronisationLicencesEstActive'), 'not-sorted': !(sortManager.sameCol('synchronisationLicencesEstActive'))}"></cds-icon>
                                </th>
                                <th width="10%" style="min-width:120px">Maintenance
                                    <cds-icon class="hand sorted" (click)="sort('avecMaintenance')" shape="arrow"
                                        [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('avecMaintenance'), 'not-sorted': !(sortManager.sameCol('avecMaintenance'))}"></cds-icon>
                                </th>
                                <th width="12%" style="min-width:130px">Dernière version
                                    <cds-icon class="hand sorted" (click)="sort('versionDerniereAnnonce')" shape="arrow"
                                        [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('versionDerniereAnnonce'), 'not-sorted': !(sortManager.sameCol('versionDerniereAnnonce'))}"></cds-icon>
                                </th>
                                <th width="12%" style="min-width:130px">Dernière annonce
                                    <cds-icon class="hand sorted" (click)="sort('dateDerniereAnnonce')" shape="arrow"
                                        [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('dateDerniereAnnonce'), 'not-sorted': !(sortManager.sameCol('dateDerniereAnnonce'))}"></cds-icon>
                                </th>
                            </thead>
                            <tbody *ngIf="!requestCompleted">
                                <p style="font-weight: bold;">Loading ...</p>
                            </tbody>
                            <tbody *ngIf="requestCompleted">
                                <ng-container *ngFor="let client of clientList; let clientIndex = index">
                                    <tr (click)="clickOnInstance($event, clientIndex)" (dblclick)="redirectToDashboard(client.idClient)"
                                    [ngClass]="currentClient && currentClient.idClient == client.idClient ?'active-line' : 'inactive-line'">
                                        <td>
                                            <a class="client btn btn-link">{{client.code}}</a>
                                        </td>
                                        <td class="text-col client-name">
                                            <a class="client btn btn-link client-name-link">{{client.nom}}</a>
                                        </td>
                                        <td>
                                            <a *ngIf="!client.estActif" role="tooltip" aria-haspopup="true"
                                                class="tooltip tooltip-md">
                                                <cds-icon class="warning" shape="lock"></cds-icon>
                                                <span class="tooltip-content">Certaines instances sont désactivées</span>
                                            </a>
                                        </td>
                                        <td>
                                            <a *ngIf="client.avecIncoherencesAnnoncesModules" role="tooltip"
                                                aria-haspopup="true" class="tooltip tooltip-md">
                                                <cds-icon class="danger" shape="bubble-exclamation"></cds-icon>
                                                <span class="tooltip-content">les modules annoncés ne correspondent
                                                    pas</span>
                                            </a>
                                        </td>
                                        <td>
                                            <span class="label"
                                                [ngClass]="getLabel(client.synchronisationLicencesEstActive)">
                                                {{client.synchronisationLicencesEstActive | yesNo}}
                                            </span>
                                        </td>
                                        <td>
                                            <span class="label" [ngClass]="getLabel(client.avecMaintenance)">
                                                {{client.avecMaintenance | yesNo}}
                                            </span>
                                        </td>
                                        <td class="text-col">
                                            <span *ngIf="client.versionDerniereAnnonce != '' && client.dateDerniereAnnonce">
                                                {{client.versionDerniereAnnonce}}
                                            </span>
                                        </td>
                                        <td class="text-col">
                                            <span *ngIf="client.versionDerniereAnnonce != '' && client.dateDerniereAnnonce">
                                                {{client.dateDerniereAnnonce | date: 'd.MM.yyyy, hh:mm'}}
                                            </span>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <h5 class="no-data" *ngIf="clientList.length == 0 && requestCompleted">Aucune donnée disponible</h5>
                </div>
            </div>
            <!-- right side : détails d'un client affichés à droite-->
            <div class="right-side" *ngIf="currentClient != null">
                <div class="right-side-title">
                    <h5>{{currentClient.code}} - {{currentClient.nom}}</h5>
                    <div class="right-side-title-icons">
                        <!-- <cds-icon class="alert-icon hand" shape="pop-out"
                            (click)="redirectToDashboard(currentClient.idClient)"></cds-icon> -->
                        <cds-icon class="alert-icon hand" shape="window-close" (click)="closeClient()"></cds-icon>
                    </div>
                </div>

                <div class="client-infos scrollable">
                    <!-- General -->
                    <clr-accordion>
                        <clr-accordion-panel>
                            <clr-accordion-title class="accordion-title">
                                General
                                <cds-icon class="alert-icon hand" shape="pop-out"
                                    (click)="redirectToDashboard(currentClient.idClient)"></cds-icon>
                            </clr-accordion-title>
                            <clr-accordion-content *clrIfExpanded class="clr-accordion-content">
                                <p class="checkbox">Avec Maintenance: <input type="checkbox" clrCheckbox
                                        [checked]="currentClient.avecMaintenance" disabled /></p>
                                <p>Remarque Interne: {{currentClient.remarque}}</p>
                                <p class="checkbox">Sync Licences: <input type="checkbox" clrCheckbox
                                        [checked]="currentClient.synchronisationLicencesEstActive" disabled /></p>
                            </clr-accordion-content>
                        </clr-accordion-panel>
                    </clr-accordion>

                    <!-- ========================= timesheet =================================-->
                    <clr-accordion *ngIf="currentClient.site.url != ''">
                        <clr-accordion-panel>
                            <clr-accordion-title class="accordion-title">
                                Timesheet
                                <cds-icon class="alert-icon hand" shape="pop-out"
                                    (click)="newWindowWithTimesheetEdition(currentClient.idClient, currentClient.site.idInstance)"></cds-icon>
                            </clr-accordion-title>
                            <clr-accordion-content *clrIfExpanded class="clr-accordion-content">
                                <p>Accéder: <a class="hand"
                                        (click)="openTimesheet(currentClient.site.url)">{{currentClient.site.url}}</a></p>
                            </clr-accordion-content>
                        </clr-accordion-panel>
                    </clr-accordion>

                    <!-- ========================= hébergements =================================-->
                    <clr-accordion *ngIf="currentClientHebergement && currentClientHebergement.idHebergement > 0">
                        <clr-accordion-panel>
                            <clr-accordion-title class="accordion-title">
                                Hébergement
                                <cds-icon class="alert-icon hand" shape="pop-out"
                                    (click)="newWindowWithHebergement(currentClient.idClient)"></cds-icon>
                            </clr-accordion-title>
                            <clr-accordion-content *clrIfExpanded class="clr-accordion-content">
                                <p>Serveur: {{currentClientHebergement.emplacement}}</p>
                                <p class="checkbox">Guacamole: <input type="checkbox" clrCheckbox
                                        [checked]="currentClientHebergement.guacamole" disabled /> </p>
                                <p>Url: {{currentClientHebergement.url}}</p>
                                <p>Remarque: {{currentClientHebergement.remarque}}</p>
                            </clr-accordion-content>
                        </clr-accordion-panel>
                    </clr-accordion>

                    <!-- ========================= infos supplémentaires simples =================================-->
                    <clr-accordion *ngIf="currentClientInfo.tabInfoSimple && currentClientInfo.tabInfoSimple.length > 0">
                        <clr-accordion-panel>
                            <clr-accordion-title class="accordion-title">Autres Informations <cds-icon
                                    class="alert-icon hand" shape="pop-out"
                                    (click)="newWindowWithClientInfos(currentClient.idClient)"></cds-icon></clr-accordion-title>
                            <clr-accordion-content *clrIfExpanded class="clr-accordion-content">
                                <div *ngFor="let item of currentClientInfo.tabInfoSimple">
                                    <p><strong>{{item.nom}}</strong></p>
                                    <p class="data-content">{{item.valeur}}</p><br>
                                </div>
                                <h5 class="no-data" *ngIf="currentClientInfo.tabInfoSimple.length <= 0">Aucune donnée
                                    disponible</h5>
                            </clr-accordion-content>
                        </clr-accordion-panel>
                    </clr-accordion>
                </div>
            </div>
        </div>
    </div>
</div>