<div id="main">
    <div id="main-form" *ngIf="hebergement != null; else addBtn">
        <!-- Champ emplacement -->
        <clr-input-container>
            <label class="clr-col-lg-3" for="emplacement">Serveur</label>
            <input class="clr-col-lg-9 input-info" clrInput type="text" [(ngModel)]="hebergement.emplacement" name="emplacement" id="emplacement"
                [disabled]="boolErrorHebergement" (input)="hasEdited=true">
        </clr-input-container>

        <!-- Checkbox guacamole -->
        <clr-input-container>
            <label class="clr-col-lg-3" for="guacamole">Guacamole</label>
            <input class="clr-col-lg-9" clrCheckbox clrInput type="checkbox" [(ngModel)]="hebergement.guacamole" name="guacamole"
                id="guacamole" [disabled]="boolErrorHebergement" (input)="hasEdited=true">
        </clr-input-container>

        <!-- Champ URL -->
        <clr-input-container>
            <label class="clr-col-lg-3" for="url">URL</label>
            <input class="clr-col-lg-9 input-info" clrInput type="text" [(ngModel)]="hebergement.url" name="url" id="url"
                [disabled]="boolErrorHebergement" (input)="hasEdited=true">
        </clr-input-container>

        <!-- Champ Remarque -->
        <clr-textarea-container>
            <label class="clr-col-lg-3" for="remarque">Remarque</label>
            <textarea clrTextarea [(ngModel)]="hebergement.remarque" name="remarque" id="remarque"
                class="clr-col-lg-9 remarqueTextArea" [disabled]="boolErrorHebergement" (input)="hasEdited=true"></textarea>
        </clr-textarea-container>

        <!-- Champ date Debut de l'hebergement -->
        <clr-date-container>
            <label class="clr-col-lg-3" for="dateDebut">Début de l'hébergement</label>
            <input class="clr-col-lg-9" type="date" clrDate id="dateDebut" [(ngModel)]="hebergement.dateDebut" name="dateDebut"
                [disabled]="boolErrorHebergement" (input)="hasEdited=true" />
        </clr-date-container>
    </div>
    
    <ng-template #addBtn>
        <button [disabled]="idClient <= 0" type="button" class="btn btn-primary" (click)="addHebergement()"> Ajouter un
            hébergement <cds-icon shape="host"></cds-icon></button>
    </ng-template>
    <div id="user-form" *ngIf="hebergement != null">
        <!-- Formulaire d'ajout d'un utilisateur -->
        <div class="form-card" *ngIf="boolForm">
            <!-- Champ login Server-->
            <clr-input-container>
                <label for="loginServeur">Login Serveur</label>
                <input clrInput type="text" [(ngModel)]="newUtilisateur.loginServeur" name="loginServeur"
                    id="loginServeur" (input)="hasEdited=true">
            </clr-input-container>

            <!-- Champ loginGuacamole -->
            <clr-input-container>
                <label for="loginGuacamole">Login Guacamole</label>
                <input clrInput type="text" [(ngModel)]="newUtilisateur.loginGuacamole" name="loginGuacamole"
                    id="loginGuacamole" (input)="hasEdited=true">
            </clr-input-container>

            <!-- Champ dateActivation -->
            <clr-date-container>
                <label for="dateActivation">Date d'activation</label>
                <input type="date" clrDate id="dateActivation" [(ngModel)]="newUtilisateur.dateActivation"
                    name="dateActivation" (input)="hasEdited=true" />
            </clr-date-container>

            <!-- Champ dateDesactivation -->
            <clr-date-container>
                <label for="dateDesactivation">Date de désactivation</label>
                <input type="date" clrDate id="dateDesactivation" [(ngModel)]="newUtilisateur.dateDesactivation"
                    name="dateDesactivation" (input)="hasEdited=true" />
            </clr-date-container>

            <!-- Checkbox avecBureau -->
            <clr-input-container>
                <label for="avecBureau">Avec Bureau</label>
                <input clrCheckbox clrInput type="checkbox" [(ngModel)]="newUtilisateur.avecBureau" name="avecBureau"
                    id="avecBureau" (input)="hasEdited=true">
            </clr-input-container>

            <!-- Bouton Ajout -->
            <button type="button" class="btn btn-danger btn-save" (click)="annulerSaisie()">Annuler</button>
            <button type="button" class="btn btn-primary btn-save" (click)="addOrModifyHebergementUser()"
                *ngIf="hebergement.idHebergement > 0">
                <span *ngIf="newUtilisateur.idHebergementUser <= 0;else edit">Ajouter</span>
                <ng-template #edit>Modifier</ng-template>
            </button>
        </div>

        <!-- Liste des utilisateurs -->
        <clr-datagrid id="userTable" *ngIf="!boolForm && hebergement">
            <clr-dg-column>
                <div id="userTableTitle">
                    Utilisateurs: {{this.hebergement.utilisateurs.length}}
                    <cds-icon style="cursor: pointer;" shape="plus" (click)="boolForm = true"
                        *ngIf="!boolErrorHebergement"></cds-icon>
                </div>
            </clr-dg-column>
            <clr-dg-row *ngFor="let user of hebergement.utilisateurs">
                <clr-dg-cell>{{user.loginServeur}}</clr-dg-cell>
                <clr-dg-cell class="align-end">
                    <cds-icon shape="pencil" (click)="modifyUtilisateur(user);"></cds-icon> <cds-icon shape="trash"
                        (click)="deleteUtilisateur(user)"></cds-icon>
                </clr-dg-cell>
                <clr-dg-row-detail *clrIfExpanded class="userTableDetail">
                    <span><strong>Login Guacamole: </strong>{{user.loginGuacamole}}</span>
                    <span><strong>Date d'activation: </strong>{{user.dateActivation}}</span>
                    <span><strong>Date de désactivation: </strong>{{user.dateDesactivation}}</span>
                    <span class="checkbox"><strong>Avec Bureau: </strong> <input type="checkbox" clrCheckbox
                            [checked]="user.avecBureau" disabled /></span>
                </clr-dg-row-detail>
            </clr-dg-row>
        </clr-datagrid>
    </div>
</div>

<button *ngIf="hebergement != null && creatingHebergement == false" type="button" class="btn btn-danger"
    (click)="this.modalConfirmationSupression = true;"> Supprimer
    l'hébergement
</button>
<button *ngIf="hebergement != null && creatingHebergement == true" type="button" class="btn btn-danger"
    (click)="annulerCreation()"> Annuler
</button>
<!-- Bouton Sauvegarder -->
<button type="button" class="btn btn-primary btn-save" (click)="saveHebergement()" [disabled]="boolErrorHebergement"
    *ngIf="hasEdited">Sauvegarder</button> <cds-icon shape="check-circle" *ngIf="boolSave" id="iconSave"></cds-icon>

<clr-modal [(clrModalOpen)]="modalConfirmationSupression">
    <h3 class="modal-title">Supprimer l'hebergement</h3>
    <div class="modal-body">
        <p>Êtes-vous sur de vouloir supprimer cet hebergement? Cette action est permanente et ne peut pas être defaite.
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modalConfirmationSupression = false">Annuler</button>
        <button type="button" class="btn btn-danger"
            (click)="modalConfirmationSupression = false; supprimerHebergement()">Supprimer</button>
    </div>
</clr-modal>