import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Module } from '../types/module';
import { Observable, Subject } from 'rxjs';
import {  MessageService } from './tools/message.service';

@Injectable()

export class ModuleService {
    private modulePath = 'modules';

    private modulesList: Module[] = [];
    modulesListSubject = new Subject<Module[]>();

    private module!: Module | null;
    moduleSubject = new Subject<Module | null>();

    constructor(private httpClient: HttpClient, private messageService: MessageService) { }

    private emitModulesListSubject(): void {
        this.modulesListSubject.next(this.modulesList.slice());
    }

    private emitModuleSubject(): void {
        this.moduleSubject.next(this.module);
    }

    getAllModules(reloadAnyway: boolean = false): void {
        if (this.modulesList.length > 0 && !reloadAnyway) {
            this.emitModulesListSubject();
        }
        else
        {
            this.httpClient.get<Module[]>(this.modulePath).subscribe({
                next: (response) => {
                    this.modulesList = response;
                    this.emitModulesListSubject();
                },
                error: (error: Error) => {
                    console.error(error);
                    this.messageService.displayError('Impossible de récupérer la liste des modules');
                }
            });
        }
    }

    getModuleById(idModule: number): void {
        const path = `${this.modulePath}/${idModule}`;
        this.httpClient.get<Module>(path).subscribe({
            next: (response) => {
                this.module = response;
                this.emitModuleSubject();
            },
            error: (error: Error) => {
                console.error(error);
                this.messageService.displayError('Echec lors de la récupération du module');
                this.module = null;
                this.emitModuleSubject();
            }
        });
    }

    postModule(newModule: Module): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
        return this.httpClient.post<boolean>(this.modulePath, JSON.stringify(newModule), {headers});
    }
}
