export interface SiteAPI {
    url: string;
    idInstance: number;
}

export interface SiteInfosAPI {
    versionFrontend: string;
    versionAPI: string;
    versionWebdev: string;
    urlAPI: string;
    information: string;
    instanceRefInstall: string;
    idClient: number;
}

export class Site {
    private static readonly ERROR_ICON: string = "❗️";
    private static readonly ALERT_ICON: string = " ⚠️ ";
    private static readonly ERROR_CODE: string = "Erreur";

    // Basic Info
    url!: string;
    idInstance!: number;
    // Additional Info
    infos!: SiteInfosAPI;
    mcrsuite: string = '';
    alertes: string = '';

    constructor(url: string = '', idInstance: number = 0) {
        this.url = url;
        this.idInstance = idInstance;
        this.infos = {
            versionFrontend: '',
            versionAPI: '',
            versionWebdev: '',
            urlAPI: '',
            information: '',
            instanceRefInstall: '',
            idClient: 0,
        };
    }

    static fromSiteAPI(siteAPI: SiteAPI): Site {
        return new Site(siteAPI.url, siteAPI.idInstance);
    }

    static copy(s: Site): Site {
        const site = new Site(s.url, s.idInstance);
        site.infos = s.infos;
        site.mcrsuite = s.mcrsuite;
        site.alertes = s.alertes;
        return site;
    }

    // Replace version string with a more readable format
    buildVersionWebdev(): void {
        const match = this.infos.versionWebdev.match(/wd[0-9]+/);
        if (match) {
            switch (match[0]) {
                case "wd280":
                    this.infos.versionWebdev = "Webdev 28";
                    break;
                case "wd260":
                    this.infos.versionWebdev = "Webdev 26";
                    break;
                default:
                    this.infos.versionWebdev = match[0];
            }
        } else {
            this.infos.versionWebdev = Site.ERROR_ICON;
        }
    }

    completeFromInfoAPI(infos: SiteInfosAPI): void {
        this.infos = infos;
        this.buildErrorMessages();
    }

    // Replace info with appropriate error messages if needed
    private buildErrorMessages(): void {
        if (this.infos.versionAPI === "Error parsing Infinity value. Path '', line 1, position 1.") {
            this.infos.versionAPI = Site.ERROR_ICON;
            this.alertes = Site.ALERT_ICON;
        }
        if (this.infos.urlAPI === Site.ERROR_CODE) {
            this.infos.urlAPI = Site.ERROR_ICON;
            this.alertes = Site.ALERT_ICON;
        }
        if (this.infos.versionAPI === Site.ERROR_CODE) {
            this.infos.versionAPI = Site.ERROR_ICON;
            this.alertes = Site.ALERT_ICON;
        }
        if (this.infos.versionFrontend === Site.ERROR_CODE) {
            this.infos.versionFrontend = Site.ERROR_ICON;
            this.alertes = Site.ALERT_ICON;
        }
        if (this.mcrsuite === null) {
            this.mcrsuite = Site.ERROR_ICON;
        }
    }
}
