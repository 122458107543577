export interface Option {
    idOption: number;
    nom: string;
    type: number;
    description: string;
    mode: number;
    section: string;
}

export function emptyOption(): Option {
    return {idOption: 0, nom: '', type: 1, description: '', mode: 1, section: 'BASE'};
}

export class Option {

    idOption: number = 0;
    nom: string = '';
    type: number = 1;
    description: string = '';
    mode: number = 1;
    section: string = 'BASE';

    constructor() {
    }

    static copy(o: Option): Option {
        const optionCopy = new Option();
        optionCopy.idOption = o.idOption;
        optionCopy.nom = o.nom;
        optionCopy.type = o.type;
        optionCopy.description = o.description;
        optionCopy.mode = o.mode;
        optionCopy.section = o.section;

        return optionCopy;
    }
}