import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Option, emptyOption } from '../../types/option';
import { OptionInstance } from '../../types/option_instance';
import { OptionService } from '../../services/option.service';
import { NgIf, NgFor } from '@angular/common';
import { ClrInputModule, ClrCommonFormsModule, ClrIconModule } from '@clr/angular';
import { FormsModule } from '@angular/forms';
import { TypePipe } from '../../tools/pipes/type.pipe';

@Component({
    selector: 'app-options-tab',
    templateUrl: './options-tab.component.html',
    styleUrls: ['./options-tab.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, ClrInputModule, FormsModule, ClrCommonFormsModule, ClrIconModule, TypePipe]
})
export class OptionsTabComponent implements OnInit, OnDestroy {

  @Input() optionsInstanceList: OptionInstance[] = [];
  @Input() mode: number       = 0;
  @Input() idInstance: number = 0;
  @Output() notify: EventEmitter<OptionInstance[]> = new EventEmitter<OptionInstance[]>();

  optionsList: Option[] = [];
  optionListSubscription: Subscription = new Subscription();
  requestCompleted = false;

  constructor(private optionService: OptionService) { }

  ngOnInit(): void {
    if (!this.optionsInstanceList) { this.optionsInstanceList = []; }
    this.getOptions();
  }

  emitOptions(save: boolean): void {
    if (this.optionsInstanceList) {
      for (const optionInstance of this.optionsInstanceList) {
        optionInstance.idOption = Number(optionInstance.idOption);
        optionInstance.valeur   = String(optionInstance.valeur);
      }
    } else {
      this.optionsInstanceList = [];
    }
    if (save) {
      this.notify.emit(this.optionsInstanceList);
    }
  }

  getOptions(): void {
    this.optionListSubscription = this.optionService.optionsListSubject.subscribe({
      next:(optionsList: Option[]) => {
        this.optionsList      = optionsList;
        this.requestCompleted = true;
      }
    });
    this.optionService.getAll(this.mode, true);
  }

  getType(idOption: number): number {
    const foundOption: Option[] = this.optionsList.filter(option => option.idOption === Number(idOption));
    return foundOption.length > 0 ? foundOption[0].type : 2;
  }

  addRow(): void {
    const newHistoOption: OptionInstance = { idOption: 0, valeur: '0', nomOption: '', type: 1, description: '' };
    this.optionsInstanceList.push(newHistoOption);
  }

  /**
   * Efface un row des options
   * @param idOption idoption
   */
  removeRow(idOption: number): void {
    for (let i = 0; i < this.optionsInstanceList.length; ++i) {
      if (this.optionsInstanceList[i].idOption === idOption) {
        this.optionsInstanceList.splice(i, 1);
        this.emitOptions(false)
      }
    }
  }

  sortOptionList(optionsList: Option[]): any[] {              // to keep for a better selection in the future
    const sortedOptionsList: {[key: string]: Option[]} = {};
    let previousOption: Option = emptyOption();
    for (const option of optionsList) {

      if (previousOption && previousOption.section === option.section) {
        sortedOptionsList[previousOption.section].push(option);
      } else {
        sortedOptionsList[option.section] = [option];
        previousOption = option;
      }
    }
    // Convertis en tableau d'options, retour attendu par la méthode
    return Object.values(sortedOptionsList).flat();
  }

  ngOnDestroy(): void {
    if (this.optionListSubscription) { this.optionListSubscription.unsubscribe(); }
  }
}
