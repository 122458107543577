import { Component, OnInit } from '@angular/core';
import { ExcelExportService } from 'app/services/tools/excel_export.service';
import { NgIf } from '@angular/common';
import { ClrIconModule, ClrAlertModule } from '@clr/angular';

@Component({
    selector: 'app-export-manager',
    templateUrl: './export-manager.component.html',
    styleUrls: ['./export-manager.component.css'],
    standalone: true,
    imports: [NgIf, ClrIconModule, ClrAlertModule]
})
export class ExportManagerComponent implements OnInit {

  excelLoading: boolean = false;

  constructor(private excelExportService: ExcelExportService) {}

  ngOnInit(): void {
    this.excelExportService.excelLoading.subscribe((value: boolean) => this.excelLoading = value);
  }
}
