// ----------- constantes
// type d'inputs disponibles
export enum InputType {
    STRING = 0,
    BOOLEAN = 1,
    NUMBER = 2
}

// informations échangées avec l'API avec tout dedans
export class InfosClient {
    tabInfoSimple: InfoSimple[] = [];
    tabInfoContact: InfoContact[] = [];

    constructor() {
        this.tabInfoSimple = [];
        this.tabInfoContact = [];
    }
    clear(): void {
        this.tabInfoSimple = [];
        this.tabInfoContact = [];
    }
}

// information de contact
export class InfoContact {
    id: number = 0;
    nom: string = '';
    description: string = '';
    tel: string = '';
    mail: string = '';
    remarque: string = '';
    static createNew(): InfoContact {
        return {id: -1, nom: '', description: '', tel: '', mail: '', remarque: ''};
    }

    static getNewId(): number { return InfoContact.COUNTER++; }

    private static COUNTER: number = 0;
}

// information sous forme de champ simple - format pour l'API simplifié
export interface InfoSimpleAPI {
    id:number;
    idItem: number;
    valeur: string;
}

// information sous forme de champ simple - format complet
export class InfoSimple {
    id: number;
    idItem: number;
    nom: string;
    type: InputType;
    noLignes: number;
    valeur: string;
    constructor(id: number, nom: string, type: InputType, noLignes:number, idItem: number = -1, valeur: string = '') {
        this.id       = id;
        this.nom      = nom;
        this.type     = type;
        this.noLignes = noLignes;
        this.idItem   = idItem;
        this.valeur   = valeur;
    }
    private static COUNTER: number = 0;

    ToContentAPI(): InfoSimpleAPI {
        return { id: this.id, idItem: this.idItem, valeur: this.valeur };
    }

    static getNewId(): number { return InfoSimple.COUNTER++; }

    static createFromType(selectedInfoType: InfoSimple): InfoSimple {
        return new InfoSimple(selectedInfoType.id, selectedInfoType.nom, selectedInfoType.type, selectedInfoType.noLignes);
    }

    static fromInfoAPI(infoAPI: InfoSimpleAPI, nom: string, type: InputType, noLignes: number): InfoSimple {
        return new InfoSimple(infoAPI.id,nom,type,noLignes,infoAPI.idItem,infoAPI.valeur);
    }
}
