import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { Client, ClientBase } from '../types/client';
import { InfoContact, InfoSimple, InfosClient, InputType } from 'app/types/clientInfo';
import { MessageService } from './tools/message.service';

@Injectable()
export class ClientService {
    private clientPath = 'clients';
    private clientList: Client[] = [];
    clientListSubject = new Subject<Client[]>();

    private availableInfoTypes: InfoSimple[] = [];

    // BehaviorSubject pour que les prochaines inscriptions puissent récupérer une valeur déjà récupérée par le Subject dans le passé qui est mémorisée
    // le sujet "mémorise" ce qu'il a récupéré, ici c'est important car on charge ça au tout début
    availableInfoTypesSubject: Subject<InfoSimple[]> = new BehaviorSubject<InfoSimple[]>([]);

    private readonly postHeaders:HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    constructor(private httpClient: HttpClient, private httpBackend: HttpBackend, private messageService: MessageService) {}

    /*======================================== EMIT ========================================*/
    private emitClientListSubject(): void {
        this.clientListSubject.next(this.clientList.slice());
    }

    private emitClientInfoTypes(): void {
        this.availableInfoTypesSubject.next(this.availableInfoTypes);
    }
    /*======================================== ACTIONS - GET ================================*/
    getAllClient(): void {
        this.httpClient.get<Client[]>(this.clientPath).subscribe({
            next:(response:Client[]) => {
                this.clientList = response;
                this.emitClientListSubject();
            },
            error:(error: Error) => {
                console.error(error);
                this.messageService.displayError('Echec lors de la récupération des clients.');
                this.clientList = [];
                this.emitClientListSubject();
            }
        });
    }

    getClientById(clientId: number): Observable<Client> {
        const foundClient = this.clientList ? this.clientList.filter(c => c.idClient === clientId) : null
        if(foundClient && foundClient.length === 1) {
            return of(foundClient[0])
        } else {
            return this.httpClient.get<Client>(`${this.clientPath}/${clientId}`)
        }
    }

    /*======================================== ACTIONS - POST ================================*/

    postClient(client: ClientBase): Observable<number> {
        return this.httpClient.post<number>(this.clientPath, JSON.stringify(client), {headers: this.postHeaders});
    }

    /*======================================== INFOS CLIENTS ================================*/

    getClientInfoData(clientId: number): Observable<InfosClient> {
        return this.httpClient.get<InfosClient>(`${this.clientPath}/infos/${clientId}`);
    }

    getAllClientInfoTypes(): void {
        if(this.availableInfoTypes && this.availableInfoTypes.length > 0) {
            return;
        }
        const http: HttpClient = new HttpClient(this.httpBackend);
        http.get<any>('config_file.json').subscribe({
            next: (e:any) => {
                if (e.info_types) {
                    for (const el of e.info_types) {
                        this.availableInfoTypes.push(new InfoSimple(el.id, el.nom, el.type, el.noLignes));
                    }
                }
                if(this.availableInfoTypes.length <= 0) {
                    console.error('Attention: aucun type d\'informations n\'est enregistrée dans le fichier de configuration, est-ce normal ?');
                }
                this.emitClientInfoTypes();
            }
        });
    }

    saveContactInfo(contact: InfoContact, clientCode: string): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.clientPath}/infos/contact/${clientCode}`, JSON.stringify(contact), {headers: this.postHeaders});
    }

    deleteContactInfo(infoId: number, idClient: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.clientPath}/infos/${idClient}/${infoId}/false`);
    }

    saveInfoSimple(info: InfoSimple, clientCode: string): Observable<boolean> {
        if(info.type !== InputType.STRING) {
            info.valeur = JSON.stringify(info.valeur);
        }
        return this.httpClient.post<boolean>(`${this.clientPath}/infos/simple/${clientCode}`, JSON.stringify(info), {headers: this.postHeaders});
    }

    deleteInfoSimple(infoId: number, idClient: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.clientPath}/infos/${idClient}/${infoId}/true`);
    }
}
