import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { Module, emptyModule } from '../types/module';
import { ModuleService } from '../services/module.service';
import { MessageService } from 'app/services/tools/message.service';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClrCommonFormsModule, ClrInputModule, ClrCheckboxModule, ClrIconModule, ClrAlertModule } from '@clr/angular';
@Component({
    selector: 'app-single-module',
    templateUrl: './single-module.component.html',
    styleUrls: ['./single-module.component.css'],
    standalone: true,
    imports: [RouterLink, NgIf, FormsModule, ClrCommonFormsModule, ClrInputModule, ClrCheckboxModule, ClrIconModule, ClrAlertModule]
})
export class SingleModuleComponent implements OnInit, OnDestroy {

  constructor(private moduleService: ModuleService, private router: Router, private activatedRoute: ActivatedRoute,
    private messageService: MessageService) { }

  module!: Module | null;
  moduleSubscription: Subscription = new Subscription();
  invalidForm: boolean      = false;
  requestCompleted: boolean = false;

  ngOnInit(): void {
    this.module = emptyModule();
    if (Number(this.activatedRoute.snapshot.paramMap.get('id'))) {
      this.getModuleById(Number(this.activatedRoute.snapshot.paramMap.get('id')));
    } else {
      this.requestCompleted = true;
    }
  }

  getModuleById(idModule: number): void {
    this.moduleSubscription = this.moduleService.moduleSubject.subscribe({
      next:(module: Module | null) => {
        this.module           = module;
        this.requestCompleted = true;
      },
      error:() =>  this.requestCompleted = true
    });
    this.moduleService.getModuleById(idModule);
  }

  onSubmit(): void {
    if(this.module === null) { return; }
    if (!this.module.code) {
      this.invalidForm = true;                   // display warning message
    } else {
      this.module.nom = this.module.nom ? this.module.nom : this.module.code;
      this.moduleService.postModule(this.module).subscribe({
        next: ((res: boolean) => {
          if(res) {
            this.router.navigate(['/modules'])
          } else {
            this.messageService.displayError(`Echec de la ${this.module && this.module.idModule > 0 ? 'modification' : 'création'} du module`);
          }
        }),
        error: ((error: Error) => {
          console.error(error);
          this.messageService.displayError(`Echec de la ${this.module && this.module.idModule > 0 ? 'modification' : 'création'} du module`);
        })
      });
    }
  }

  ngOnDestroy(): void {
    if (this.moduleSubscription) {
      this.moduleSubscription.unsubscribe();
    }
  }
}
