import { Filter } from '../../types/filter';
import { UserGoogle } from '../../types/user';

export enum DELETE_MODE {
  VOID = 0,
  INSTANCE,
  HISTO
}
export class ToolsService {

  compareAndEscape(element1: string, element2: string): string {
    const result = element1 ? element1 : element2;
    if (result !== null){
      return result.replace(/'/g, '');
    } else {
      return '';
    }
  }

  removeAccents(message: string): string {
    // décompose lettre accent et remplace les accents extraits par des chars vides
    return message.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  // -------------------------------- filters and sort management -------------------------- //
  // REMINDER : session -> effacé lorsque la fenêtre/onglet est fermé
  saveFilters(filters: Filter[], isOpen: boolean, txt: string): void {
    this.sessionSetByKeyValue('filters', JSON.stringify(filters));
    this.sessionSetByKeyValue('seeFilters', isOpen.toString());
    this.sessionSetByKeyValue('filter', txt);
  }

  resetFilter(): void {
    this.sessionSetByKeyValue('filter', '');
  }

  findFilters(): Filter[] {
    if(this.filtersInStorage()) {
      return JSON.parse(this.sessionGetByKey('filters') ?? '');
    } else {
      return [];
    }
  }

  filtersInStorage(): boolean {
    return this.sessionGetByKey('filters') !== '';
  }

  clearFilters(): void {
    if(this.filtersInStorage()) {
      sessionStorage.removeItem('filters');
    }
  }

  // -------------------------------- user management ----------------------------------------- //

  setUser(userGoogle: UserGoogle, accessToken: string, userId: string): void {
    this.localSetByKeyValue('access_token', accessToken); 
    // il a une durée de vie courte, il est donc difficilement utilisable de manière malicieuse très longtemps tel quel
    // et le refresh token est gardé sur le serveur donc pas moyen de le rafraîchir pour un attaquant (et il dure qu'un jour)
    // de plus pour identifier le refresh token en base et l'utiliser on utilise le tokenId qui n'est pas déduisible

    // problème éventuel = accès pendant 1 jour si on arrive à extraire le googleUserTokenId (a fixer)

    this.localSetByKeyValue('userName', userGoogle.Name);
    this.localSetByKeyValue('userEmail', userGoogle.Email);
    this.localSetByKeyValue('userPicture', userGoogle.Picture);
    this.localSetByKeyValue('tokenId', userId);
  }

  clearUser(): void {
    this.localRemoveByKey('access_token');
    this.localRemoveByKey('userName');
    this.localRemoveByKey('userEmail');
    this.localRemoveByKey('userPicture');
    this.localRemoveByKey('tokenId');
  }

  setAccessToken(accessToken: string): void {
    this.localRemoveByKey('access_token');
    this.localSetByKeyValue('access_token', accessToken);
  }

  // -------------------------------- generic session storage methods -------------------------- //

  sessionGetByKey(key: string): string {
    return sessionStorage.getItem(key) ?? '';
  }

  private sessionSetByKeyValue(key: string, val: string): void {
    sessionStorage.setItem(key, val);
  }

  // -------------------------------- generic local storage methods -------------------------- //

  localGetByKey(key: string): string {
    return localStorage.getItem(key) ?? '';
  }

  localSetByKeyValue(key: string, val: string): void {
    localStorage.setItem(key, val);
  }

  private localRemoveByKey(key: string): void {
    localStorage.removeItem(key);
  }
}
