import { HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ToolsService } from 'app/services/tools/tools.service';

@Injectable()
export class HttpResponseInterceptor {
    
    constructor(private authService: AuthService, private toolsService: ToolsService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // ici on s'intéresse à intercepter la réponse
        return next.handle(req).pipe(
            map((event) => {
                if (event instanceof HttpResponse) {
                    if (event.body.data !== undefined && event.body.detail !== undefined) {
                        const accessToken = event.headers.get('access_token'); 
                        const user_id = event.headers.get('user_id'); 
                        // on récupère au passage l'access token si le serveur nous en redonne un nouveau
                        if (accessToken) {
                            this.toolsService.setAccessToken(accessToken);
                        }
                        if (user_id) {
                            // le backend a vu que je l'avais perdu et me le redonne ici en entête de réponse
                            this.authService.idUser = +user_id;
                        }
                        if (event.body.detail.status !== 200) {
                            console.error(event.body.detail.message);
                            console.error(event.body.detail.stackTrace);
                            throw event;
                        }
                        return event.clone({ body: event.body.data, headers: event.headers });
                    }
                }
                return event;
            }), 
            tap({
                error: (error) => {
                    // on intercepte une erreur spécifique retournée en guise de réponse par le serveur ici
                    if (error instanceof HttpErrorResponse) {
                        if(error.status && error.status === 401) {
                            console.error('Authentification impossible, veuillez vous reconnecter');
                            this.authService.logOut();
                        } else {
                            console.error(`${error.url} : ${error.message} - ${error.statusText} (${error.status})}`);
                        }
                    }
                }
            })
        );
    }
}
