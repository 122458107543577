import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'typePipe',
    standalone: true
})
export class TypePipe implements PipeTransform {

  transform(value: any, param: string): any {
    if(!value || value === '') { return value; }
    switch (param) {
     case 'type':
      switch (value) {
        case 1 :
          return 'Achat';
        case 2 :
          return 'Location';    // type d'historique
        case 3 :
          return 'Offert';
        case 4 :
          return 'Maintenance';
        case '3':
          return 'Offert';
      }
      break;

      case 'typeOption':
        switch (value) {
          case 1 :
            return 'Booléen';
          case 2 :
            return 'Numérique';    // type d'historique
          case 3 :
            return 'Texte';
          case 4 :
            return 'Date / Heure';
        }
        break;

      case 'modeOption':
        switch (value) {
          case 1 :
            return 'Histo';
          case 2 :
            return 'Unique';    // type d'historique
        }
        break;

      case 'dateValidite' :
      case 'dateFacture' :
      case 'dateFinMaintenance' :
      case 'dateHeureSaisie' :
        if (new Date(value).getFullYear() < 2000) {
          return '';
        } else {
          return new Date(value).toLocaleDateString('en-GB');
        }

      case 'total' :
        if (value === 0) {
          return '';
        }
        return value;

      case 'title' :
        if (value === 'dateValidite') {
          return 'Date validité';
        } else if (value === 'initialesUser') {
          return 'Utilisateur';
        } else if (value === 'dateHeureSaisie') {
          return 'Date saisie';                                 // only day for now
        }

        value = value.replace(/([A-Z][a-z])/g, ' $1');            // replace : uppper letter + lower letter with space + upper letter
        if (value.length > 4) { value = value.toLowerCase(); }     // module codes stay in full upper
        return value.charAt(0).toUpperCase() + value.slice(1);    // first letter capitalized
      default :
        if (Object.prototype.hasOwnProperty.call(value, 'nombre')) {
          if (value.nombre === 0) {
            return '';
          }
          if (value.flottant) {
            return `${value.nombre}  F`;      // add F in historique table if licence flottante
          } else {
            return `${value.nombre}`;
          }
        } else {
          return value;
        }
      }
    }
  }
