import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Client } from '../types/client';
import { Filter } from '../types/filter';
import { ClientService } from '../services/client.service';
import { ToolsService } from '../services/tools/tools.service';
import { InfoSimple, InfoSimpleAPI, InfosClient } from 'app/types/clientInfo';
import { SortManager } from 'app/tools/sort-manager';
import { Hebergement } from 'app/types/hebergement';
import { HebergementService } from 'app/services/hebergement.service';
import { DashboardPanel, DashboardTab } from 'app/dashboard/dashboard.component';
import { MessageService } from 'app/services/tools/message.service';
import { FormsModule } from '@angular/forms';
import { ClrInputModule, ClrIconModule, ClrAccordionModule, ClrDatagridModule, ClrConditionalModule, ClrRadioModule, ClrCommonFormsModule, ClrCheckboxModule, ClarityModule } from '@clr/angular';
import { NgFor, NgStyle, NgClass, NgIf, DatePipe } from '@angular/common';
import { YesNoPipe } from '../tools/pipes/yes-no.pipe';
import { TypePipe } from '../tools/pipes/type.pipe';

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.css'],
    standalone: true,
    imports: [FormsModule, ClrInputModule, ClrIconModule, ClrAccordionModule, ClrDatagridModule, ClrConditionalModule, NgFor, ClrRadioModule, ClrCommonFormsModule, NgStyle, NgClass, NgIf, ClrCheckboxModule, DatePipe, YesNoPipe, TypePipe, ClarityModule]
})
export class ClientsComponent implements OnInit, OnDestroy {

  clientList: Client[] = [];
  clientListFiltered: Client[] = [];
  clientListOrigin: Client[] = [];
  clientListSubscription: Subscription = new Subscription();

  userName: string = '';
  searchText = '';

  isOpen = false;
  requestCompleted: boolean = false;

  sortManager!: SortManager;
  filters: Filter[] = [];

  currentClientInfo: InfosClient = new InfosClient();
  infosSimplesDispos: InfoSimple[] = [];
  currentClientHebergement: Hebergement = Hebergement.createEmpty();

  currentClient: Client | null = null;

  constructor(private clientService: ClientService, private router: Router, private toolsService: ToolsService,
    private hebergementService: HebergementService, private messageService: MessageService) {
    this.sortManager = new SortManager('numClient', 'clients', this.toolsService);
  }

  ngOnInit(): void {
    this.requestCompleted = false;
    this.clientListSubscription = this.clientService.clientListSubject.subscribe({
      next: (clientList: Client[]) => {  // connects the licence list below to the one from the service
        this.clientListOrigin = clientList;
        this.clientListFiltered = clientList;
        setTimeout(() => {
          this.setFiltres();
          this.clientList = clientList.map(x => Object.assign({}, x));
          this.filterClients();
          // On fait un map pour passer une version de mcr "28.2406.7.10" à "28.2406.07.10"
          this.clientList = this.clientList.map(n => {
            n.versionDerniereAnnonce = this.formatVersion(n.versionDerniereAnnonce)
            return n
          });
          this.sort(this.sortManager.getcol(), true);
          this.requestCompleted = true;
        }, 0);
      },
      error: () => this.requestCompleted = true
    });
    // dedans : appelle la fct asynchrone pour les infos sur les annonces
    // -> va mettre à jour les clients avec plus d'infos sur la dernière annonce
    this.clientService.getAllClient();
    this.userName = this.toolsService.localGetByKey('userName') ?? '';
    this.clientService.availableInfoTypesSubject.subscribe((infosDispos: InfoSimple[]) => {
      this.infosSimplesDispos = infosDispos;
    });
  }

  private setFiltres(): void {
    const filters = [
      { name: 'avecMaintenance', number: 0, mode: 0 },
      { name: 'estActif', number: 1, mode: 2 },
      { name: 'instanceAutomatique', number: 2, mode: 1 },
      { name: 'avecIncoherencesAnnoncesModules', number: 3, mode: 0 },
      { name: 'synchronisationLicencesEstActive', number: 4, mode: 0 }
    ];
    const seeFilters = this.toolsService.sessionGetByKey('seeFilters') === 'true';
    const txt = this.toolsService.sessionGetByKey('filter');
    if (this.toolsService.filtersInStorage()) {
      // on a filtré d'après saisie -> on la remet direct dans le champ texte
      if (txt && this.searchText === '') { this.searchText = txt; }

      // on remet le menu des filtres selon s'il était ouvert/fermé
      if (seeFilters) { this.isOpen = seeFilters; }

      // on vérifie si on a les bons filtres
      const foundFilters = this.toolsService.findFilters();

      for (const existingFilter of filters) {
        // si on en trouve pas un on remplace tout
        if (!foundFilters.find(x => x.name === existingFilter.name)) {
          this.filters = filters;
          return;
        }
      }
      for (const foundFilter of foundFilters) {
        // si on en trouve un qui existe pas on remplace tout
        if (!filters.find(x => x.name === foundFilter.name)) {
          this.filters = filters;
          return;
        }
      }
      this.filters = this.toolsService.findFilters();
    } else {
      this.isOpen = false;
      this.filters = filters;
    }
  }


  changeColor(){
    console.log("hellow");
  }

  /*======================================== REDIRECTION ==================================*/
  clickOnInstance(event: MouseEvent, clientListIndex: number): void {
    if (clientListIndex < 0 || clientListIndex >= this.clientList.length ||
      this.clientList[clientListIndex].idClient === this.currentClient?.idClient) {
      return;
    }
    this.currentClient = this.clientList[clientListIndex];
    this.toolsService.saveFilters(this.filters, this.isOpen, this.searchText);
    if (event.ctrlKey) { // on ouvre une autre page
      window.open(`${window.location.origin}/dashboard/${this.currentClient.idClient}`, "_blank");
    } else {
      // on cherche les infos client
      this.currentClientInfo.tabInfoSimple = [];
      this.currentClientInfo.tabInfoContact = [];
      this.clientService.getClientInfoData(this.currentClient.idClient).subscribe({
        next: (data: InfosClient) => {
          if (data.tabInfoSimple && data.tabInfoSimple.length > 0) {
            data.tabInfoSimple.forEach((info: InfoSimpleAPI) => {
              const infoType = this.infosSimplesDispos.find(i => i.id === info.id);
              if (infoType && (info.id === 104 || info.id === 105))
                this.currentClientInfo.tabInfoSimple.push(new InfoSimple(info.id, infoType.nom, infoType.type, infoType.noLignes, info.idItem, info.valeur))
            });
            this.currentClientInfo.tabInfoContact = data.tabInfoContact;
          }
          // on cherche les infos d'hébergement
          if (this.currentClient !== null) {
            this.hebergementService.getHebergement(this.currentClient.idClient).subscribe({
              next: (hebergement: Hebergement) => this.currentClientHebergement = hebergement
            });
          }
        },
        error: (error: Error) => {
          console.error(error); // géré ici car pas dans le service
          this.messageService.displayError("Echec lors de la récupération des infos de l'instance");
        }
      });
    }
  }

  openTimesheet(name: string): void {
    window.open("https://" + name, "_blank");
  }

  redirectToDashboard(idClient: number): void {
    this.router.navigate([`/dashboard/${idClient}/${DashboardTab.Client}/${DashboardPanel.None}/0`]);
  }

  newWindowWithClientInfos(idClient: number): void {
    window.open(`/dashboard/${idClient}/${DashboardTab.Client}/${DashboardPanel.ClientInfos}`, '_blank');
  }

  newWindowWithTimesheetEdition(idClient: number, idInstance: number): void {
    window.open(`/instance/${idClient}/${idInstance}`, '_blank');
  }

  newWindowWithHebergement(idClient: number): void {
    window.open(`/dashboard/${idClient}/2/${DashboardPanel.ClientHebergement}`, '_blank');
  }

  closeClient(): void {
    this.currentClient = null;
    this.currentClientInfo.clear();
  }

  addClient(): void {
    this.router.navigate([`/dashboard/0/${DashboardTab.Client}/${DashboardPanel.None}/0`]);
    this.toolsService.saveFilters(this.filters, this.isOpen, this.searchText);
  }

  /*======================================== FETCH DATA ====================================*/
  searchClient(): void {
    this.currentClient = null;
    this.clientList = [];
    this.clientList = this.clientListFiltered.slice();
    if (this.searchText) {
      const textToFind = this.toolsService.removeAccents(this.searchText);
      this.clientList = this.clientListFiltered.filter(client =>
        this.toolsService.removeAccents(client.nom).toLowerCase().includes(textToFind.toLowerCase()) ||
        client.code.toString().includes(textToFind.toLowerCase())
      );
    }
  }

  reloadList(): void {
    this.toolsService.saveFilters(this.filters, this.isOpen, this.searchText);
    this.clientList = [];
    this.requestCompleted = false;
    this.clientService.getAllClient();
  }

  /*======================================== TOOLS ========================================*/
  getLabel(avecMaintenance: boolean): string {
    return avecMaintenance ? 'label-success' : 'label-warning';
  }

  resetSearch(): void {
    this.searchText = '';
    this.searchClient();
    this.toolsService.resetFilter();
  }

  getColor(filterMode: number): string {
    return filterMode === 0 ? 'grey' : 'black';
  }

  updateFilters(filterNumber: number, event: Event | null): void {
    this.currentClient = null;
    if (event === null) { return; }
    const newMode = (event.target as HTMLInputElement).value;
    if (filterNumber === 2 && newMode === '2') {
      // si filtre sur instance automatique on ignore le reste des filtres
      this.filters[0].mode = this.filters[1].mode = 0;
    }
    for (const singleFilter of this.filters) {
      if (singleFilter.number === filterNumber) {
        singleFilter.mode = Number(newMode);
      }
    }
    this.filterClients();
  }

  selectThisFilter(thisFilter: Filter, filterNumber: number): void {
    thisFilter.mode = filterNumber;

    if (filterNumber === 2 && thisFilter.number === 2) {
      // si filtre sur instance automatique on ignore le reste des filtres
      this.filters[0].mode = this.filters[1].mode = 0;
    }

    this.filterClients();
  }

  private filterClients(): void {
    this.clientList = [];
    this.clientList = this.clientListOrigin.slice();
    for (const singleFilter of this.filters) {
      switch (singleFilter.mode) {
        case 1:
          this.clientList = this.clientList.filter(client => client[singleFilter.name as keyof Client] === false);
          break;
        case 2:
          this.clientList = this.clientList.filter(client => client[singleFilter.name as keyof Client] === true);
          break;
      }
    }
    this.clientListFiltered = this.clientList;
    this.searchClient(); // filter on clients in case we are searching and filtering
  }                      // => will look on the filtered list

  sort(nameCol: string, initialSort = false): void {
    this.sortManager.sort(nameCol, this.clientList, (a: Client, b: Client) => {
      if (!a[nameCol as keyof Client] && nameCol !== 'avecMaintenance' && nameCol !== 'synchronisationLicencesEstActive') {
        return 1;
      } else if (!b[nameCol as keyof Client]) {
        return -1;
      }
      return this.sortManager.compare(a[nameCol as keyof Client], b[nameCol as keyof Client]);
    }, initialSort);
  }

  /**
   * Rajoute un zero pour éviter que les chiffres soient tout seuls
   * @param inputString Version MCR "28.2406.7.1"
   * @returns Version MCR "28.2406.07.10"
   */
  private formatVersion(inputString: string): string {
    // Eviter de retourne "00"
    if (inputString === "") { return inputString }

    const chiffres = inputString.split('.');
    const formattedParts = chiffres.map(i => i.padStart(2, '0'));
    return formattedParts.join('.');
  }

  ngOnDestroy(): void { this.clientListSubscription.unsubscribe(); }
}
