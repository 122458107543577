<div class="content-container">
    <nav class="sidenav" style="width: 200px;">
        <section class="sidenav-content">
            <section class="nav-group">
                <button routerLink="/modules" class="btn btn-inline">Back to list</button>
            </section>
        </section>
    </nav>

    <div *ngIf="requestCompleted" class="content-area">
        <form clrForm (ngSubmit)="onSubmit()" #f="ngForm">
            <ng-container *ngIf="module">
                <clr-input-container>
                    <label for="nomModule">Nom du module</label>
                    <input clrInput type="text" id="nomModule" name="nomModule" [(ngModel)]="module.nom" ngModel />
                </clr-input-container>

                <clr-input-container>
                    <label for="codeModule">Code du module</label>
                    <input clrInput type="text" id="codeModule" name="codeModule" [(ngModel)]="module.code" ngModel
                        required />
                </clr-input-container>

                <clr-input-container>
                    <label for="verifierLicence">Verifier Licences</label>
                    <input clrInput type="checkbox" id="verifierLicence" name="verifierLicence"
                        [(ngModel)]="module.verifierLicence" clrCheckbox />
                </clr-input-container>

                <br />
            </ng-container>
            <div class="navigation">
                <button type="submit" class="btn btn-success-inline" *ngIf="module"> Valider </button>
                <button id="mobileButton" routerLink="/modules" class="btn btn-inline">Back to list</button>
            </div>
        </form>
        <div *ngIf="invalidForm" class="alert alert-warning" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                    </div>
                    <span class="alert-text">Veuillez entrer au moins un code de module</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!requestCompleted" id="loading" class="loading" style="visibility:inherit">
        Loading&#8230;
    </div>
</div>
