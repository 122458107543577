<div class="content-container">
    <div class="content-area">
        <div class="title-area">
            <h4>Liste des options</h4>
            <button (click)="addOption()" class="btn btn-success-outline">Ajouter</button>
        </div>
        <div class="table-content">
            <div class="table-container">
            <!-- <div class="clr-col-12 clr-col-lg-6 clr-col-xl-12"> -->
                <button id="mobileButton" (click)="addOption()" class="btn btn-success-outline">Ajouter</button>
                <table class="table table-compact table-noborder">
                    <thead>
                        <th class="hidden">ID
                            <cds-icon (click)="sort('id')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('id'), 'not-sorted': !(sortManager.sameCol('id'))}">
                            </cds-icon>
                        </th>
                        <th class="noMobile" style="min-width:330px">Description
                            <cds-icon (click)="sort('description')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('description'), 'not-sorted': !(sortManager.sameCol('description'))}">
                            </cds-icon>
                        </th>
                        <th class="noMobile" style="width:5%">Type
                            <cds-icon (click)="sort('type')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('type'), 'not-sorted': !(sortManager.sameCol('type'))}">
                            </cds-icon>
                        </th>
                        <th id="name" style="width:20%">Nom
                            <cds-icon (click)="sort('nom')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('nom'), 'not-sorted': !(sortManager.sameCol('nom'))}">
                            </cds-icon>
                        </th>
                        <th style="width:10%">Section
                            <cds-icon (click)="sort('section')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('section'), 'not-sorted': !(sortManager.sameCol('section'))}">
                            </cds-icon>
                        </th>
                        <th style="min-width:83px">Mode
                            <cds-icon (click)="sort('mode')" class="sorted" shape="arrow"
                                [ngClass]="{'desc': sortManager.isDesc() && sortManager.sameCol('mode'), 'not-sorted': !(sortManager.sameCol('mode'))}">
                            </cds-icon>
                        </th>
                        <th style="width:10%">
                        </th>
                    </thead>
                    <tbody  >
                        <ng-container *ngFor="let option of optionsList">
                            <tr (click)="select(option)"  class="trbody"
                                [ngClass]="selectedOption == option ? 'active-line' : 'inactive-line'">
                                <td class="hidden">{{option.idOption}}</td>
                                <td id="description" class="noMobile">{{option.description |typePipe: 'textarea'}}</td>
                                <td class="noMobile">{{option.type |typePipe: 'typeOption'}}</td>
                                <td>{{option.nom}}</td>
                                <td>{{option.section}}</td>
                                <td>
                                    {{option.mode |typePipe: 'modeOption'}}
                                </td>
                                <td>
                                    <cds-icon class="edit-action" shape="pencil" (click)="editOption(option)"></cds-icon>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <h6 class="no-data" *ngIf="optionsList.length == 0">Aucune donnée disponible</h6>
            </div>
        </div>
    </div>
</div>

