<div class="login-wrapper">
    <form class="login">
        <section class="title">
            <h3 class="welcome">Bienvenue dans</h3> la gestion des licences
        </section>
        <br/>
        <div id="buttonDiv"></div>
        <div *ngIf="requestFailed" class="alert alert-warning" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <span class="alert-text">L'authentification a échoué. Veuillez recharger la page et réessayer.</span>
                </div>
            </div>
        </div>
        <div *ngIf="!requestCompleted" id="loading" class="loading" style="visibility:inherit">
            Loading&#8230;     
        </div>
    </form>
</div>