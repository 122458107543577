import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { Instance } from '../types/instance';
import { tap } from 'rxjs/operators';
import { LicenceHistorique } from 'app/types/licence_historique';
import { ExcelExportService } from './tools/excel_export.service';
import { TypePipe } from 'app/tools/pipes/type.pipe';
import { YesNoPipe } from 'app/tools/pipes/yes-no.pipe';
import { DateService } from './tools/date.service';
import { MessageService } from './tools/message.service';
@Injectable()

export class InstanceService {

    private instanceList: Instance[] = [];
    instanceListSubject = new Subject<Instance[]>();

    private instance: Instance = new Instance();

    private readonly postHeaders:HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    constructor(private httpClient: HttpClient, private excelExportService: ExcelExportService, 
        private dateService: DateService, private messageService: MessageService) { }

    private emitInstanceListSubject(): void {
        this.instanceListSubject.next(this.instanceList.slice());
    }

    getAllInstances(idClient: number, reloadAnyway: boolean = false): void {
        if (this.instanceList.length > 0 && this.instanceList[0].idClient === idClient && !reloadAnyway) {
            this.emitInstanceListSubject();
        } else {
            this.getAllInstancesReq(idClient).subscribe({
                next: (response:Instance[]) => {
                    this.instanceList = response;
                    this.emitInstanceListSubject();
                },
                error: (error: Error) => {
                    console.error(error)
                    this.messageService.displayError(`Impossible de récupérer les instances associées à ce client`);
                    this.instanceList = [];
                    this.emitInstanceListSubject();
                }
            });
        }
    }

    getAllInstancesReq(idClient: number): Observable<Instance[]> {
        return this.httpClient.get<Instance[]>(`instance/client/${idClient}`);
    }

    getInstanceById(idInstance: number, reloadAnyway = false): Observable<Instance> {
        if (this.instance && this.instance.idInstance === idInstance && !reloadAnyway) {
            return of(this.instance);
        } else {
            const path = `instance/id/${idInstance}`;
            return this.httpClient.get<Instance>(path);
        }
    }

    public getSingleInstanceWithoutSubject(refInstall: string): Observable<Instance> {
        const path = `instance/ref/${refInstall}`;
        return this.httpClient.get<Instance>(path);
    }

    postInstance(newInstance: Instance): Observable<number> {
        const instance_API = { ...newInstance, 
            ...{dateFinMaintenance: this.dateService.dateForAPI(newInstance.dateFinMaintenance)}
        };  // issues with headers
        return this.httpClient.post<number>('instance', JSON.stringify(instance_API), {headers: this.postHeaders});
    }

    deleteInstance(idInstance: number): Observable<boolean> {
        const path = `instance/${idInstance}`;
        return this.httpClient.delete<boolean>(path, {headers: this.postHeaders}).pipe(
            tap({
                next: (response: boolean) => {
                    if (response) {
                        // ligne supprimé, on l'enlève du tableau et on émet
                        this.instanceList = this.instanceList.filter((instance: Instance) => instance.idInstance !== idInstance);
                        this.emitInstanceListSubject();
                    } else {
                        this.messageService.displayError(`Impossible de supprimer cette instance`);
                    }
                },
                error: (error) => {
                    console.error(error);
                    this.messageService.displayError(`Impossible de supprimer cette instance`);
                }
            })
        );
    }

    exportExcel(instance: Instance, historiqueList: LicenceHistorique[]): void {
        this.excelExportService.setExcelDataLoadingStatus(true);
        const typePipe  = new TypePipe();
        const yesNoPipe = new YesNoPipe();
        const completeContent = []
        let propertiesNumber = 0;
        const clientContent: { [key: string]: any } = {
            idClient: instance.idClient,
            nomInstance: instance.nom,
            référenceInstallation: instance.referenceInstallation,
            maintenance: yesNoPipe.transform(instance.avecMaintenance),
            dateFinMaintenance: new Date(instance.dateFinMaintenance).getFullYear() < 2000 ? '' : instance.dateFinMaintenance,
            actif: yesNoPipe.transform(instance.estActif),
            remarque: instance.remarque,
        };
        for (const option of instance.options) {
            clientContent[`option - ${option.nomOption}`] = option.type === 1 ? yesNoPipe.transform(option.valeur) : option.valeur
        }
        for (const historique of historiqueList) {
            const historiqueContent: { [key: string]: any } = {
                dateValidite: new Date(historique.dateValidite).getFullYear() < 2000 ? '' : historique.dateValidite,
                dateFacture: new Date(historique.dateFacture).getFullYear() < 2000 ? '' : historique.dateFacture,
                dateSaisie: new Date(historique.dateHeureSaisie).getFullYear() < 2000 ? '' : historique.dateHeureSaisie,
                utilisateur: historique.initialesUser,
                type: typePipe.transform(historique.type, ''),
                remarqueHistorique: historique.remarque,
            };
            const line = Object.assign({}, clientContent, historiqueContent);
            for (const module of historique.modulesInfo) {
                line[module.nom] = module.flottant ? `${module.nombre} (f)` : module.nombre
            }
            for (const option of historique.options) {
                line[`option - ${option.nomOption}`] = option.type === 1 ? yesNoPipe.transform(option.valeur) : option.valeur
            }
            propertiesNumber = Object.keys(line).length;
            completeContent.push(line);
        }
        this.excelExportService.createFile(`Client - ${instance.nom}`,
            [{ sheetName: 'Client_information', data: completeContent, columns: this.excelExportService.setupColumns(propertiesNumber) }]
        );
    }

    getInstance(refInstall: string): Observable<Instance> {
        return this.httpClient.get<Instance>(`instance/ref/${refInstall}`);
    }
}
