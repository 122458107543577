export interface Module {
    idModule: number;
    code: string;
    nom: string;
    verifierLicence: boolean;
}

export function emptyModule(): Module {
    return {idModule: 0, code: '', nom: '', verifierLicence: false};
}

export class Module {
    idModule: number = 0;
    code: string = '';
    nom: string = '';
    verifierLicence: boolean = false;

    /* fonction qui récupère en copie les infos d'un module */
    static copy(m: Module): Module {

        const moduleCopy = new Module();
        moduleCopy.idModule = m.idModule;
        moduleCopy.code = m.code;
        moduleCopy.nom = m.nom;
        moduleCopy.verifierLicence = m.verifierLicence;

        return moduleCopy;
    }
}