<div id="main" *ngIf="client">
  <form class="row">
    <clr-input-container>
      <label for="adresse">Code client</label>
      <input clrInput type="text" id="code" [(ngModel)]="client.code" name="code"
        [readonly]="client.idClient > 0 || client.idClient < 0" (input)="boolModifie = true" />
    </clr-input-container>
    <clr-input-container>
      <label for="adresse">Nom client</label>
      <input clrInput type="text" id="nom" [(ngModel)]="client.nom" name="nom" [readonly]="client.idClient < 0"
        (input)="boolModifie = true" class="big-clr-input" />
    </clr-input-container>
    <clr-input-container>
      <label for="adresse">Remarque</label>
      <input clrInput type="text" id="remarque" [(ngModel)]="client.remarque" name="remarque"
        [readonly]="client.idClient < 0" (input)="boolModifie = true" class="big-clr-input" />
    </clr-input-container>
    <div>
      <button *ngIf="boolModifie" type="button" class="btn btn-icon btn-primary btn-sm"
        (click)="updateClient();boolModifie=false;" [disabled]="client.idClient < 0">
        <span *ngIf="client.idClient > 0 ;else ajouter">Enregistrer</span><ng-template #ajouter>Créer</ng-template>
      </button>
      <button *ngIf="client.idClient <= 0" (click)="backToClients()" class="btn btn-inline btn-sm">Retour</button>
    </div>
  </form>
  <clr-tabs>
    <clr-tab>
      <button clrTabLink class="tab-btn" (click)="switchTab(Tab.Client)">Client</button>
      <ng-template [clrIfActive]="currentTab == Tab.Client">
        <clr-tab-content *clrIfActive>
          <clr-accordion>
            <!-- ========================= infos générales =================================-->
            <clr-accordion-panel [clrAccordionPanelOpen]="currentPanel == Panel.ClientContact">
              <clr-accordion-title>
                Informations de contact
                <span class="badge badge-info" *ngIf="infoContactEnEdition != null">!</span>
              </clr-accordion-title>
              <clr-accordion-content *ngIf="client">
                <div id="main-form" *ngIf="infoContactEnEdition != null; else tableauContacts">

                  <clr-input-container>
                    <label for="descriptionContact">Description / Rôle</label>
                    <input clrInput type="text" [(ngModel)]="infoContactEnEdition.description" name="descriptionContact"
                      id="descriptionContact">
                  </clr-input-container>

                  <clr-input-container>
                    <label for="contactName">Nom</label>
                    <input clrInput type="text" [(ngModel)]="infoContactEnEdition.nom" name="nomContact"
                      id="nomContact">
                  </clr-input-container>

                  <clr-input-container>
                    <label for="telContact">Téléphone</label>
                    <input clrInput type="text" [(ngModel)]="infoContactEnEdition.tel" name="telContact"
                      id="telContact" />
                  </clr-input-container>

                  <clr-input-container>
                    <label for="mailContact">Email</label>
                    <input clrInput type="text" [(ngModel)]="infoContactEnEdition.mail" name="mailContact"
                      id="mailContact" />
                  </clr-input-container>

                  <!--
                  <clr-input-container>
                    <label for="remarqueContact">Remarque</label>
                    <input clrInput type="text" [(ngModel)]="infoContactEnEdition.remarque" name="remarqueContact"
                      id="remarqueContact">
                  </clr-input-container>
                  -->

                  <clr-textarea-container>
                    <label>Remarque</label>
                    <textarea clrTextarea [(ngModel)]="infoContactEnEdition.remarque" name="remarqueContact"></textarea>
                  </clr-textarea-container>

                  <div id="main-buttons">
                    <button type="button" class="btn btn-danger" (click)="cancelContact()">Annuler</button>
                    <button type="button" class="btn btn-primary" (click)="saveContact()">
                      <span *ngIf="infoContactEnEdition.id >= 0;else ajouter">Modifier</span><ng-template
                        #ajouter>Créer</ng-template>
                    </button>
                  </div>
                </div>
                <ng-template #tableauContacts>
                  <table id="contactTable" class="table table-compact table-noborder"
                    *ngIf="clientInfos && clientInfos.tabInfoContact">
                    <thead>
                      <th width="13%">Nom</th>
                      <th width="25%">Description</th>
                      <th width="13%">Téléphone</th>
                      <th width="13%">Email</th>
                      <th width="25%">Remarque</th>
                      <th width="5%"></th>
                      <th width="5%"></th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let contact of clientInfos.tabInfoContact; index as i">
                        <td>{{contact.nom}}</td>
                        <td>{{contact.description}}</td>
                        <td>{{contact.tel}}</td>
                        <td>{{contact.mail}}</td>
                        <td>{{contact.remarque}}</td>
                        <td class="hand" (click)="editContact(i)"><cds-icon shape="pencil"></cds-icon></td>
                        <td class="hand" (click)="deleteContact(i)"><cds-icon shape="trash"></cds-icon></td>
                      </tr>
                    </tbody>
                  </table>
                  <button type="button" class="btn btn-primary" [disabled]="this.client.idClient <= 0"
                    (click)="startEditionContact()">
                    Ajouter un contact <cds-icon shape="user"></cds-icon>
                  </button>
                </ng-template>
              </clr-accordion-content>
            </clr-accordion-panel>
            <!-- ========================= infos d'hébergement =================================-->
            <clr-accordion-panel [clrAccordionPanelOpen]="currentPanel == Panel.ClientHebergement">
              <clr-accordion-title>Informations d'hébergement</clr-accordion-title>
              <clr-accordion-content *ngIf="client">
                <app-hebergements-tab [idClient]="client.idClient"></app-hebergements-tab>
              </clr-accordion-content>
            </clr-accordion-panel>
            <!-- ========================= infos supplémentaires simples =================================-->
            <clr-accordion-panel [clrAccordionPanelOpen]="currentPanel == Panel.ClientInfos">
              <clr-accordion-title>
                Informations supplémentaires
                <span class="badge badge-info" *ngIf="infoSimpleEnEdition != null">!</span>
              </clr-accordion-title>
              <clr-accordion-content *ngIf="client">
                <div *ngIf="infoSimpleEnEdition != null; else tableauInfos">
                  <form clrForm class="form-card info-card col" *ngIf="infoSimpleEnEdition != null">
                    <div class="row form-row">
                      <p>{{infoSimpleEnEdition.nom}}</p>
                      <ng-container [ngSwitch]="infoSimpleEnEdition.type">
                        <clr-textarea-container *ngSwitchCase="InputType.STRING">
                          <textarea rows="{{infoSimpleEnEdition.noLignes}}" clrTextarea
                            [(ngModel)]="infoSimpleEnEdition.valeur" name="valeurInfo" id="valeurInfo"></textarea>
                        </clr-textarea-container>
                        <clr-input-container *ngSwitchCase="InputType.BOOLEAN">
                          <input clrInput clrCheckbox type="checkbox" [(ngModel)]="infoSimpleEnEdition.valeur"
                            name="valeurInfo" id="valeurInfo" />
                        </clr-input-container>
                        <clr-input-container *ngSwitchCase="InputType.NUMBER">
                          <input class="num-input" clrInput type="number" [(ngModel)]="infoSimpleEnEdition.valeur"
                            name="valeurInfo" id="valeurInfo" />
                        </clr-input-container>
                      </ng-container>
                    </div>
                  </form>
                  <button type="button" class="btn btn-primary" (click)="saveInfo()">
                    <span *ngIf="infoSimpleEnEdition.idItem >= 0;else ajouter">Modifier</span><ng-template
                      #ajouter>Créer</ng-template>
                  </button>
                  <button type="button" class="btn btn-danger" (click)="cancelInfo()">Annuler</button>
                </div>

                <ng-template #tableauInfos>
                  <table id="infoTable" class="table table-compact table-noborder"
                    *ngIf="clientInfos && clientInfos.tabInfoSimple">
                    <thead>
                      <th width="45%">Nom</th>
                      <th width="45%">Valeur</th>
                      <th width="5%"></th>
                      <th width="5%"></th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let info of clientInfos.tabInfoSimple; index as i">
                        <td>{{info.nom}}</td>
                        <td>
                          <ng-container [ngSwitch]="info.type">
                            <span *ngSwitchCase="InputType.STRING">{{info.valeur}}</span>
                            <span *ngSwitchCase="InputType.BOOLEAN">{{info.valeur == "true" ? "Oui": "Non"}}</span>
                            <span *ngSwitchCase="InputType.NUMBER">{{info.valeur}}</span>
                          </ng-container>
                        </td>
                        <td class="hand" (click)="editInfo(i)"><cds-icon shape="pencil"></cds-icon></td>
                        <td class="hand" (click)="deleteInfo(i)"><cds-icon shape="trash"></cds-icon></td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <clr-dropdown>
                      <button class="btn btn-primary" [disabled]="this.client.idClient <= 0" clrDropdownTrigger>
                        Ajouter une information
                        <cds-icon shape="angle" direction="down"></cds-icon>
                      </button>
                      <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
                        <ng-container *ngFor="let type of infosSimplesDispo">
                          <div (click)="startEditionInfo(type)" clrDropdownItem>{{type.nom}}</div>
                        </ng-container>
                      </clr-dropdown-menu>
                    </clr-dropdown>
                  </div>
                </ng-template>
              </clr-accordion-content>
            </clr-accordion-panel>
          </clr-accordion>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
    <clr-tab *ngIf="client && client.idClient > 0">
      <button clrTabLink class="tab-btn">Instances</button>
      <ng-template [clrIfActive]="currentTab == Tab.Instance" (click)="switchTab(Tab.Instance)">
        <clr-tab-content *clrIfActive>
          <div>
            <app-historique (getToClientTab)="switchTab(Tab.Client)" [idClient]="client.idClient"
              [nomClient]="client.nom" [codeClient]="client.code" [instanceID]="idInstance"></app-historique>
          </div>
        </clr-tab-content>
      </ng-template>
    </clr-tab>
  </clr-tabs>
</div>