import { enableProdMode, LOCALE_ID, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { httpInterceptorProvider } from './app/tools/http-interceptors';
import { RoutingConfigService } from './app/services/routingConfigService';
import { InstanceService } from './app/services/instance.service';
import { ModuleService } from './app/services/module.service';
import { ClientService } from './app/services/client.service';
import { HistoriqueService } from './app/services/historique.service';
import { ToolsService } from './app/services/tools/tools.service';
import { AuthService } from './app/services/auth.service';
import { AuthGuard } from './app/services/auth-guard.service';
import { Routes, provideRouter } from '@angular/router';
import { LoginComponent } from './app/login/login.component';
import { ClientsComponent } from './app/clients/clients.component';
import { InstallationsComponent } from './app/installations/installations.component';
import { ModulesComponent } from './app/modules/modules.component';
import { SingleModuleComponent } from './app/single-module/single-module.component';
import { OptionsComponent } from './app/options/options.component';
import { SingleOptionComponent } from './app/single-option/single-option.component';
import { DashboardComponent } from './app/dashboard/dashboard.component';
import { AnnoncesComponent } from './app/annonces/annonces.component';
import { SingleHistoriqueComponent } from './app/single-historique/single-historique.component';
import { SingleInstanceComponent } from './app/single-instance/single-instance.component';
import { FourOhFourComponent } from './app/four-oh-four/four-oh-four.component';
import { OptionService } from './app/services/option.service';
import { MessageService } from './app/services/tools/message.service';
import { AnnonceService } from './app/services/annonce.service';
import { ExcelExportService } from './app/services/tools/excel_export.service';
import { TimesheetService } from './app/services/timesheet.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ClarityModule } from '@clr/angular';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app/app.component';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'clients', canActivate: [AuthGuard], component: ClientsComponent },
  { path: 'installations', canActivate: [AuthGuard], component: InstallationsComponent },
  { path: 'modules', canActivate: [AuthGuard], component: ModulesComponent },
  { path: 'moduleAdd', canActivate: [AuthGuard], component: SingleModuleComponent },
  { path: 'modules/:id', canActivate: [AuthGuard], component: SingleModuleComponent },
  { path: 'options', canActivate: [AuthGuard], component: OptionsComponent },
  { path: 'options/:id', canActivate: [AuthGuard], component: SingleOptionComponent },
  { path: 'optionAdd', canActivate: [AuthGuard], component: SingleOptionComponent },

  { path: 'dashboard/:idClient', canActivate: [AuthGuard], component: DashboardComponent},
  { path: 'dashboard/:idClient/:tab/:panel', canActivate: [AuthGuard], component: DashboardComponent},
  { path: 'dashboard/:idClient/:tab/:panel/:idInstance', canActivate: [AuthGuard], component: DashboardComponent},

  { path: 'annonces/:idClient/:refInstall', canActivate: [AuthGuard], component: AnnoncesComponent },

  { path: 'editHistorique/:idInstance/:refInstall/:idHistorique/:idClient', canActivate: [AuthGuard], component: SingleHistoriqueComponent },

  { path: 'instance/:idClient/:idInstance', canActivate: [AuthGuard], component: SingleInstanceComponent },

  { path: 'not-found', component: FourOhFourComponent },
  { path: '**', redirectTo: 'not-found' },
];



if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(CommonModule, BrowserModule, ClarityModule, FormsModule),
        { provide: LOCALE_ID, useValue: 'fr' },
        httpInterceptorProvider,
        RoutingConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: (config: RoutingConfigService) => (): Promise<boolean> => config.load(),
            deps: [RoutingConfigService],
            multi: true
        },
        InstanceService,
        ModuleService,
        ClientService,
        HistoriqueService,
        ToolsService,
        AuthService,
        AuthGuard,
        OptionService,
        MessageService,
        AnnonceService,
        ExcelExportService,
        TimesheetService,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideRouter(appRoutes)
    ]
})
  .then()
  .catch(err => console.error(err));

