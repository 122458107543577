<div class="content-container">
    <nav class="sidenav">
        <section class="sidenav-content">
            <section class="nav-group">
                <div>
                    <button (click)="backToList()" class="btn btn-inline">Retour</button>
                </div>
            </section>
        </section>
    </nav>
    <clr-tabs id="tabs">
        <!-- table des annonces avec détails -->
        <clr-tab>
            <button clrTabLink class="btn-tab">Annonces</button>
            <clr-tab-content *clrIfActive>
                <div class="row">
                    <h4 class="bold">Annonces reçues depuis MCR-suite</h4>
                    <a (click)="exportexcel()" *ngIf="requestCompleted" class="btn btn-inline">Exporter vers Excel</a>
                </div>
                <div class="row announce-start-date">
                    <h5>{{instance.referenceInstallation}}
                        <span *ngIf="instance.nom != ''"> - {{instance.nom}}</span></h5>
                    <div class="row announce-start-date">
                        <clr-date-container>
                            <input type="date" clrDate id="customStartDate" name="customStartDate" [(ngModel)]="customStartDate"/>
                        </clr-date-container>
                        <a (click)="filterByStartDate()" *ngIf="requestCompleted" class="btn btn-inline">Aller à</a>
                    </div>
                </div>
                <clr-datagrid>
                    <clr-dg-column class="clr-col-sm-2">Date/Heure <cds-icon shape="arrow" (click)="sort('dateHeure')"></cds-icon></clr-dg-column>
                    <clr-dg-column class="clr-col-sm-3">Utilisateur</clr-dg-column>
                    <clr-dg-column class="clr-col-sm-3">Machine</clr-dg-column>
                    <clr-dg-column class="clr-col-sm-2">mcr-suite <cds-icon shape="arrow" (click)="sort('version')"></cds-icon></clr-dg-column>
                    <clr-dg-column class="clr-col-sm-1">OS <cds-icon shape="arrow" (click)="sort('versionOs')"></cds-icon></clr-dg-column>
                    <ng-container  *ngIf="requestCompleted && annonces.length > 0">
                        <clr-dg-row *clrDgItems="let annonce of annonces" [clrDgItem]="annonce">
                            <clr-dg-cell class="clr-col-sm-2">{{annonce.dateHeure | date:'dd/MM/yyyy H:mm'}}</clr-dg-cell>
                            <clr-dg-cell class="clr-col-sm-3">{{annonce.prenom}} {{annonce.nom}} ({{annonce.login}})</clr-dg-cell>
                            <clr-dg-cell class="clr-col-sm-3">{{annonce.nomMachine}}</clr-dg-cell>
                            <clr-dg-cell class="clr-col-sm-2">{{annonce.version}}</clr-dg-cell>
                            <clr-dg-cell class="clr-col-sm-1">{{annonce.versionOS}}</clr-dg-cell>
                            <clr-dg-row-detail *clrIfExpanded [innerHTML]="annonce.informations | keepHtml"></clr-dg-row-detail>
                        </clr-dg-row>
                    </ng-container>
                    <clr-dg-row *ngIf="!requestCompleted">
                        <clr-dg-cell class="clr-col-auto">Chargement...</clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-row *ngIf="requestCompleted && annonces.length == 0">
                        <clr-dg-cell class="clr-col-auto">Aucune donnée disponible</clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                        <ng-container *ngIf="requestCompleted">
                            <cds-icon *ngIf="pagination.startRes" class="hand nav-icon" (click)="loadOnePage()" shape="undo"></cds-icon>
                            <cds-icon *ngIf="pagination.endRes + 1 < pagination.total" (click)="loadOnePage(true)" class="hand nav-icon" shape="redo"></cds-icon>
                            {{pagination.startRes + 1}} -
                            <span *ngIf="pagination.endRes > pagination.total">{{pagination.total}}</span> <span *ngIf="pagination.endRes <= pagination.total">{{pagination.endRes + 1}}</span>
                            of {{ pagination.total }} annonces
                        </ng-container>
                        <ng-container *ngIf="!requestCompleted">
                            Calcul du total en cours...
                        </ng-container>
                    </clr-dg-footer>
                </clr-datagrid>
            </clr-tab-content>
        </clr-tab>
        <clr-tab>
            <!-- table des associations utilisateurs -/ postes -->
            <button clrTabLink class="btn-tab">Utilisateurs</button>
            <clr-tab-content *clrIfActive>
                <h4 class="bold">Duos utilisateur-poste annoncés</h4>
                <div class="row user-filter">
                    <clr-date-container>
                        <label for="userSearchStart">Depuis</label>
                        <input type="date" clrDate id="userSearchStart" name="userSearchStart"
                        [(ngModel)]="userData.start"/>
                    </clr-date-container>
                    <clr-date-container>
                        <label for="userSearchEnd">Jusqu'au</label>
                        <input type="date" clrDate id="userSearchEnd" name="userSearchEnd"
                        [(ngModel)]="userData.end"/>
                    </clr-date-container>
                    <div>
                        <button (click)="filterUsers()" class="btn btn-inline">Filtrer</button>
                    </div>
                </div>
                <clr-datagrid>
                    <clr-dg-column class="clr-col-7">Utilisateur</clr-dg-column>
                    <clr-dg-column class="clr-col-3">Machine</clr-dg-column>
                    <ng-container  *ngIf="userRequestCompleted && userData.filtered_users.size > 0">
                        <clr-dg-row *clrDgItems="let user of userData.filtered_users | keyvalue" [clrDgItem]="user">
                            <clr-dg-cell class="clr-col-7">{{ user.key }}</clr-dg-cell>
                            <clr-dg-cell class="clr-col-3">{{ user.value }}</clr-dg-cell>
                        </clr-dg-row>
                    </ng-container>
                    <clr-dg-row *ngIf="!userRequestCompleted">
                        <clr-dg-cell class="clr-col-7">Chargement...</clr-dg-cell>
                        <clr-dg-cell class="clr-col-3"></clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-row *ngIf="userRequestCompleted && userData.filtered_users.size == 0">
                        <clr-dg-cell class="clr-col-7">Aucune donnée disponible</clr-dg-cell>
                        <clr-dg-cell class="clr-col-3"></clr-dg-cell>
                    </clr-dg-row>
                </clr-datagrid>
            </clr-tab-content>
        </clr-tab>
    </clr-tabs>
</div>
