import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Site, SiteAPI, SiteInfosAPI } from '../types/Site.interface';
import { Observable, Subject, catchError, forkJoin, map } from 'rxjs';
import { MessageService } from './tools/message.service';
@Injectable({ providedIn: 'root' })

export class TimesheetService {

    constructor(private httpClient: HttpClient, private messageService: MessageService) { }

    populatedSubject = new Subject<Site>();

    getAllWebsites(): void {
        this.httpClient.get<SiteAPI[]>("site").subscribe({
            next: (sitesAPI: SiteAPI[]) => {
                // on récupère nom + idInstance
                const tableData: Site[] = sitesAPI.map(
                    (siteAPI: SiteAPI) => Site.fromSiteAPI(siteAPI)
                );
                // pour chaque site on va récupérer plus d'infos
                tableData.forEach((simpleSite) => {

                    const mcrsuiteObs = this.httpClient.get<string>(`annonce/versionMcr/${simpleSite.idInstance}`);
                    const infosObs = this.httpClient.get<SiteInfosAPI>(`site/infos/${simpleSite.url}/${simpleSite.idInstance}`);
                    // on attend que les 2 requêtes soient terminées et on récupère tous les résultats
                    forkJoin([mcrsuiteObs, infosObs]).subscribe({
                        next: (results => {
                            simpleSite.mcrsuite = results[0];
                            const infos = results[1];
                            simpleSite.completeFromInfoAPI(infos);
                            simpleSite.buildVersionWebdev();
                            this.emitpopulated(simpleSite);
                        })
                    });
                });
            },
            error: (error: Error) => {
                console.error(error);
                this.messageService.displayError('Impossible de récupérer la liste des timesheet web.');
            }
        });
    }

    getWebsiteByURL(url: string, idInstance: number): Observable<Site> {
        return this.httpClient.get<SiteInfosAPI>(`site/infos/${url}/${idInstance}`).pipe(
            map((infoAPI: SiteInfosAPI) => {
                const site = new Site(url, idInstance);
                site.completeFromInfoAPI(infoAPI);
                return site;
            }),
            catchError((error: Error) => {
                console.error(error);
                this.messageService.displayError(`Impossible de récupérer les infos du site ${url}`);
                throw error;
            })
        );
    }

    private emitpopulated(site: Site): void {
        this.populatedSubject.next(site);
    }
}