import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';

import { Instance } from '../types/instance';
import { LicenceHistorique } from '../types/licence_historique';
import { LicenceHistoriqueDetail } from '../types/licence.historique.detail';
import { OptionInstance } from '../types/option_instance';
import { Module } from '../types/module';
import { Option } from '../types/option';
import { FullHistorique } from '../types/full_historique';
import { TotalTable} from '../types/total';

import { ModuleService } from '../services/module.service';
import { OptionService } from '../services/option.service';
import { tap } from 'rxjs/operators';
import { DateService } from './tools/date.service';
import { MessageService } from './tools/message.service';

interface Licence {
    nombre: number;
    flottant: boolean;
}
@Injectable()

export class HistoriqueService implements OnDestroy {

    historiquePath = 'historique';

    private historiqueList: LicenceHistorique[] = [];
    historiqueListSubject = new Subject<LicenceHistorique[]>();

    private modulesList: Module[] = [];                  // allow table building with every module
    private moduleListSubscription: Subscription = new Subscription();

    private optionsList: Option[] = [];
    private optionListSubscription: Subscription = new Subscription();

    private fullHistorique = new FullHistorique();
    fullHistoriqueSubject = new Subject<FullHistorique>();

    private totals: TotalTable[] = [];
    totalsSubject = new Subject<TotalTable[]>();

    private readonly postHeaders:HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    constructor(private httpClient: HttpClient, private moduleService: ModuleService, private optionService: OptionService,
        private dateService: DateService, private messageService: MessageService) {}

    /*======================================== EMIT ================================================*/

    private emitHistoriqueListSubject(): void {
        this.historiqueListSubject.next(this.historiqueList.slice());
    }

    private emitFullHistoriqueSubject(): void {
        this.fullHistoriqueSubject.next(this.fullHistorique);
    }

    private emitTotals(): void {
        this.totalsSubject.next(this.totals);
    }

    /*======================================== EXTERN FETCH ========================================*/

    private fetchModules(): void {
        this.moduleListSubscription = this.moduleService.modulesListSubject.subscribe({
            next:(modulesList: Module[]) => {
              this.modulesList = modulesList;
            }
        });
        this.moduleService.getAllModules();
    }

    private fetchOptions(): void {
        this.optionListSubscription = this.optionService.optionsListSubject.subscribe({
            next: (optionsList: Option[]) => {
              this.optionsList = optionsList;
            }
        });
        this.optionService.getAll(1, false);
    }

    /*======================================== MAIN LOADING METHODS ================================*/

    getAllLicencesByInstance(idInstance: number, refInstall: string, reoladAnyway: boolean = false): void {
        if (this.modulesList.length <= 0) {
            this.fetchModules();
        }
        if (this.optionsList.length <= 0) {
            this.fetchOptions();
        }
        if (this.historiqueList && this.historiqueList.length > 0 && !reoladAnyway) {
            if (this.historiqueList[0].idInstance === idInstance) {
                this.emitHistoriqueListSubject();
            } else
            {
                this.loadHistorique(refInstall);
            }
        } else {
            this.loadHistorique(refInstall);
        }
    }

    /*======================================== ACTIONS - GET ========================================*/
 
    private loadHistorique(refInstall: string): void {
        type Response = {
            item1: LicenceHistorique[];
            item2: TotalTable[];
            item3: string;
        };
        const path = `${this.historiquePath}/${refInstall}?aDevIsDriving=true`;
        this.httpClient.get <Response>(path).subscribe({
                next:(response) => {
                    const tuple = response as Response;
                    this.historiqueList = tuple.item1;
                    this.buildTable();
                    this.emitHistoriqueListSubject();
                    // check les modules annoncés si les nombres correspondent à ceux des totaux
                    // si moins de modules annoncés => orange (moins grave) / si plus annoncés => rouge
                    for(const total of tuple.item2) {
                        if(total.verifierLicence) {
                            if(total.problem > 0) {
                                total.color = 'red';
                            } else {
                                total.color = '#2c3e50';
                            }
                        } else {
                            total.color = '#2c3e50';
                        }
                    }
                    this.totals = tuple.item2;
                    this.emitTotals();
                },
                error: (error) => {
                    console.error(error);
                    this.messageService.displayError("Impossible de récupérer les historiques de cette instance");
                    this.historiqueList = [];
                    this.totals         = [];
                    this.emitHistoriqueListSubject();
                    this.emitTotals();
                }
            });
    }

    getFullHistorique(idInstance: number, idHistorique: number): void {
        type Tuple = {
            item1: Instance; item2: LicenceHistorique;
            item3: LicenceHistoriqueDetail[]; item4: OptionInstance[];
        };
        const path = `${this.historiquePath}/${idInstance}/${idHistorique}`;
        this.httpClient.get <Tuple>(path).subscribe({
            next: (response) => {
                const tuple = response as Tuple;
                this.fullHistorique.instance   = tuple.item1;
                this.fullHistorique.historique = tuple.item2;
                this.fullHistorique.details    = tuple.item3;
                this.fullHistorique.options    = tuple.item4;
                this.emitFullHistoriqueSubject();
            },
            error: (error) => {
                console.error(error);
                this.messageService.displayError("Impossible de récupérer les détails d'historique de cette instance");
            }
        });
    }

    /*======================================== ACTIONS - POST ========================================*/

    postHistorique(historique: LicenceHistorique, detailsHistorique: LicenceHistoriqueDetail[], optionsInstance: OptionInstance[]): Observable<boolean> {
        // copie + formattage des dates pour l'API
        const historique_API = {
            ...historique, 
            ...{dateValidite: this.dateService.dateForAPI(historique.dateValidite),
                dateFacture: this.dateService.dateForAPI(historique.dateFacture),
                dateHeureSaisie: this.dateService.dateForAPI(historique.dateHeureSaisie),
                details: detailsHistorique,
                options: optionsInstance
            }
        };
        return this.httpClient.post<boolean>(this.historiquePath, JSON.stringify(historique_API), {headers: this.postHeaders});
    }

    /*======================================== ACTIONS - DELETE ========================================*/

    deleteHistorique(selectedHistorique: number): Observable<boolean> {
        const path = `${this.historiquePath}/${selectedHistorique}/`;
        return this.httpClient.delete<boolean>(path, {headers: this.postHeaders}).pipe(tap({
            next:(response: boolean) => {
                if (response) {
                    // ligne supprimé, on l'enlève du tableau et on émet
                    this.historiqueList = this.historiqueList.filter(
                        (historique: LicenceHistorique) => historique.idLicenceHistorique !== selectedHistorique);
                    this.emitHistoriqueListSubject();
                }
            },
            error:(error: Error) => {
                console.error(error);
                this.messageService.displayError("Impossible de supprimer cet historique");
            }
        }));
    }

    /*======================================== TOOLS NON REQUEST ========================================*/

    // table building methods
    private buildTable(): void {
        for (let i = 0; i < this.historiqueList.length; ++i) {
            this.historiqueList[i].modulesInfo = [];
            for (const module of this.modulesList) {
                const licence = this.searchModule(this.historiqueList[i], module.idModule);
                this.historiqueList[i].modulesInfo.push({nom: module.code, nombre: licence.nombre ?? 0, flottant: licence.flottant});
            }
        }
    }

    private searchModule(historique: LicenceHistorique, idModule: number): Licence {
        const licence: Licence = {nombre: 0, flottant: false};
        const results = historique.details.filter((detail: LicenceHistoriqueDetail) => detail.idModule === idModule);      // get the affected modules only
        if (results.length > 0) {
            licence.nombre   = results[0].nombre;
            licence.flottant = results[0].flottant;
        }
        return licence;
    }

    ngOnDestroy(): void {
        if (this.moduleListSubscription) {
            this.moduleListSubscription.unsubscribe();
        }
        if (this.optionListSubscription) {
            this.optionListSubscription.unsubscribe();
        }
    }
}
