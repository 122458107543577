import { Pipe, PipeTransform} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: 'keepHtml',
    standalone: true
})
export class SanitizerHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(content: string): string {
    // !! On ne l'utilise que pour le résumé des annonces car on est sûr qu'on le construit nous-même côté backend
    return this.sanitizer.bypassSecurityTrustHtml(content) as string;
  }
}