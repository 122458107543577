<div id="main">
    <div class="column-content column-content-spaced" [ngClass]="{'spaced': instanceList.length == 0}">
        <clr-vertical-nav [clrVerticalNavCollapsible]="true" *ngIf="instanceList.length > 0">
            <ng-container *ngFor="let instance of instanceList">
                <div class="nav hand" (click)="findInstance(instance.referenceInstallation)">
                    <cds-icon clrVerticalNavIcon shape="user"></cds-icon>
                    <span class="marginLeft">
                        {{instance.referenceInstallation}}
                        <ng-container *ngIf="instance.nom != ''">- {{instance.nom}}</ng-container>
                    </span>
                </div>
            </ng-container>
        </clr-vertical-nav>
        <div class="column-content">
            <button (click)="addInstance()" class="btn btn-inline">Ajouter instance</button>
            <button (click)="editHistorique(0)" class="btn btn-inline" *ngIf="currentInstance.idInstance != 0">Ajouter historique</button>
        </div>
        <div class="column-content" >
            <button (click)="redirectToAnnonces()" class="btn btn-inline" *ngIf="currentInstance.idInstance != 0">Liste des annonces</button>
            <button (click)="exportexcel()" class="btn btn-inline" *ngIf="instanceList.length > 0">Exporter vers Excel</button>
        </div>
    </div>
    <div *ngIf="requestCompleted && currentInstance.idInstance > 0; else processing" id="main-content">
        <div id="instanceCards">
            <!-- ========================= carte + infos générales =================================-->
            <div class="card clr-col-4 infoTab" [ngClass]="currentInstance.urlTimesheet">
                <div class="card-header">
                    <h4> Instance
                        <span *ngIf="currentInstance.idInstance != 0">: {{currentInstance.referenceInstallation}}</span>
                        <span *ngIf="currentInstance.idInstance == 0">: ?</span>
                        <ng-container *ngIf="currentInstance.nom != ''"> - {{currentInstance.nom}}</ng-container>
                    </h4>
                    <h4 *ngIf="currentInstance.idInstance != 0">
                        <a (click)="editInstance()" class="marginRight">
                            <cds-icon class="hand" shape="pencil"></cds-icon>
                        </a>
                        <a (click)="openModalToDeleteInstance()" *ngIf="currentInstance.instanceAutomatique">
                            <cds-icon class="hand danger" shape="trash"></cds-icon>
                        </a>
                    </h4>
                </div>
                <div class="card-block">
                    <div class="card-text">
                        <table class="table table-compact table-noborder" *ngIf="currentInstance.idInstance != 0">
                            <colgroup>
                                <col span="1" style="width: 30%;">
                                <col span="1" style="width: 30%;">
                                <col span="1">
                                <col span="1" style="width: 5%;">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td class="left">Numéro du client</td>
                                    <td class="left">{{currentInstance.idClient}}</td>
                                    <td class="left">Avec maintenance</td>
                                    <td class="left">{{currentInstance.avecMaintenance | yesNo}}</td>
                                </tr>
                                <tr>
                                    <td class="left">Nom du client</td>
                                    <td class="left">{{currentInstance.nom}}</td>
                                    <td class="left">Actif</td>
                                    <td class="left">{{currentInstance.estActif | yesNo}}</td>
                                </tr>
                                <tr>
                                    <td class="left">
                                        <span *ngIf="checkDate(currentInstance.dateFinMaintenance)">
                                            Date fin maintenance
                                        </span>
                                    </td>
                                    <td class="left">
                                        <span *ngIf="checkDate(currentInstance.dateFinMaintenance)">
                                            {{currentInstance.dateFinMaintenance | date:'dd/MM/yyyy'}}
                                        </span>
                                    </td>
                                    <td class="left">Synchro des licences</td>
                                    <td class="left">{{currentInstance.synchronisationLicencesEstActive| yesNo}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-compact table-noborder" *ngIf="currentInstance.idInstance != 0">
                            <colgroup>
                                <col span="1" style="width: 30%;">
                                <col span="1">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td class="left">Référence d'installation</td>
                                    <td class="left">{{currentInstance.referenceInstallation}}</td>
                                </tr>

                                <tr *ngIf="currentInstance.nom != ''">
                                    <td class="left">Nom de l'instance</td>
                                    <td class="left">{{currentInstance.nom}}</td>
                                </tr>
                                <tr *ngIf="currentInstance.remarque">
                                    <td class="left">Remarque</td>
                                    <td class="left">{{currentInstance.remarque}}</td>
                                </tr>
                                <tr *ngIf="!currentInstance.estActif">
                                    <td class="left">Message client si inactif</td>
                                    <td class="left">{{currentInstance.messageSiInactif}}</td>
                                </tr>
                                <tr>
                                    <td class="left" *ngIf="currentInstance.instanceAutomatique" class="danger"> Instance automatique </td>
                                </tr>
                            </tbody>
                        </table>
                        <h6 class="no-data" *ngIf="currentInstance.idInstance == 0">Aucune donnée disponible</h6>
                    </div>
                </div>
            </div>
            <!-- ========================= carte + options instance =================================-->
            <div class="card clr-col-4 infoTab"
                *ngIf="currentInstance.options && currentInstance.options.length > 0">
                <div class="card-header">
                    <h4> Options </h4>
                </div>
                <div class="card-block">
                    <div class="card-text">
                        <table class="table table-compact table-noborder">
                            <colgroup>
                                <col span="1" style="width: 70%;">
                                <col span="1" style="width: 30%;">
                            </colgroup>
                            <tbody>
                                <tr *ngFor="let option of currentInstance.options">
                                    <td class="left">{{ option.description }}</td>
                                    <td class="left"> {{(option.type == 1) ? (option.valeur | yesNo) : (option.valeur)}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- ========================= carte + timesheet =================================-->
            <div class="card clr-col-4 infoTab" *ngIf="currentInstance.urlTimesheet">
                <div class="card-header">
                    <h4>Timesheet web</h4>
                </div>
                <div class="card-block">
                    <div class="card-text">
                        <table class="table table-compact table-noborder">
                            <colgroup>
                                <col span="1" style="width: 30%;">
                                <col span="1" style="width: 70%;">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td class="left">Site</td>
                                    <td class="left hand">
                                        <a (click)="redirectToSite(currentInstance.urlTimesheet)">{{currentInstance.urlTimesheet}}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="left">Version</td>
                                    <td class="left">{{siteInstallation.infos.versionFrontend}}</td>
                                </tr>
                                <tr>
                                    <td class="left">API</td>
                                    <td class="left">{{siteInstallation.infos.urlAPI}}</td>
                                </tr>
                                <tr>
                                    <td class="left">Version API</td>
                                    <td class="left">{{siteInstallation.infos.versionAPI}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="currentInstance.idInstance > 0 && currentInstance.estActif == false">
            <div class="alert alert-danger" role="alert">
                <strong>Attention ! Cette instance est désactivée : <br /> Ses liences ne sont pas accessibles pour le moment</strong>
            </div>
        </div>

        <div class="tableFixHead">
            <table *ngIf="historiqueList.length > 0" class="table table-noborder main-table">
                <thead>
                    <tr>
                        <th scope="col" class="table-header" style="width: fit-content;">{{'dateValidite' | typePipe:'title' }}</th>
                        <ng-container *ngFor="let item of historiqueList[0] | keyvalue">
                            <ng-container *ngIf="displayItem(item.key)">
                                <th scope="col" class="table-header" style="width: fit-content;">{{item.key | typePipe: 'title' }}</th>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngFor="let item of historiqueList[0].modulesInfo">
                            <ng-container *ngIf="displayItem(item.nom)">
                                <th scope="col" class="table-header" style="width: fit-content;">
                                {{item.nom | typePipe: 'title' }}
                                </th>
                            </ng-container>
                        </ng-container>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="mainBody">
                    <ng-container *ngFor="let item of historiqueList; index as i;">
                        <tr>
                            <td [title]="item.dateValidite" class="table-header">
                                {{item.dateValidite |typePipe:'dateValidite' }}
                            </td>
                            <ng-container *ngFor="let list of item | keyvalue">
                                <ng-container *ngIf="displayItem(list.key)">
                                    <td [title]="list" class="table-header">{{item[list.key] |typePipe: list.key }}</td>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngFor="let list of item.modulesInfo">
                                <ng-container *ngIf="displayItem(list.nom)">
                                    <td [title]="list" class="table-header">{{list |typePipe: ''}}</td>
                                </ng-container>
                            </ng-container>
                            <td class="containerCenter">
                                <a class="table-row-action edit-action"
                                    (click)="editHistorique(item.idLicenceHistorique)">
                                    <cds-icon class="hand" shape="pencil"></cds-icon>
                                </a>
                            </td>
                            <td class="containerCenter">
                                <a class="table-row-action delete-action"
                                    (click)="openModalToDeleteHisto(item.idLicenceHistorique)">
                                    <cds-icon class="hand danger" shape="trash"></cds-icon>
                                </a>
                            </td>
                        </tr>
                        <tr id="separator">
                            <td colspan="22">
                                <ng-container *ngIf="item.remarque.length > 0">
                                    <table id="optionTable" class="table table-compact table-noborder">
                                        <tr>
                                            <td style="width:5px;"></td>
                                            <td class="left">{{item.remarque |typePipe: 'textarea'}}</td>
                                        </tr>
                                    </table>
                                </ng-container>
                                <table class="table table-compact table-noborder">
                                    <tr *ngIf="item.options.length == 0">
                                        <!-- juste une ligne/colonne pour avoir le trait de séparation -->
                                        <td></td>
                                    </tr>
                                    <tr *ngFor="let option of item.options; index as i;">
                                        <td style="text-align: right;width:320px;">{{ option.description }}</td>
                                        <td class="left" style="width: auto;">{{(option.type == 1) ? (option.valeur |
                                            yesNo) : (option.valeur)}}</td>
                                        <td colspan="17"></td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tfoot>
                    <tr class="white">
                        <td colspan="5"></td>
                        <ng-container *ngFor="let item of historiqueList[0].modulesInfo">
                            <ng-container *ngIf="displayItem(item.nom)">
                                <td scope="col" class="bold" style="width: fit-content;">
                                    {{item.nom | typePipe: 'title' }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <td></td>
                        <td></td>
                    </tr>
                    <!-- --------------------------------------- ligne total sans maintenance actuel -->
                    <tr class="grey">
                        <td style="text-align:right" colspan="5" class="bold">Total au {{currentDay | date:"dd/MM/yy"}}</td>
                        <ng-container *ngFor="let total of totals">
                            <td>
                                <ng-container *ngIf="total.totalSansMaintenanceActuel!=0">
                                    {{total.totalSansMaintenanceActuel}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <td></td>
                        <td></td>
                    </tr>
                    <!-- --------------------------------------- ligne total avec maintenance actuel -->
                    <tr class="grey">
                        <td style="text-align:right" colspan="5" class="bold">Total maintenance au {{currentDay | date:"dd/MM/yy"}}</td>
                        <ng-container *ngFor="let total of totals">
                            <td>
                                <ng-container *ngIf="total.totalAvecMaintenanceActuel!=0">
                                    {{total.totalAvecMaintenanceActuel}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <td></td>
                        <td></td>
                    </tr>
                    <!-- --------------------------------------- ligne total sans maintenance futur -->
                    <tr class="grey" *ngIf="hasDifferences[0]">
                        <td style="text-align:right" colspan="5" class="bold">Total au {{latestDay | date:"dd/MM/yy"}}</td>
                        <ng-container *ngFor="let total of totals">
                            <td>
                                <ng-container *ngIf="total.totalSansMaintenanceFutur!=0">
                                    {{total.totalSansMaintenanceFutur}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <td></td>
                        <td></td>
                    </tr>
                    <!-- --------------------------------------- ligne total avec maintenance futur -->
                    <tr class="grey" *ngIf="hasDifferences[1]">
                        <td style="text-align:right" colspan="5" class="bold">Total maintenance au {{latestDay | date:"dd/MM/yy"}}</td>
                        <ng-container *ngFor="let total of totals">
                            <td>
                                <ng-container *ngIf="total.totalAvecMaintenanceFutur!=0">
                                    {{total.totalAvecMaintenanceFutur}}
                                </ng-container>
                            </td>
                        </ng-container>
                        <td></td>
                        <td></td>
                    </tr>
                    <!-- --------------------------------------- ligne total annonce -->
                    <tr class="grey" *ngIf="hasAnnonce">
                        <td style="text-align:right;font-size: 0.9em;" colspan="5" class="bold">Licences
                            attribuées<br>(flottantes)</td>
                        <ng-container *ngFor="let total of totals">
                            <td>
                                <div *ngIf="total.totalAnnonce != 0" [style.color]="total.color">
                                    {{total.totalAnnonce}}<br>({{total.annonceFlottant |yesNo}})
                                </div>
                            </td>
                        </ng-container>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>

<ng-template #processing>
    <div class="spaced" *ngIf="requestCompleted && (!instanceList ||instanceList.length == 0)">
        On dirait que ce client n'a pas d'instances. Vous pouvez directement les ajouter depuis le bouton "Ajouter" à gauche.<br />
    </div>
    <div *ngIf="!requestCompleted" id="loading" class="loading" style="visibility:inherit">
        Loading&#8230;
    </div>
</ng-template>

<!---------------------------------------- delete validation modal --------------------------->
<clr-modal #validationDialog [(clrModalOpen)]="openModal" [clrModalClosable]="false" [clrModalSize]="'lg'">
    <h3 class="modal-title">
        <ng-container *ngIf="deletingStatus == DELETE_MODE.HISTO">
            Voulez-vous vraiment supprimer cet historique ainsi que
            tous ses détails et options ?
        </ng-container>
        <ng-container *ngIf="deletingStatus == DELETE_MODE.INSTANCE">
            Voulez-vous vraiment supprimer cette instance ainsi que tous ses historiques et annonces ?<br /><br />
            Attention : Celle-ci devrait être automatique
        </ng-container>
    </h3>
    <div class="modal-footer">
        <button class="btn btn-primary" type="submit" (click)="closeModal()">Oui</button>
        <button type="button" class="btn btn-primary-outline" (click)="openModal = false">Non</button>
    </div>
</clr-modal>
