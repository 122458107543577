import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ToolsService } from 'app/services/tools/tools.service';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { CommonModule, NgIf } from '@angular/common';
import { ClrIconModule, ClrStandaloneCdkTrapFocus, ClrNavigationModule, ClrSidePanelModule } from '@clr/angular';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: true,
    imports: [NgIf, RouterLink, ClrIconModule, ClrStandaloneCdkTrapFocus, ClrNavigationModule, RouterLinkActive, 
      ClrSidePanelModule, CommonModule
    ]
})
export class HeaderComponent implements OnInit {
  decoNoel: boolean = false;
  userName: string = '';
  userPicture: string = '';
  sideNavOpened: boolean = false;
  size: string = 'sm';

  constructor(private authService: AuthService, private router: Router, private toolsService: ToolsService, private httpBackend: HttpBackend) { }

  ngOnInit(): void {
    this.authService.trigger.subscribe(() => this.getUserInfo());
    this.getUserInfo();
    this.getDecoration();
  }

  isInsideApp(): boolean {
    return this.router.url !== '/login' && this.router.url !== '/';
  }

  /**
   * Log out Google
   */
  logOut(): void {
    this.authService.logOut();
  }

  /**
   * Prends le nom d'utilisateur et la photo google de l'utilisateur
   */
  private getUserInfo(): void {
    this.userName = this.toolsService.localGetByKey('userName') ?? '';
    this.userPicture = this.toolsService.localGetByKey('userPicture') ?? '';
  }

  /**
   * Prends les variables de decoration sur ocnfig_file.json
   * @returns Promise qui peut ensuite donner les variables decoration
   */
  getDecoration(): Promise<boolean> {
    type Response = {
      api: string;
      deconoel: boolean;
    };
    const http: HttpClient = new HttpClient(this.httpBackend);
    return new Promise((resolve) => {
      http
        .get<Response>('/config_file.json')
        .subscribe({
          next:(response) => {
            const responseObj = response as Response;
            this.decoNoel = responseObj.deconoel;
            resolve(true);
          },
          error:() => {
            console.error('Erreur! : ${error}');
            resolve(true)
          }
        });
    });
  }

}
