import { Component, Inject, NgZone, Renderer2 } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { DOCUMENT, NgIf } from '@angular/common';
import { ToolsService } from 'app/services/tools/tools.service';
import { MessageService } from 'app/services/tools/message.service';
import { FormsModule } from '@angular/forms';
import { ClrAlertModule } from '@clr/angular';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: true,
    imports: [
        FormsModule,
        NgIf,
        ClrAlertModule,
    ],
})

export class LoginComponent {
  requestCompleted = true;
  requestFailed = false;

  constructor(private _renderer2: Renderer2,@Inject(DOCUMENT) private _document: Document,private authService: AuthService, private ngZone: NgZone,
    private toolsService: ToolsService, private messageService: MessageService) {}

  // scripts pour faire marcher le login via Google (nouvelle façon de faire)
  ngAfterViewInit(): void {
    const script = this._renderer2.createElement('script');
    script.src   = `https://accounts.google.com/gsi/client`;
    script.async = `true`; 
    script.defer = `true`; 
    this._renderer2.appendChild(this._document.body, script);
  }

  ngOnInit(): void {
    if (this.toolsService.localGetByKey('access_token')) {
      // on essaie d'utiliser l'access_token direct si on le trouve
      this.authService.navigate();
      return;
    }
    // @ts-ignore
    window.onGoogleLibraryLoad = (): void => {
      const btn = document.getElementById("buttonDiv");
      if(btn === null)
        return;
      // on crée la fenêtre popup de login et on donne une fonction de chez nous à appeler pour recevoir le code quand c'est fini
      // @ts-ignore
      const client = google.accounts.oauth2.initCodeClient({
        client_id: '254253590324-f6smosuqhc1i7j5g3h3ph95nf2nmlaeu.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email openid',
        ux_mode: 'popup',
        callback: this.handleCredentialResponse.bind(this), // si tout va bien
        error_callback: this.handleFailure.bind(this), // si ça merde sec quelque part
        prompt: 'consent',
      });
      client.requestCode();
    };
  }

  stopRequest(): void {
    this.requestFailed    = true;
    this.requestCompleted = true;
  }

  handleFailure(err:{message: string, stack: string, type: string}): void {
    this.ngZone.run(() => {
      if (err.type === 'popup_failed_to_open') {
        // ici on détecte juste l'erreur lancée quand l'utilisateur a bloqué les popups pour ce site
        this.messageService.displayError("Vérifiez que les popup sont autorisées pour ce site (icône barrée dans le coin droit de la barre de recherche) "+
        "et rafraîchissez la page.");
      } else {
        // ici l'utilisateur a fermé manuellement la popup ou on a pas pu récupérer le code de Google
        this.stopRequest();
      }
    })
  }

  // on est appelé quand l'utilisateur s'est authentifié via la popup et on a le code
  async handleCredentialResponse(response: {code: string}): Promise<void> {
    this.authService.loginGoogle(response.code).subscribe({
      next:(email: string) => {
        // le token d'accès a été sauvegardé dans le session storage => on cherche le user dans la db à nous
        this.authService.login(email).subscribe({
          next:(email: string) => {
            if (email.length === 0) {
              this.requestCompleted = true;
              this.ngZone.run(() => {
                this.authService.navigate();
              })
            } else { this.stopRequest() }
          }, error:() => this.stopRequest()
        });
      }, error:() => this.stopRequest()
    });
  }
}