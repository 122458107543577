<div class="alert alert-app-level alert-warning" role="alert" *ngIf="excelLoading">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="download"></cds-icon>
        </div>
        <div class="alert-text">Le fichier excel est en cours de création.</div>
      </div>
    </div>
    <button type="button" class="close" aria-label="Close">
      <cds-icon aria-hidden="true" shape="times"></cds-icon>
    </button>
</div>
