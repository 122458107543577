<div class="content-container">
    <nav class="sidenav" style="width: 200px;">
        <section class="sidenav-content">
            <section class="nav-group">
                <button routerLink="/options" class="btn btn-inline">Back to list</button>
            </section>
        </section>
    </nav>

    <div *ngIf="requestCompleted" class="content-area">
        <form clrForm (ngSubmit) = "onSubmit()" #f="ngForm" *ngIf="option">

            <clr-input-container>
                <label for="nomOption">Nom de l'option</label>
                <input clrInput type="text" id="nomOption" name="nomOption" [(ngModel)]="option.nom"/>
            </clr-input-container>

            <clr-input-container>
                <label for="type">Type de l'option</label>
                <select (change)="update('type',$event)" clrInput id="type" name="type">
                    <option value="1" [selected]="option.type == 1">Booléen</option>
                    <option value="2" [selected]="option.type == 2">Numérique</option>
                    <option value="3" [selected]="option.type == 3">Texte</option>
                    <!--<option value="4" [selected]="option.type == 4">Date / Heure</option>-->
                </select>
            </clr-input-container>

            <clr-textarea-container>
                <label>Description</label>
                <textarea id="description" clrTextarea [(ngModel)]="option.description" name="description"></textarea>
              </clr-textarea-container>

            <clr-input-container>
                <label for="mode">Mode</label>
                <select (change)="update('mode',$event)" clrInput id="mode" name="mode">
                    <option value="1" [selected]="option.mode == 1">Historique</option>
                    <option value="2" [selected]="option.mode == 2">Unique</option>
                </select>
            </clr-input-container>

            <clr-input-container>
                <label for="nomOption">Section</label>
                <input clrInput type="text" id="section" name="section" [(ngModel)]="option.section"/>
            </clr-input-container>

            <br/>
        </form>
        <div class="navigation">
            <button type="submit" (click)="onSubmit()" class="btn btn-success-inline" *ngIf="option"> Valider </button>
            <button id="mobileButton" routerLink="/options" class="btn btn-inline">Back to list</button>
        </div>
        <div *ngIf="invalidForm" class="alert alert-warning" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                    </div>
                    <span class="alert-text">Veuillez entrer un nom d'option</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!requestCompleted" id="loading" class="loading" style="visibility:inherit">
        Loading&#8230;
    </div>
</div>
