import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnnonceService } from '../services/annonce.service';
import { InstanceService } from '../services/instance.service';
import { Annonce } from '../types/annonce';
import { Instance } from '../types/instance';
import { Pagination, Sort, UserAnnonce, UserData } from './annonce-types';
import { DashboardPanel, DashboardTab } from 'app/dashboard/dashboard.component';
import { MessageService } from 'app/services/tools/message.service';
import { ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrTabsModule, ClrConditionalModule, ClrDatepickerModule, ClrDatagridModule, ClrIconModule, ClrCommonFormsModule } from '@clr/angular';
import { NgIf, DatePipe, KeyValuePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SanitizerHtmlPipe } from '../tools/pipes/sanitizer.pipe';

@Component({
    selector: 'app-annonces',
    templateUrl: './annonces.component.html',
    styleUrls: ['./annonces.component.css'],
    standalone: true,
    imports: [ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrTabsModule, ClrConditionalModule, NgIf, ClrDatepickerModule, FormsModule, ClrDatagridModule, ClrIconModule, ClrCommonFormsModule, DatePipe, KeyValuePipe, SanitizerHtmlPipe]
})

export class AnnoncesComponent implements OnInit {
  refInstall!: string;
  idClient!: number;

  instance: Instance = new Instance();

  annonces: Annonce[] = [];
  annoncesOrigin: Annonce[] = [];
  userData: UserData = { start: null, end: null, users: [], filtered_users: new Map<string, string[]>() };

  requestCompleted: boolean = false;
  userRequestCompleted: boolean = false;
  sortedTable: Sort = new Sort();
  pagination: Pagination = { startRes: 0, endRes: 9, pageSize: 10, total: -1 };
  customStartDate: string = '';

  constructor(private activatedRoute: ActivatedRoute, private annonceService: AnnonceService, private router: Router,
    private instanceService: InstanceService, private messageService: MessageService) {
    const nbrOfResults = this.annonceService.MAX_ANNONCE_RESULT;
    this.pagination = { startRes: 0, endRes: nbrOfResults - 1, pageSize: nbrOfResults, total: -1 }
  }

  ngOnInit(): void {
    this.refInstall = this.activatedRoute.snapshot.paramMap.get('refInstall') ?? '';
    this.idClient = Number(this.activatedRoute.snapshot.paramMap.get('idClient')) ?? -1;
    this.getInstances();

    this.annonceService.annoncesListSubject.subscribe({
      next: (response: Annonce[]) => {
        this.annoncesOrigin = response;
        this.annonces = response;
        if (this.pagination.total === -1) {
          this.getTotalCount();
        } else {
          this.requestCompleted = true;
        }
      },
      error: (error) => {
        this.requestCompleted = true;
        console.error(error);
      }
    });
  }

  /*======================================== LOADING DATA ========================================*/
  /**
   * Récupère l'instance d'après sa référence d'installation
   */
  getInstances(): void {
    this.instanceService.getSingleInstanceWithoutSubject(this.refInstall).subscribe({
      next: (instance: Instance) => {
        this.instance = instance;
        this.getAnnonces(); // on récupère une plage d'annonce
        this.getAllUsers(); // on récupère tous les duos utilisateurs / postes
      }
    });
  }

  /**
   * Récupère l'ensemble des annonces
   */
  getAnnonces(): void {
    this.annonceService.getAllAnnonces(this.instance.idInstance, this.pagination.startRes);
  }

  filterByStartDate(): void {
    if (this.customStartDate !== '') {
      this.annonceService.getAllAnnoncesByStartDate(this.instance.idInstance, this.customStartDate, this.pagination.total).subscribe({
        next: (response: { annonces: Annonce[], index: number }) => {
          this.pagination.startRes = response.index;
          this.pagination.endRes = this.pagination.startRes + this.pagination.pageSize - 1;
        },
        error: (error: Error) => this.messageService.displayError(error.message)
      });
    }
  }

  /**
   * Calcul du total des annonces pour la pagination
   */
  getTotalCount(): void {
    this.annonceService.countAnnonces(this.instance.idInstance).subscribe({
      next: (response: number) => {
        this.pagination.total = response;
        this.requestCompleted = true;
      }
    });
  }

  exportexcel(): void {
    const nomInstance = this.instance.nom !== '' ? `instance ${this.refInstall}_${this.instance.nom}` : `instance ${this.refInstall}`
    this.annonceService.exportExcel(this.instance.idInstance, nomInstance);
  }

  /*======================================== USERS ========================================*/
  /**
   * Récupère la liste des utilisateurs et les machines qu'ils ont utilisées pour émettre des annonces
   * d'après la liste des annonces
   */
  private getAllUsers(): void {
    if (this.userData.users.length === 0) {
      // on doit récupérer la liste on l'a pas encore
      this.annonceService.getAllUsers(this.instance.idInstance).subscribe({
        next: (users: UserAnnonce[]) => {
          this.userData.users = users;
          this.filterUsers();
          this.userRequestCompleted = true;
        }
      })
    } else {
      this.filterUsers();
    }
  }

  filterUsers(): void {
    this.userData.filtered_users = new Map<string, string[]>();

    for (const user of this.userData.users) {
      const dateAnnonce = new Date(user.dateAnnonce);
      if (this.userData.start !== null && dateAnnonce < this.newDate(this.userData.start)) { continue; }
      if (this.userData.end !== null && dateAnnonce > this.newDate(this.userData.end)) { continue; }

      if (!this.userData.filtered_users.has(user.username)) {
        this.userData.filtered_users.set(user.username, [user.machine]);

      } else if (!this.userData.filtered_users.get(user.username)?.includes(user.machine)) {
        this.userData.filtered_users.get(user.username)?.push(user.machine);
      }
    }
  }

  private newDate(dateString: string): Date {
    const [day, month, year] = dateString.split('/')
    return new Date(+year, +month - 1, +day)
  }

  /*======================================== PAGINATION ========================================*/
  loadOnePage(forward = false): void {
    if (forward) {
      this.pagination.startRes += this.pagination.pageSize;
      this.pagination.endRes = this.pagination.endRes += this.pagination.pageSize;
    } else {
      this.pagination.startRes = this.pagination.startRes ? this.pagination.startRes -= this.pagination.pageSize : this.pagination.startRes;
      this.pagination.endRes -= this.pagination.pageSize;
    }
    this.requestCompleted = false;
    this.getAnnonces();
  }

  /*======================================== REDIRECTION ========================================*/
  backToList(): void {
    this.router.navigate([`/dashboard/${this.idClient}/${DashboardTab.Instance}/${DashboardPanel.None}/${this.instance.referenceInstallation}`]);
  }

  /*======================================== TEMPLATE METHODS ========================================*/
  sort(name: string): void {
    if (this.sortedTable[name as keyof Sort]) {
      this.annonces.sort((a: Annonce, b: Annonce) => {
        return (a[name as keyof Annonce] < b[name as keyof Annonce]) ? 1 : -1;
      });
    } else {
      this.annonces.sort((a: Annonce, b: Annonce) => {
        return (a[name as keyof Annonce] > b[name as keyof Annonce]) ? 1 : -1;
      });
    }
    this.sortedTable[name as keyof Sort] = !this.sortedTable[name as keyof Sort];   // update the sorting status => will do the opposite next time
  }
}
