
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { ToolsService } from './tools/tools.service';

@Injectable()
export class AuthGuard {
    constructor(private authService: AuthService, private toolsService: ToolsService) {}
    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        // si on a des param dans l'url : a/b/c on renvoie pas en plus des param. on envoie que cette url
        // si on est toujours authentifié on continue notre route
        const accessToken = this.toolsService.localGetByKey('access_token');
        if(accessToken === '') { // sans ça imposible de contacter l'API, on sort          
            this.authService.logOut();
            return false;
        }
        return true;
    }
}
