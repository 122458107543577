import { ToolsService } from "app/services/tools/tools.service";

/**
 * Classe de tri utilisée par le tableau de clients et le tableau des installations pour le tri des lignes
 */
export class SortManager {
    private sortMode!: SortMode;
    private toolsService: ToolsService;
    private storageNamePrefix: string;
    constructor(defaultCol: string, storageNamePrefix: string, toolsService: ToolsService) {
      this.storageNamePrefix = storageNamePrefix;
      this.toolsService      = toolsService;
      this.loadSortInfo(defaultCol);
    }

    // Méthode de tri appelée depuis l'extérieur
    sort<T>(nameCol: string, data: T[], compareFn: ((a: T, b: T) => number), initialSort: boolean): void {
      if(!initialSort) {
        this.changeSortedCol(nameCol);
        this.changeSortDirection();
        this.storeSortInfo();
      }
      data.sort(compareFn);
    }

    compare<T>(col1: T, col2: T): number {
      let res = 0;
      if (typeof col1 === 'string' && typeof col2 === 'string') {
        // si on a des dates on met toujours les dates null à la fin
        if(new Date(col2).getFullYear() < 2000) {
          return -1;
        }
        if(new Date(col1).getFullYear() < 2000) {
          return 1;
        }
        res = col1.localeCompare(col2, 'fr', { ignorePunctuation: true });
      } else {
        res = col1 < col2 ? -1 : (col1 > col2 ? 1 : 0);
      }
      return this.isDesc() ? -res : res;
    }

    // vérifie le sens du tri
    isDesc(): boolean { return this.sortMode.order === SortMode.DESC; }

    // vérifie si on trie sur une colonne donnée
    sameCol(col: string): boolean { return this.sortMode.col === col; }

    // donne la colonne triée
    getcol(): string { return this.sortMode.col; }

    // stocke la colonne + le sens du tri dans le localStorage
    private storeSortInfo(): void {
      this.toolsService.localSetByKeyValue(`${this.storageNamePrefix}_sortedCol`, this.sortMode.col);
      this.toolsService.localSetByKeyValue(`${this.storageNamePrefix}_sortedStatus`, this.sortMode.order);
    }

    // met à jour le sens du tri
    private changeSortDirection(): void {
      this.sortMode.order = this.sortMode.order === SortMode.ASC ? SortMode.DESC : SortMode.ASC; 
    }

    // change de colonne à trier
    private changeSortedCol(col:string): void {
      if (this.sortMode.col !== col) {
        this.sortMode = new SortMode(col, SortMode.NONE);
      }
    }
    
    // Récupère les infos de tri depuis le localStorage
    private loadSortInfo(defaultCol: string): void {
      const sortedCol    = this.toolsService.localGetByKey(`${this.storageNamePrefix}_sortedCol`);
      const sortedStatus = this.toolsService.localGetByKey(`${this.storageNamePrefix}_sortedStatus`);
      if(sortedCol !== '' && sortedStatus !== '') {
        this.sortMode = new SortMode(sortedCol, sortedStatus);
      } else {
        this.sortMode = new SortMode(defaultCol, SortMode.NONE);
      }
    }
}

/**
 * Classe interne utilisée uniquement par le SortManager
 */
class SortMode {
  static ASC  = 'asc';
  static DESC = 'desc';
  static NONE = 'none';
  col: string;
  order: string;
  constructor(col: string, order: string) {
    this.col   = col;
    this.order = order;
  }
  triggerSort(): void {
    this.order = this.order === SortMode.ASC ? SortMode.DESC : SortMode.ASC; 
  }
}