import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RoutingConfigService {
    urlPrefix: string = '';
    googleClientId: string = '';
    constructor(private httpBackend: HttpBackend) {}

    load(): Promise<boolean> {

        type Response = {
            api: string;
            google_client_id: string;
        };
        const http: HttpClient = new HttpClient(this.httpBackend);
        return new Promise((resolve, reject) => {
            http
            .get<Response>('./config_file.json')
            .subscribe({
                next: (response) => {
                    const responseObj = response as Response;
                    this.urlPrefix = responseObj.api;
                    this.googleClientId = responseObj.google_client_id;
                    resolve(true);
                },
                error: (error) => {
                    console.error(`Erreur ! : ${error}`);
                    resolve(true);
                }
            });
        });
    }

    public getUrl(): string {
        return this.urlPrefix;
    }

    public load_additional_data(): Observable<string> {
        const http: HttpClient = new HttpClient(this.httpBackend);
        return http.get<string>('./assets/additional_data.txt', {responseType: 'text' as 'json'});
    }
}
