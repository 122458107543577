import { Component, OnInit } from '@angular/core';
import { Site } from "../types/Site.interface"
import { SortManager } from 'app/tools/sort-manager';
import { ToolsService } from 'app/services/tools/tools.service';
import { TimesheetService } from 'app/services/timesheet.service';
import { Router } from '@angular/router';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { ClrIconModule } from '@clr/angular';
@Component({
    selector: 'app-installations',
    templateUrl: './installations.component.html',
    styleUrls: ['./installations.component.css'],
    standalone: true,
    imports: [NgIf, ClrIconModule, NgClass, NgFor]
})

export class InstallationsComponent implements OnInit {
  constructor(private timesheetService: TimesheetService, private toolsService: ToolsService, private router: Router) {
    this.sortManager = new SortManager('versionFrontend', 'install', this.toolsService);
  }

  selectedSite: Site = new Site();
  dataSource: Site[] = [];
  sortManager!: SortManager;

  ngOnInit(): void {
    this.dataSource = []; // Vide la dataSource
    this.timesheetService.populatedSubject.subscribe({
      next: (site: Site) => {
        //Chaque fois qu'il reçois un "resultat" il le push dans dataSource qui va s'afficher
        this.dataSource.push(Site.copy(site))
        this.sort(this.sortManager.getcol(), true);
      }
    });
    this.timesheetService.getAllWebsites();
  }

  /**
   * Fonction pour ouvrir le timesheet dans un nouvel onglet
   * @param url Url pour redirectioner
   */
  redirectToSite(url: string): void {
    window.open("https://" + url, "_blank");
  }

  /**
   * Selectionne un site
   * @param site le site a selectionner
   */
  select(site: Site): void {
    this.selectedSite = site;
  }

  /**
   * Renvoie sur l'édition de l'instance dans l'onglet avec les infos du timesheet
   * @param code Code de l'instance
   */
  modify(selectedSite: Site): void {
    if (selectedSite.idInstance > 0) {
      this.router.navigate([`/instance/${this.selectedSite.infos.idClient}/${this.selectedSite.infos.instanceRefInstall}/${this.selectedSite.idInstance}`]);
    }
  }

  /**
   * Trie le tableau
   * @param trier la colonne à trier
   */
  sort(nameCol: string, initialSort = false): void {
    this.sortManager.sort(nameCol, this.dataSource, (a: Site, b: Site) => {
      const getValue = (site: Site, column: string) => {
        let value = site[column as keyof Site];
        if (value === undefined) {

          value = site.infos[column as keyof Site['infos']];
        }
        return value;
      };

      const aValue = getValue(a, nameCol);
      const bValue = getValue(b, nameCol);

      if (!aValue || aValue.toString().includes("⛔️") || aValue.toString().includes("❗️")) {
        return 1;
      } else if (!bValue || bValue.toString().includes("⛔️") || bValue.toString().includes("❗️")) {
        return -1;
      }

      return this.sortManager.compare(aValue, bValue);
    }, initialSort);
  }

}