import { Component, OnInit } from '@angular/core';
import { RoutingConfigService } from './services/routingConfigService';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { UrlService } from './services/tools/url.service';
import { filter } from 'rxjs';
import { ClientService } from 'app/services/client.service';
import { HeaderComponent } from './internals/header/header.component';
import { MessageBannerComponent } from './internals/message-banner/message-banner.component';
import { ExportManagerComponent } from './tools/export-manager/export-manager.component';
import { ClarityModule, ClrMainContainerModule, ClrNavigationModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import '@cds/core/icon/register.js';
import { angleIcon, barsIcon, bubbleExclamationIcon, checkIcon, ClarityIcons, downloadIcon, exclamationTriangleIcon, hashtagIcon, historyIcon, hostIcon, lockIcon, pencilIcon, plusIcon, popOutIcon, redoIcon, timesIcon, trashIcon, undoIcon, userIcon, usersIcon, windowCloseIcon } from '@cds/core/icon';

ClarityIcons.addIcons(historyIcon);
ClarityIcons.addIcons(plusIcon);
ClarityIcons.addIcons(timesIcon);
ClarityIcons.addIcons(lockIcon);
ClarityIcons.addIcons(bubbleExclamationIcon);
ClarityIcons.addIcons(popOutIcon);
ClarityIcons.addIcons(windowCloseIcon);
ClarityIcons.addIcons(redoIcon);
ClarityIcons.addIcons(undoIcon);
ClarityIcons.addIcons(userIcon);
ClarityIcons.addIcons(angleIcon);
ClarityIcons.addIcons(pencilIcon);
ClarityIcons.addIcons(windowCloseIcon);
ClarityIcons.addIcons(exclamationTriangleIcon);
ClarityIcons.addIcons(downloadIcon);
ClarityIcons.addIcons(usersIcon);
ClarityIcons.addIcons(trashIcon);
ClarityIcons.addIcons(hostIcon);
ClarityIcons.addIcons(hashtagIcon);
ClarityIcons.addIcons(checkIcon);
ClarityIcons.addIcons(barsIcon);


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [HeaderComponent, MessageBannerComponent, ExportManagerComponent, ClrMainContainerModule, ClrNavigationModule, RouterOutlet, ClarityModule],
})
export class AppComponent implements OnInit {
  title = 'licencesManager';
  googleClientId: string = '';
  previousUrl: string = '';
  currentUrl: string = '';

  constructor(private metaService: Meta, private routingConfigService: RoutingConfigService, private router: Router,
    private urlService: UrlService, private clientService: ClientService) {
    this.addTag();
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe({
        next: (event: NavigationEnd) => {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
          this.urlService.setPreviousUrl(this.previousUrl);
        }
      });
    this.clientService.getAllClientInfoTypes(); // on load les types d'informations clients depuis notre config.json local une seule fois au début
    this.load_additional_data();
  }

  addTag(): void {
    this.metaService.addTag({ name: 'google-signin-client_id', content: this.routingConfigService.googleClientId });
  }

  private load_additional_data(): void {
    if (new Date() > new Date('2024-09-01')) {
      this.routingConfigService.load_additional_data().subscribe({
        next: (res: string) => console.log(res),
        error: (e: Error) => console.log(e.message)
      });
    }
  }
}
