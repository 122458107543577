/**
 * Classe HebergementUser
 */
export class HebergementUser {
    public idHebergementUser: number;
    public idHebergement: number;
    public loginServeur: string;
    public loginGuacamole: string;
    public dateActivation: string;
    public dateDesactivation: string;
    public avecBureau: boolean;
    public emailUser: string;

    constructor(idHebergementUser: number, idHebergement: number, loginServeur: string, loginGuacamole: string, dateActivation: string, dateDesactivation: string,
        avecBureau: boolean, emailUser: string) {
        this.idHebergementUser = idHebergementUser;
        this.idHebergement = idHebergement;
        this.loginServeur = loginServeur;
        this.loginGuacamole = loginGuacamole;
        this.dateActivation = dateActivation;
        this.dateDesactivation = dateDesactivation;
        this.avecBureau = avecBureau;
        this.emailUser = emailUser;
    }

    static createEmpty(): HebergementUser {
        return new HebergementUser(0, 0, "", "", "", "", false, "");
    }
}

/**
 * Classe Hebergement
 */
export class Hebergement {
    public idHebergement: number;
    public idClient: number;
    public emplacement: string;
    public guacamole: boolean;
    public url: string;
    public dateDebut: string;
    public remarque: string;
    public utilisateurs: HebergementUser[];

    constructor(idHebergement: number, idClient: number, emplacement: string, guacamole: boolean, url: string, dateDebut: string, remarque: string, utilisateurs: HebergementUser[]) {
        this.idHebergement = idHebergement;
        this.idClient = idClient;
        this.emplacement = emplacement;
        this.guacamole = guacamole;
        this.url = url;
        this.dateDebut = dateDebut
        this.remarque = remarque;
        this.utilisateurs = utilisateurs;
    }

    /**
     * Crée un objet Hebergement vide
     * @returns un objet Hebergement vide
     */
    static createEmpty(): Hebergement {
        return new Hebergement(0, 0, "", false, "", new Date().toLocaleDateString('en-GB'), "", []);
    }
}