import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Instance } from '../types/instance';
import { OptionInstance } from '../types/option_instance';
import { InstanceService } from '../services/instance.service';
import { UrlService } from '../services/tools/url.service';
import { Subject, forkJoin } from 'rxjs';
import { DateService } from 'app/services/tools/date.service';
import { registerLocaleData, NgIf } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DashboardPanel, DashboardTab } from 'app/dashboard/dashboard.component';
import { ClientService } from 'app/services/client.service';
import { Client } from 'app/types/client';
import { MessageService } from 'app/services/tools/message.service';
import { FormsModule } from '@angular/forms';
import { ClrCommonFormsModule, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrTabsModule, ClrConditionalModule, ClrInputModule, ClrCheckboxModule, ClrDatepickerModule, ClrTextareaModule, ClrIconModule, ClrAlertModule } from '@clr/angular';
import { OptionsTabComponent } from '../internals/options-tab/options-tab.component';

registerLocaleData(localeFr);

enum Tab {
  General,
  Options,
}

@Component({
    selector: 'app-single-instance',
    templateUrl: './single-instance.component.html',
    styleUrls: ['./single-instance.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, ClrCommonFormsModule, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrTabsModule, ClrConditionalModule, ClrInputModule, ClrCheckboxModule, ClrDatepickerModule, ClrTextareaModule, ClrIconModule, ClrAlertModule, OptionsTabComponent]
})

export class SingleInstanceComponent implements OnInit {
  idInstance!: number;
  idClient!: number;

  client!: Client;
  instance: Instance = new Instance();

  instanceCode!: number;
  existingClient: boolean = true;   // allow code client edition only when we create it
  previousUrl: string = '';
  // permet d'avertir les composants enfants que l'instance est prête
  currentInstanceSubject: Subject<Instance> = new Subject();
  Tab = Tab;
  currentTab: Tab = Tab.General;

  requestCompleted: boolean = false;
  sending: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private instanceService: InstanceService,
    private urlService: UrlService, private dateService: DateService, private clientService: ClientService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.idInstance = Number(this.activatedRoute.snapshot.paramMap.get('idInstance')) ?? 0;
    this.idClient = Number(this.activatedRoute.snapshot.paramMap.get('idClient')) ?? 0;

    switch (this.activatedRoute.snapshot.paramMap.get('tab')) {
      case 'options':
        this.currentTab = Tab.Options;
        break;
      default:
        this.currentTab = Tab.General;
        break;
    }

    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousUrl = previousUrl;
    });
    if (this.idInstance <= 0) {
      // on est en train de créer une nouvelle instance => on récupère la liste pour créer la référence d'install
      this.getClientAndList();
    } else {
      this.getInstanceAndClient();
    }
  }
  /**
   * Update les options
   * 
   * @param foundOptions Options reçus du emit
   */
  getOptionsInstance(foundOptions: OptionInstance[]): void {
    this.instance.options = foundOptions;
  }

  getInstanceAndClient(): void {
    const instanceObs = this.instanceService.getInstanceById(this.idInstance);
    const clientObs = this.clientService.getClientById(this.idClient);

    // on attend que les 2 observables aient terminés pour récupérer les 2 résultats (client + instance)
    forkJoin([instanceObs, clientObs]).subscribe(results => {
      this.instance = results[0];
      if (this.instance.instanceAutomatique) {
        this.existingClient = false;
      }
      this.instance.dateFinMaintenance = this.dateService.formatForDateInput(this.instance.dateFinMaintenance);
      this.currentInstanceSubject.next(this.instance);
      this.instanceCode = this.referenceCode(this.instance.referenceInstallation);
      this.client = results[1];
      this.requestCompleted = true;
    });
  }

  getClientAndList(): void {
    const instanceListObs = this.instanceService.getAllInstancesReq(this.idClient);
    const clientObs = this.clientService.getClientById(this.idClient);

    // on attend que les 2 observables aient terminés pour récupérer les 2 résultats (client + instance)
    forkJoin([instanceListObs, clientObs]).subscribe(results => {
      this.client = results[1];
      const instanceList = results[0]
      const codeList: string[] = instanceList.map(el => el.referenceInstallation);
      let nextRef = '';
      if (codeList.length > 0) {
        const lastCode = codeList[0].substring(codeList[0].indexOf(".") + 1)
        nextRef = `${this.client.code}.${Number(lastCode) + 1}`
      } else {
        nextRef = `${this.client.code}.0`;
      }
      this.instance = Instance.createNew(nextRef, this.idClient);
      this.requestCompleted = true;
    });
  }

  onSubmit(): void {
    this.sending = true;
    this.instanceService.postInstance(this.instance).subscribe({
      next: ((newId: number) => {
        if (newId > 0) {
          this.backToList();
        } else {
          this.messageService.displayError(`Echec de la ${this.instance.idInstance > 0 ? 'modification' : 'création'} de l'instance`);
        }
      }),
      error: ((error: Error) => {
        console.error(error);
        this.messageService.displayError(`Echec de la ${this.instance.idInstance > 0 ? 'modification' : 'création'} de l'instance`);
      })
    });
  }

  enableEdit(): void {
    this.instance.estActif = !this.instance.estActif;
  }

  changeTab(tab: Tab): void {
    this.currentTab = tab;
  }

  backToList(): void {
    if (this.idInstance) {
      this.router.navigate([`/dashboard/${this.idClient}/${DashboardTab.Instance}/${DashboardPanel.None}/${this.instance.referenceInstallation}`]);
    } else {
      this.router.navigate([`/dashboard/${this.idClient}`]);
    }
  }

  referenceCode(ref: string): number {
    const regex = new RegExp("^\\S+[.]\\d+$");
    if (regex.test(ref))
      return Number(ref.substring(ref.indexOf(".") + 1));
    return -1;
  }
}