<div class="content-container">
    <div  class="content-area center">
        <h2>Erreur 404</h2>
        <p>La page que vous cherchez n'existe pas !</p>
        <div>
            <iframe style="display:none;" width="420" height="345" src="https://www.youtube.com/embed/dQw4w9WgXcQ"></iframe>
            <br>
            <button (click)="back()" class="btn btn-inline">Retour</button>
        </div>
    </div>
</div>
