import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Instance } from '../types/instance';
import { Module } from '../types/module';
import { TotalTable } from '../types/total';
import { LicenceHistorique } from '../types/licence_historique';
import { InstanceService } from '../services/instance.service';
import { HistoriqueService } from '../services/historique.service';
import { MessageService } from '../services/tools/message.service';
import { DELETE_MODE } from '../services/tools/tools.service';
import { Site } from '../types/Site.interface';
import { TimesheetService } from '../services/timesheet.service';
import { DashboardPanel, DashboardTab } from 'app/dashboard/dashboard.component';
import { NgClass, NgIf, NgFor, DatePipe, KeyValuePipe } from '@angular/common';
import { ClrVerticalNavModule, ClrIconModule, ClrModalModule } from '@clr/angular';
import { YesNoPipe } from '../tools/pipes/yes-no.pipe';
import { TypePipe } from '../tools/pipes/type.pipe';

@Component({
    selector: 'app-historique',
    templateUrl: './historique.component.html',
    styleUrls: ['./historique.component.css'],
    standalone: true,
    imports: [NgClass, NgIf, ClrVerticalNavModule, NgFor, ClrIconModule, ClrModalModule, DatePipe, KeyValuePipe, YesNoPipe, TypePipe]
})

export class HistoriqueComponent implements OnInit, OnDestroy {

  @Input() title: string = '';
  @Input() idClient: number = 0;
  @Input() codeClient: string = '';
  @Input() nomClient: string = '';
  @Input() instanceID: number = 0;

  @Output() getToClientTab: EventEmitter<void> = new EventEmitter<void>();

  siteInstallation: Site = new Site();

  currentInstance: Instance = new Instance();

  instanceList: Instance[] = [];
  instanceSubscription: Subscription = new Subscription();

  historiqueList: LicenceHistorique[] = [];
  historiqueListOrigine: LicenceHistorique[] = [];
  historiqueListSubscription: Subscription = new Subscription();
  selectedHistoriqueId: number = 0;

  moduleList: Module[] = [];

  totals: TotalTable[] = [];
  totalSubscription: Subscription = new Subscription();

  requestCompleted: boolean = false;
  openModal = false;
  currentDay: Date = new Date();
  latestDay: Date = new Date();
  hasAnnonce: boolean = false;
  hasDifferences: boolean[] = [false, false];

  deletingStatus: DELETE_MODE = DELETE_MODE.VOID;
  DELETE_MODE = DELETE_MODE;

  constructor(private instanceService: InstanceService, private historiqueService: HistoriqueService, private router: Router,
    private messageService: MessageService, private timesheetService: TimesheetService) { }

  ngOnInit(): void {
    this.requestCompleted = false;
    this.getInstances();
    this.currentDay = new Date();
  }

  /*======================================== CHARGEMENT DES DONNEES ========================================*/
  getInstances(): void {
    this.instanceSubscription = this.instanceService.instanceListSubject.subscribe({
      next: (instanceList: Instance[]) => {
        this.instanceList = instanceList;
        if (this.instanceList.length > 0) {

          // si on a une instance à afficher, on la cherche dans la liste
          if (this.instanceID != 0) {
            let foundInstanceIndex = -1
            this.instanceList.forEach((instance, index) => {
              if (instance.referenceInstallation == this.instanceID.toString()) {
                foundInstanceIndex = index;
              }
            });
            if (foundInstanceIndex !== -1) {
              this.currentInstance = instanceList[foundInstanceIndex];
            }
            else {
              this.currentInstance = instanceList[0];
            }
          }
          else {
            this.currentInstance = instanceList[0];
          }

          this.getHistorique();
          if (this.currentInstance.urlTimesheet) {
            this.timesheetService.getWebsiteByURL(this.currentInstance.urlTimesheet, this.currentInstance.idInstance).subscribe({
              next: (res: Site) => this.siteInstallation = res
            });
          }
        } else {
          this.currentInstance = new Instance();
          this.requestCompleted = true;
        }
      },
      error: () => {
        this.currentInstance = new Instance();
        this.requestCompleted = true;
      }
    });
    this.instanceService.getAllInstances(this.idClient, true);
    // chercher les instantes liées à cette référence d'install -> première fois -> on recharge tout
  }

  getHistorique(): void {
    this.historiqueListSubscription = this.historiqueService.historiqueListSubject.subscribe({
      next: (historiqueList: LicenceHistorique[]) => {
        // create one object linked to the one in the service and a proper copy to compare and edit the 2
        this.historiqueList = historiqueList.map(x => Object.assign({}, x));
        this.historiqueListOrigine = historiqueList;
        if (this.historiqueList.length > 0) {
          this.latestDay = new Date(this.historiqueList[this.historiqueList.length - 1].dateValidite);
        }
      }, error: (() => this.requestCompleted = true)
    });

    this.totalSubscription = this.historiqueService.totalsSubject.subscribe({
      next: (totals: TotalTable[]) => {
        this.totals = totals;
        this.hasDifferences = [false, false];
        this.hasAnnonce = false;
        for (const total of this.totals) {
          if (total.totalAnnonce !== 0) {
            if (total.totalSansMaintenanceActuel !== total.totalSansMaintenanceFutur) {
              this.hasDifferences[0] = true;
            }
            if (total.totalAvecMaintenanceActuel !== total.totalAvecMaintenanceFutur) {
              this.hasDifferences[1] = true;
            }
            this.hasAnnonce = true;
          }
        }
        this.requestCompleted = true;
      }, error: (() => this.requestCompleted = true)
    });

    this.historiqueService.getAllLicencesByInstance(this.currentInstance.idInstance, this.currentInstance.referenceInstallation, true);
  }

  findInstance(refInstall: string): void {
    const instance = this.findInstanceByRefInstall(refInstall);
    if (instance) {
      this.currentInstance = instance;
      this.historiqueService.getAllLicencesByInstance(this.currentInstance.idInstance, this.currentInstance.referenceInstallation, false);
      this.router.navigate([`/dashboard/${this.idClient}/${DashboardTab.Instance}/${DashboardPanel.None}/${instance.referenceInstallation}`]);
    }
  }

  findInstanceByRefInstall(refInstall: string): Instance {
    if (refInstall === '') {
      return this.instanceList[0];
    }
    for (const instance of this.instanceList) {
      if (instance.referenceInstallation === refInstall) {    // cherche dans la liste déjà chargée
        if (instance.urlTimesheet) {
          this.timesheetService.getWebsiteByURL(instance.urlTimesheet, instance.idInstance).subscribe({
            next: (res: Site) => this.siteInstallation = res
          });
        }
        return instance;
      }
    }
    return new Instance();
  }

  /*======================================== DISPLAY ========================================*/

  displayItem(itemName: string): boolean { // true to display
    return (!itemName.includes('dateValidite') && !itemName.includes('details') && !itemName.includes('modulesInfo') && (!itemName.includes('id') || itemName === 'dateValidite')
      && itemName !== 'options' && itemName !== 'remarque');
  }

  displayModules(item: string): boolean {
    return (item.length <= 5 && item !== 'type'); // a modifier
  }

  displayTotal(totalName: string): boolean {
    const today = new Date();
    const maintenance = new Date(this.currentInstance.dateFinMaintenance);
    if (totalName.includes('maintenance')) {
      if ((maintenance.getFullYear() < 2000 && this.currentInstance.avecMaintenance)
        || maintenance > today) {
        return true;
      } else if (maintenance < today ||
        (maintenance.getFullYear() < 2000 && !this.currentInstance.avecMaintenance)) {
        return false;
      }
    }
    return true;
  }

  /*======================================== ACTIONS / ROUTING ========================================*/

  editInstance(): void {
    this.router.navigate([`/instance/${this.idClient}/${this.currentInstance.idInstance}`]);
  }

  addInstance(): void {
    this.router.navigate([`/instance/${this.idClient}/0`]);
  }

  /**
   * Ouvre la modal pour confimer la suppression de l'instance "current"
   */
  openModalToDeleteInstance(): void {
    if (this.currentInstance.instanceAutomatique) {
      this.openModal = true;
      this.deletingStatus = DELETE_MODE.INSTANCE;
    } else {
      this.messageService.displayError('instance non automatique : suppression impossible');
    }
  }

  /**
   * Supprime l'instance "current"
   */
  deleteInstance(): void {
    if (this.currentInstance.idInstance) {
      this.instanceService.deleteInstance(this.currentInstance.idInstance).subscribe({
        next: () => {
          // si on a d'autres instances on prend la première pour l'afficher
          if (this.instanceList.length > 0) {
            this.currentInstance = this.instanceList[0];
          } else {
            // sinon on avertit le parent qu'on veut revenir à l'onglet client
            this.getToClientTab.emit();
          }
        }, error: (error) => console.error(error)
      });
    }
  }

  redirectToAnnonces(): void {
    this.router.navigate([`/annonces/${this.currentInstance.idClient}/${this.currentInstance.referenceInstallation}`]);
  }

  /*================ HISTORIQUES =======================*/
  editHistorique(selectedHistoriqueId: number): void {
    this.router.navigate([
      `/editHistorique/${this.currentInstance.idInstance}/${this.currentInstance.referenceInstallation}
      /${selectedHistoriqueId}/${this.currentInstance.idClient}`
    ]);
  }

  openModalToDeleteHisto(selectedHistoriqueId: number): void {
    this.selectedHistoriqueId = selectedHistoriqueId;
    this.deletingStatus = DELETE_MODE.HISTO;
    this.openModal = true;
  }

  deleteHistorique(): void {
    if (this.selectedHistoriqueId) {
      this.historiqueService.deleteHistorique(this.selectedHistoriqueId).subscribe({
        next: () => this.historiqueService.getAllLicencesByInstance(this.currentInstance.idInstance, this.currentInstance.referenceInstallation, true),
        error: (error) => console.error(error)
      });
    }
  }

  /*======================================== TOOLS ========================================*/

  checkDate(currentDate: string): boolean {
    return new Date(currentDate).getFullYear() > 2000;
  }

  redirectToSite(url: string): void {
    window.open("https://" + url, "_blank");
  }

  exportexcel(): void {
    this.instanceService.exportExcel(this.currentInstance, this.historiqueList);
  }
  /*======================================== MODALS ========================================*/

  closeModal(): void {
    this.openModal = false;
    if (this.deletingStatus === DELETE_MODE.INSTANCE) {
      this.deleteInstance();
    } else if (this.deletingStatus === DELETE_MODE.HISTO) {
      this.deleteHistorique();
    }
    this.deletingStatus = DELETE_MODE.VOID;
  }

  ngOnDestroy(): void {
    if (this.instanceSubscription) {
      this.instanceSubscription.unsubscribe();
    }
    if (this.historiqueListSubscription) {
      this.historiqueListSubscription.unsubscribe();
    }
    if (this.totalSubscription) {
      this.totalSubscription.unsubscribe();
    }
  }
}