import { Instance } from '../types/instance';
import { LicenceHistorique } from '../types/licence_historique';
import { LicenceHistoriqueDetail } from '../types/licence.historique.detail';
import { OptionInstance } from '../types/option_instance';

export class FullHistorique {
    instance: Instance = new Instance();
    historique: LicenceHistorique = new LicenceHistorique();
    details: LicenceHistoriqueDetail[] = [];
    options: OptionInstance[] = [];
}
