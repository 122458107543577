import { EventEmitter, Injectable, NgZone, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { User, UserGoogle } from '../types/user';
import { ToolsService } from './tools/tools.service';
import { MessageService } from './tools/message.service';
import { catchError, map, Observable } from 'rxjs';

// structure qu'on reçoit de l'API au login
interface GoogleToken {
  Access_token: string, 
  Expires_in: number, 
  Token_type: string, 
  Id_token: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuth: boolean = false;
  idUser: number  = 0;
  @Output()
  trigger: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private httpClient: HttpClient, private router: Router, private toolsService: ToolsService, 
    private messageService: MessageService, private ngZone: NgZone) {}

  logOut(): void {
    this.toolsService.clearUser();
    this.toolsService.clearFilters();
    this.signOut();
    this.ngZone.run(() => { this.router.navigate(['/login']); })
  }

  // ici on contacte notre DB
 login(email: string): Observable<string> {
    return this.httpClient
    .get<User>(`users/${email}`)
    .pipe(
      map((response: User) => {
        this.idUser = response.idUser;
        this.isAuth = true;
        this.trigger.emit(true);
        return '';
      }),
      catchError((error:Error) => {
        this.messageService.displayError('Echec du login');
        return error.message;
      })
    );
  }

  // ici on va contacter Google
  loginGoogle(googleCode: string): Observable<string> {
    let headers = new HttpHeaders();
    // on donne le code que Google nous a donné
    headers = headers.set('google_code', googleCode);
    return this.httpClient.get<{Token:GoogleToken, User: UserGoogle}>('google/token', {headers: headers}).pipe(
      map((result: {Token:GoogleToken, User: UserGoogle}) => {
        // on stocke les infos user + le access token
        this.toolsService.setUser(result.User, result.Token.Access_token, result.Token.Id_token);
        return result.User.Email;
      }),
      catchError((error:Error) => {
          this.messageService.displayError(`Echec du login Google`);
          console.error(error);
          throw error;
      })
    );
  }

  navigate(currentPath: string = '', params: any = null): void {
    if (currentPath === '') {
      currentPath = '/clients';
    }
    if (params !== null) {
      this.router.navigate([currentPath, params]);
    } else {
      this.router.navigate([currentPath]);
    }
  }

  private signOut(): void {
    this.toolsService.clearUser();
  }
}
