<div class="content-container">
    <nav class="sidenav" style="width: 200px;">
        <section class="sidenav-content">
            <section class="nav-group">
                <div *ngIf="fullHistorique.instance.idInstance == 0" class="danger">L'instance n'a pas été trouvé</div>
                <div id="divbuttonsnav">
                    <button (click)="onSubmit()" style="width: fit-content;"
                        class="btn btn-success btn-inline" id="btnSubmit" [disabled]="fullHistorique.instance.idInstance == 0">Valider</button>
                    <button (click)="redirectBack()" class="btn btn-inline">Retour</button>
                </div>
            </section>
        </section>
    </nav>
    <div *ngIf="requestCompleted" class="content-area scrollable">
        <div class="card-block">
            <div class="card-text">
                <p class="element"><strong>
                    Instance : {{fullHistorique.instance.referenceInstallation}}
                    <span *ngIf="fullHistorique.instance.nom != ''"> - {{fullHistorique.instance.nom}}</span>
                </strong></p>
            </div>
        </div>
        <div *ngIf="invalidForm" class="alert alert-warning" role="alert">
            <div class="alert-items">
                <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                        <cds-icon class="alert-icon" shape="exclamation-triangle"></cds-icon>
                    </div>
                    <span class="alert-text">{{errorContent}}</span>
                </div>
            </div>
        </div>

        <form clrForm (ngSubmit)="onSubmit()" #f="ngForm">
            <clr-date-container>
                <label for="dateValidite">Date de validité</label>
                <input class="date" type="date" clrDate id="dateValidite" name="dateValidite"
                    [(ngModel)]="fullHistorique.historique.dateValidite" ngModel />
                <clr-control-helper></clr-control-helper>
            </clr-date-container>

            <clr-input-container>
                <label for="type">Type</label>
                <select (change)="updateType($event)" clrInput id="type" name="type">
                    <option value="1" [selected]="fullHistorique.historique.type == 1">Achat</option>
                    <option value="2" [selected]="fullHistorique.historique.type == 2">Location</option>
                    <option value="3" [selected]="fullHistorique.historique.type == 3">Offert</option>
                    <option value="4" [selected]="fullHistorique.historique.type == 4">Maintenance</option>
                </select>
                <clr-control-helper></clr-control-helper>
            </clr-input-container>

            <clr-date-container>
                <label for="dateFacture"> Date de facturation </label>
                <input class="date" type="date" clrDate id="dateFacture" name="dateFacture"
                    [(ngModel)]="fullHistorique.historique.dateFacture" ngModel/>
                <clr-control-helper></clr-control-helper>
            </clr-date-container>

            <clr-textarea-container>
                <label for="remarque">Remarque</label>
                <textarea clrTextarea style="width:286px" id="remarque" name="remarque"
                    [(ngModel)]="fullHistorique.historique.remarque" ngModel>
                </textarea>
            </clr-textarea-container>
        </form>

        <div id="container" class="content clr-col-6 clr-col-md-6">
            <ul id="historiqueTab" class="nav">
                <li class="nav-item">
                    <button (click)="switchTab(Tab.Licences)" id="licenceBtn" [class.active]="currentTab == Tab.Licences"
                        class="btn btn-link nav-link" type="button">Licences
                    </button>
                </li>
                <li class="nav-item">
                    <button (click)="switchTab(Tab.Options)" id="optionBtn" [class.active]="currentTab == Tab.Options"
                        class="btn btn-link nav-link" type="button">Options
                    </button>
                </li>
            </ul>

            <!-- licences tab -->
            <section *ngIf="currentTab == Tab.Licences">
                <div class="clr-col-12 clr-col-lg-6 clr-col-xl-4" id="licence-tab">
                    <table width="480px;">
                        <col style="width:60%">
                        <col style="width:10%">
                        <col style="width:10%">
                        <thead>
                            <tr>
                                <th>Nom module</th>
                                <th>Nombre</th>
                                <th>Flottant</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let detail of fullHistorique.details; index as i;">
                                <td title="Nom Module" class="containerCenter">{{detail.nomModule}}</td>
                                <td title="Nombre"><input type="number" class="toPutUp" [(ngModel)]="detail.nombre"
                                    clrInput /></td>
                                <td title="Flottant" id="containerCheckbox"><input type="checkbox"
                                    id="flottant-{{i}}" [(ngModel)]="detail.flottant" clrCheckbox /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button id="mobileButton" (click)="redirectBack()" class="btn btn-inline">Retour</button>
            </section>

            <!-- options tab -->
            <section *ngIf="currentTab == Tab.Options" id="optionTab">
                <app-options-tab *ngIf="requestCompleted" (notify)="getChildInfo($event)" [mode]="1"
                    [optionsInstanceList]="fullHistorique.options" [idInstance]="idInstance">
                </app-options-tab>
            </section>
        </div>
    </div>

    <div *ngIf="!requestCompleted" id="loading" class="loading" style="visibility:inherit">
        Loading&#8230;
    </div>
</div>
