import { LicenceHistoriqueDetail } from './licence.historique.detail';
import { OptionInstance } from './option_instance';

export class LicenceHistorique {
    idLicenceHistorique: number = 0;
    idInstance: number = 0;
    idUser: number = 0;
    initialesUser: string = '';
    dateHeureSaisie: string = '';
    type: number = 0;
    remarque: string = '';
    dateFacture: string = '';
    dateValidite: string = '';
    
    options: OptionInstance[] = [];
    details: LicenceHistoriqueDetail[] = [];
    modulesInfo: ModuleInfo[] = [];
}

export interface ModuleInfo {
    nom: string;
    nombre: number;
    flottant: boolean;
}

export interface InstanceExport {
    dateValidite: Date;
    dateFacture: Date;
    dateSaisie: Date;
    type: string;
    remarque: string;
    initialesUser: string;
}