import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Hebergement, HebergementUser } from 'app/types/hebergement';
import { DateService } from './tools/date.service';
import { Observable, catchError, of, tap } from 'rxjs';
import { MessageService } from './tools/message.service';

@Injectable({
    providedIn: 'root'
})
export class HebergementService {

    private loadedHebergement: Hebergement = Hebergement.createEmpty();

    constructor(private http: HttpClient, private dateService: DateService, private messageService: MessageService) { }

    getHebergement(clientId: number, bypass = false): Observable<Hebergement> {
        // si on retrouve l'hébergement du client on le redonne sans le recharger
        if (this.loadedHebergement && this.loadedHebergement.idHebergement > 0 && this.loadedHebergement.idClient === clientId && !bypass) {
            return of(this.loadedHebergement);
        }

        return this.http.get<Hebergement>(`hebergement/${clientId}`).pipe(
            tap((h: Hebergement) => this.loadedHebergement = h,
                catchError((error: Error) => {
                    this.messageService.displayError(`Impossible de récupérer les infos d'hébergement du client`);
                    throw error;
                })
            ));
    }

    postHebergement(hebergement: Hebergement): Observable<boolean> {
        // copie + formattage des dates pour l'API
        const hebergement_API = {
            ...hebergement,
            ...{ dateDebut: this.dateService.dateForAPI(hebergement.dateDebut) },
            ...{
                utilisateurs: hebergement.utilisateurs.map((u: HebergementUser) => {
                    return {
                        ...u,
                        ...{
                            dateActivation: this.dateService.dateForAPI(u.dateActivation),
                            dateDesactivation: this.dateService.dateForAPI(u.dateDesactivation)
                        }
                    };
                })
            }
        };

        return this.http.post<boolean>('hebergement', hebergement_API);
    }

    deleteHebergement(idHebergement: number): Observable<boolean> {
        return this.http.delete<boolean>(`hebergement/${idHebergement}`);
    }

    deleteHebergementUser(idUser: number): Observable<boolean> {
        return this.http.delete<boolean>('hebergement/users/' + idUser);
    }

    postHebergementUser(user: HebergementUser): Observable<boolean> {
        const user_API = {
            ...user,
            ...{
                dateActivation: this.dateService.dateForAPI(user.dateActivation),
                dateDesactivation: this.dateService.dateForAPI(user.dateDesactivation)
            }
        };
        return this.http.post<boolean>('hebergement/users', user_API);
    }
}